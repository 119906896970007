import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  setProducts,
  toggleProduct,
  clearProductToggles,
  setMotorProducts,
  setHealthProducts,
  setLifeProducts,
  setLifeCompanyProducts,
  setPropertyProducts,
  setCompareProducts,
  clearCompareProducts,
  setPayload,
  setPropertyPayload,
  setMotorPayload,
  setLifePayload,
  setLifeCompanyPayload,
  setHealthPayload,
  clearPayload,
  toggleMessenger,
} from '../components/Product/actions';
import { setExchangeRate } from '../components/Payments/actions';
import { setSearchItem, clearSearchItem } from '../components/Search/actions';

const WithProducts = WrappedComponent => {
  class With extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(
    state => ({
      products: state.products,
      exchangeRate: state.exchangeRate,
      search: state.search,
      motorProducts: state.motorProducts,
      healthProducts: state.healthProducts,
      lifeProducts: state.lifeProducts,
      lifeCompanyProducts: state.lifeCompanyProducts,
      propertyProducts: state.propertyProducts,
      compareProducts: state.compareProducts,
      showMessenger: state.showMessenger,
      payload: state.payload,
      motorPayload: state.motorPayload,
      propertyPayload: state.propertyPayload,
      healthPayload: state.healthPayload,
      lifePayload: state.lifePayload,
      lifeCompanyPayload: state.lifeCompanyPayload,
    }),
    dispatch => ({
      setProducts: products => dispatch(setProducts(products)),
      toggleProduct: productId => dispatch(toggleProduct(productId)),
      clearProductToggles: () => dispatch(clearProductToggles()),
      setSearchItem: search => dispatch(setSearchItem(search)),
      clearSearchItem: () => dispatch(clearSearchItem()),
      setMotorProducts: products => dispatch(setMotorProducts(products)),
      setHealthProducts: products => dispatch(setHealthProducts(products)),
      setLifeProducts: products => dispatch(setLifeProducts(products)),
      setLifeCompanyProducts: products =>
        dispatch(setLifeCompanyProducts(products)),
      setPropertyProducts: products => dispatch(setPropertyProducts(products)),
      setCompareProducts: products => dispatch(setCompareProducts(products)),
      clearCompareProducts: () => dispatch(clearCompareProducts()),
      setPayload: payload => dispatch(setPayload(payload)),
      setMotorPayload: payload => dispatch(setMotorPayload(payload)),
      setPropertyPayload: payload => dispatch(setPropertyPayload(payload)),
      setLifePayload: payload => dispatch(setLifePayload(payload)),
      setLifeCompanyPayload: payload =>
        dispatch(setLifeCompanyPayload(payload)),
      setHealthPayload: payload => dispatch(setHealthPayload(payload)),
      clearPayload: () => dispatch(clearPayload()),
      setExchangeRate: rate => dispatch(setExchangeRate(rate)),
      toggleMessenger: () => dispatch(toggleMessenger()),
    }),
  )(With);
};

export default WithProducts;
