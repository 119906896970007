import React, { useState, useEffect } from 'react';
import {
  PaystackConsumer,
} from 'react-paystack';
import axios from 'axios';
import { transactionsUrl, getHeaders, errorMessage } from '../../config';

import WithPayments from '../../hocs/WithPayments';
import WithUser from '../../hocs/WithUser';
import { withRouter } from 'react-router-dom';

const PaystackPayment = props => {
  // eslint-disable-next-line react/prop-types
  const { id, amount, reference } = props.payment;
  // eslint-disable-next-line react/prop-types
  const { email, phoneNumber, firstName, lastName } = props.authUser.user;
  // eslint-disable-next-line
  const [paystackAmount, setPaystackAmount] = useState(Number(amount) * 100)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [metaData, setMetaData] = useState({
    name: `${firstName} ${lastName}`,
    phone: phoneNumber,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [defaultEmail, setDefaultEmail] = useState('support@mybestquote.com');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [key, setKey] = useState(process.env.REACT_APP_PAYSTACK_PUBLIC_KEY); //PAYSTACK PUBLIC KEY
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currency, setCurrency] = useState('GHS');

  const startProcessing = (initializePayment) => {
    axios
      .post(`${transactionsUrl}${id}/start-processing`, { reference }, {
        headers: getHeaders(),
      })
      .then(() => {
        initializePayment();
      })
      .catch(err => {
        if (err.response) {
          errorMessage('Error Initiating Payment, Retry.');
        } else {
          errorMessage('Check your internet connection, retry');
        }
      });
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, [])

  const callback = () => {
    // const payload = {
    //   reference: response.reference,
    // };

    /* NOTE: clear reference from state. 
      and remove amount from state. 
      to prevent duplicate confirmation. 
      to prevent duplicated wallet values.
    */
    // eslint-disable-next-line react/prop-types
    props.handleConfirmSuccess();
  };

  const close = () => {};
  
  return (
    <div>
      <PaystackConsumer
      email={email ? email : defaultEmail}
      amount={Math.floor(Number(amount) * 100)}
      reference={reference}
      currency={currency}
      metadata={metaData}
      publicKey={key}
      onSuccess={callback}
      onClose={close}
      >
        {({ initializePayment }) => (
          <button
            className="btn_normal btn_gradient btn_md"
            onClick={() => {
              startProcessing(initializePayment);
            }}
          >
            Make Payment
          </button>
        )}
      </PaystackConsumer>
    </div>
  );
};

export default withRouter(WithPayments(WithUser(PaystackPayment)));
