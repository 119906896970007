// eslint-disable-next-line @typescript-eslint/naming-convention
export function parse_query_string(query) {
  let vars = query.split('&');
  let queryString = {};
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    let key = decodeURIComponent(pair[0]);
    let value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof queryString[key] === 'undefined') {
      queryString[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof queryString[key] === 'string') {
      queryString[key] = [queryString[key], decodeURIComponent(value)];
      // If third or later entry with this name
    } else {
      queryString[key].push(decodeURIComponent(value));
    }
  }
  return queryString;
}

export function setCookie(queryString, queryValue, expiry) {
  document.cookie = `${queryString}=${queryValue}; expires=${expiry}`;
}

export function getCookie(queryString) {
  let cookieValue = false;
  if (document.cookie.split('; ').find(row => row.startsWith(queryString))) {
    cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(`${queryString}=`))
      .split('=')[1];
  }

  return cookieValue;
}
