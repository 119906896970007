/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import SignupForm from '../../components/Auth/SignupForm';
import bqLogo from '../../img/bestquote-logo.png';
// import signupBorder from 'img/signup-border.svg'

class SignUpPage extends Component {
  facebookLogin = () => {
    let url = 'https://www.facebook.com/v2.10/dialog/oauth?';
    url += `client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&`;
    url += `redirect_uri=${process.env.REACT_APP_APP_BASE}/auth/facebook-redirect&`;
    url += 'response_type=code&scope=public_profile,email,user_birthday';

    window.location.href = url;
  };

  render() {
    return (
      <div className="auth">
        <div className="auth__bounding-box">
          {/* <div
            className="auth__back"
            style={{ fontSize: '3rem', cursor: 'pointer' }}
          >
            <span onClick={this.props.history.goBack}>&times;</span>
          </div> */}
          <div className="auth__top">
            <img
              src={bqLogo}
              alt="BestQuote Logo"
              className="bq-logo bq-logo--auth mb-1rem"
              onClick={() => this.props.history.push('/')}
            />
          </div>
          <div>
            <hr />
          </div>

          <div className="auth__caption mb-2rem">
            <p>Sign Up NOW!</p>

            <p className="auth__caption__box">
              By signing up, you get detailed view of all the best deals on
              BESTQUOTE, efficiently process your claim, get reminders of
              purchased policies and a lot more.
            </p>
          </div>

          <SignupForm useComponentIn="page" />

          <div className="auth__footer mt-1rem">
            <div>
              Already have an account?{' '}
              <Link to="/auth/login">
                <span className="navbar__link--active">Log in</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SignUpPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
};

export default SignUpPage;
