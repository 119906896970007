import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, Button } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  CarOutlined,
  SafetyCertificateFilled,
  ExportOutlined,
  HomeOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import bqLogo from '../../img/bestquote-logo.png';

const { SubMenu } = Menu;

const DashboardSidebar = props => {
  const [collapsed, setCollapsed] = useState();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <React.Fragment>
      <div className="dashboard_sidebar">
        <img
          src={bqLogo}
          alt="logo"
          className="dashboard_sidebar_logo"
          onClick={() => props.history.push('/')}
        />
        <Menu
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode="inline"
          theme="light"
          inlineCollapsed={collapsed}
          className="dashboard_sidebar_menu"
        >
          <Menu.Item
            key="1"
            icon={<PieChartOutlined />}
            onClick={() => props.history.push('/account')}
          >
            Overview
          </Menu.Item>
          {/* <Menu.Item
            key="2"
            icon={<SafetyCertificateFilled />}
            onClick={() => props.history.push('/account/motor')}
          >
            Insurance Products
          </Menu.Item> */}
          <SubMenu
            key="sub1"
            icon={<SafetyCertificateFilled />}
            title="Insurance Products"
          >
            <Menu.Item
              key="10"
              onClick={() => props.history.push('/account/motor')}
            >
              Car Insurance
            </Menu.Item>
            <Menu.Item
              key="11"
              onClick={() => props.history.push('/account/travel')}
            >
              Travel Insurance
            </Menu.Item>
            <Menu.Item
              key="12"
              onClick={() => props.history.push('/account/life')}
            >
              Life Insurance
            </Menu.Item>
            {/* <Menu.Item
              key="12"
              onClick={() => props.history.push('/account/health')}
            >
              Health Insurance
            </Menu.Item> */}
            <Menu.Item
              key="13"
              onClick={() => props.history.push('/account/property')}
            >
              Home Insurance
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="3"
            icon={<ArrowUpOutlined />}
            onClick={() => props.history.push('/account/claims')}
          >
            Claims
          </Menu.Item>
          <Menu.Item
            key="4"
            icon={<ArrowDownOutlined />}
            onClick={() => props.history.push('/account/cancellations')}
          >
            Cancellations
          </Menu.Item>
          {/* <Menu.Item
            key="5"
            icon={<ContainerOutlined />}
            onClick={() => props.history.push('/account/settings')}
          >
            Learn
          </Menu.Item> */}
          <Menu.Item
            key="6"
            icon={<SettingOutlined />}
            onClick={() => props.history.push('/account/settings')}
          >
            Account Settings
          </Menu.Item>
          <Menu.Item
            key="7"
            icon={<CarOutlined />}
            onClick={() => props.history.push('/buy-motor-insurance')}
          >
            Buy Car Insurance
          </Menu.Item>
          <Menu.Item
            key="8"
            icon={<ExportOutlined />}
            onClick={() => props.history.push('/buy-travel-insurance')}
          >
            Buy Travel Insurance
          </Menu.Item>
          <Menu.Item
            key="9"
            icon={<HomeOutlined />}
            onClick={() => props.history.push('/property')}
          >
            Buy Home Insurance
          </Menu.Item>

          {/* <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Navigation Two">
            <Menu.Item key="9">Option 9</Menu.Item>
            <Menu.Item key="10">Option 10</Menu.Item>
            <SubMenu key="sub3" title="Submenu">
              <Menu.Item key="11">Option 11</Menu.Item>
              <Menu.Item key="12">Option 12</Menu.Item>
            </SubMenu>
          </SubMenu> */}
        </Menu>
        <Button
          type="primary"
          onClick={toggleCollapsed}
          className="dashboard_sidebar_btn"
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          )}
        </Button>
      </div>
    </React.Fragment>
  );
};

DashboardSidebar.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(DashboardSidebar);
