import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { FadeLoader } from '../UI/FadeLoader';
import PropTypes from 'prop-types';
import { getHeaders, motorUrl } from '../../config';

import Header from '../Header';
import MotorRenewForm from './MotorRenewForm';
import MotorProductBreakdown from './MotorProductBreakdown';

const MotorRenew = props => {
  const [policy, setPolicy] = useState(null);

  const getPolicy = () => {
    axios
      .get(`${motorUrl}/${props.match.params.id}/get-renewal`, {
        headers: getHeaders(),
      })
      .then(res => {
        setPolicy(res.data);
      });
  };

  useEffect(() => {
    getPolicy();
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Header>{/* <ProductSearchSummary /> */}</Header>
      <div className="wrapper mt-5rem mb-5rem">
        {policy ? (
          <div className="row reverse-md">
            <div className="col-md-7">
              <MotorRenewForm policy={policy} setPolicy={val => setPolicy(val)} />
            </div>
            <div className="col-md-5 mb-2rem">
              <p className="mb-2rem">{"Here's a Breakdown of Current Policy"}</p>
              <MotorProductBreakdown
                product={policy}
                premium={policy.premium}
                isPurchasing={true}
              />
            </div>
          </div>
        ) : (
          <div className="site-content mb-10rem">
            <FadeLoader/>
          </div>
        )}
      </div>
    </div>
  );
};

MotorRenew.propTypes = {
  search: PropTypes.object,
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default withRouter(MotorRenew);
