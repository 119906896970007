import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import HealthPage from './HealthMainPage';

import PageNotFound from '../PageNotFound';
import HealthCompare from './HealthCompare';
import Footer from '../../components/Footer';

const HealthRoutes = props => (
  <>
    <Switch>
      <Route exact path={props.match.path} component={HealthPage} />
      <Route
        exact
        path={`${props.match.path}/compare`}
        component={HealthCompare}
      />
      <Route component={PageNotFound} />
    </Switch>
    <Footer />
  </>
);
HealthRoutes.propTypes = {
  match: PropTypes.object,
};

export default HealthRoutes;
