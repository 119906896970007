import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import * as pc from '../../../app-constants';

class UserCancellationCard extends React.Component {
  render() {
    let { cancellation } = this.props;
    let logoUrl = cancellation.policy.product.company.logoUrl;
    let status = cancellation.policy.status;
    let CHOICES = pc.POLICY_STATUS_CHOICES;
    let COLOUR = {
      C: 'user-dash-card__status--cancelled',
      F: 'user-dash-card__status--requested',
      P: 'user-dash-card__status--processed',
    };

    return (
      <div className="user-dash-card mr-2rem mb-2rem">
        <div>
          <div className="space-between">
            <span className="user-dash-card__category">
              {cancellation.policy.product.name}
            </span>
            <span className={cx(COLOUR[status], 'user-dash-card__status')}>
              {CHOICES[status]}
            </span>
          </div>

          <div className="user-dash-card__description">
            Refund: <span className="text--muted"></span>
          </div>
          <div className="user-dash-card__description">
            Reason for cancellation:{' '}
            <span className="text--muted">{cancellation.reason}</span>
          </div>
        </div>

        <div>
          <img
            className="user-dash-card__logo"
            src={logoUrl}
            alt="Insurer Logo"
          />
        </div>
      </div>
    );
  }
}
UserCancellationCard.propTypes = {
  cancellation: PropTypes.shape({
    reason: PropTypes.string,
    policy: PropTypes.shape({
      status: PropTypes.string,
      product: PropTypes.shape({
        name: PropTypes.string,
        company: PropTypes.shape({
          logoUrl: PropTypes.string,
        }),
      }),
    }),
  }),
};

export default UserCancellationCard;
