import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// import landingImage from 'img/landing-image.svg";
import Navbar from './Navbar';

class Header extends Component {
  render() {
    return (
      <div
        className={cx('header', this.props.noRibbon ? null : 'header--ribbon')}
      >
        <div className="wrapper">
          <Navbar />
        </div>
        {this.props.children}
      </div>
    );
  }
}
Header.propTypes = {
  noRibbon: PropTypes.bool,
  children: PropTypes.node,
};

export default Header;
