import React from 'react';
import axios from 'axios';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getHeaders } from '../../config';

import WithUser from '../../hocs/WithUser';
import UserClaimCard from './travel/UserClaimCard';
import MotorClaimCard from './motor/MotorClaimCard';
import PropertyClaimCard from './property/PropertyClaimCard';
import policyIcon from '../../img/policy-icon.svg';

class UserClaims extends React.Component {
  state = {
    claims: [],
    motorClaims: [],
    propertyClaims: [],
    width: 0,
    height: 0,
  };

  async componentDidMount() {
    let URL = `${process.env.REACT_APP_API_BASE}/api/v1/claims`;
    axios
      .get(URL, {
        headers: getHeaders(),
      })
      .then(res => {
        this.setState({
          claims: res.data.results,
        });
      })
      .catch(err => {
        console.error(err);
      });

    try {
      const motorURL = `${process.env.REACT_APP_API_BASE}/api/v1/motor-claims`;
      let {
        data: { results },
      } = await axios.get(motorURL, {
        headers: getHeaders(),
      });
      this.setState({ motorClaims: results });
    } catch (err) {
      console.error(err);
    }

    try {
      const propertyURL = `${process.env.REACT_APP_API_BASE}/api/v1/property-claims`;
      let {
        data: { results },
      } = await axios.get(propertyURL, {
        headers: getHeaders(),
      });
      this.setState({ propertyClaims: results });
    } catch (err) {
      console.error(err);
    }

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    let { claims } = this.state;
    let { motorClaims } = this.state;
    let { propertyClaims } = this.state;
    return (
      <div className="user-dash__main">
        <p className="site-content__lead">Claims Made on Purchased Policies</p>
        <p className="text--muted mb-2rem">
          Here’s an overview of policies you’ve made claims on
        </p>

        <hr />

        <p className="mt-2rem">Travel</p>

        {claims.length ? (
          <div className="mt-2rem flex-row flex-wrap">
            {claims.map(claim => (
              <UserClaimCard key={claim.id} claim={claim} />
            ))}
          </div>
        ) : (
          <div className="mt-2rem flex-row flex-wrap">
            {this.state.width > 767 ? (
              <img src={policyIcon} alt="policy-icon" />
            ) : null}
            <p className={cx(this.state.width > 767 ? 'ml-2rem' : null)}>
              <span className="mr-2rem">You’ve not made any claims</span>
              <button
                className={cx(
                  'btn btn--gradient-border',
                  this.state.width > 767 ? null : 'mt-1rem',
                )}
              >
                <Link to="/travel">Discover Policies</Link>
              </button>
            </p>
          </div>
        )}

        <p className="mt-4rem">Car</p>

        {motorClaims.length ? (
          <div className="mt-2rem flex-row flex-wrap">
            {motorClaims.map(claim => (
              <MotorClaimCard key={claim.id} claim={claim} />
            ))}
          </div>
        ) : (
          <div className="mt-2rem flex-row flex-wrap">
            {this.state.width > 767 ? (
              <img src={policyIcon} alt="policy-icon" />
            ) : null}
            <p className={cx(this.state.width > 767 ? 'ml-2rem' : null)}>
              <span className="mr-2rem">You’ve not made any claims</span>
              <button
                className={cx(
                  'btn btn--gradient-border',
                  this.state.width > 767 ? null : 'mt-1rem',
                )}
              >
                <Link to="/motor">Discover Policies</Link>
              </button>
            </p>
          </div>
        )}

        <p className="mt-4rem">Home</p>

        {propertyClaims.length ? (
          <div className="mt-2rem flex-row flex-wrap">
            {propertyClaims.map(claim => (
              <PropertyClaimCard key={claim.id} claim={claim} />
            ))}
          </div>
        ) : (
          <div className="mt-2rem flex-row flex-wrap">
            {this.state.width > 767 ? (
              <img src={policyIcon} alt="policy-icon" />
            ) : null}
            <p className={cx(this.state.width > 767 ? 'ml-2rem' : null)}>
              <span className="mr-2rem">You’ve not made any claims</span>
              <button
                className={cx(
                  'btn btn--gradient-border',
                  this.state.width > 767 ? null : 'mt-1rem',
                )}
              >
                <Link to="/property">Discover Policies</Link>
              </button>
            </p>
          </div>
        )}
      </div>
    );
  }
}
UserClaims.propTypes = {
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
};

export default WithUser(UserClaims);
