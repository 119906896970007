import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TravelBannerPage from '../../components/Travel/TravelBanner';
import TravelSearchForm from '../../components/Travel/TravelSearchForm';
import TravelPartners from '../../components/Travel/TravelPartners';
import bannerImage from '../../img/travel/travel-banner.svg';
import { Modal } from 'antd';
import Navbar from '../../components/Navbar';

const TravelMainPage = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      <Navbar />

      <div className="travel_page">
        <div className="row ">
          <div className="col-md-6 col-xs-12 travel_page_banner">
            <TravelBannerPage
              bannerImage={bannerImage}
              moduleName="Travel"
              price="GHS 88.00"
            />
          </div>
          <div className="col-md-6 travel_page_form">
            <TravelSearchForm />
          </div>
        </div>

        <div className="row travel_page_partners pb-5rem">
          <div className="col-md-7">
            <TravelPartners />
          </div>
        </div>

        <div className="travel_page_floating">
          <button
            type="button"
            className="btn btn--gradient-primary mb-2rem travel_page_floating_button"
            onClick={() => setShowModal(true)}
          >
            Get Started Now
          </button>
        </div>

        <Modal
          title=""
          centered
          open={showModal}
          onOk={() => setShowModal(false)}
          onCancel={() => setShowModal(false)}
          footer={null}
        >
          <TravelSearchForm />
        </Modal>
      </div>
    </React.Fragment>
  );
};

TravelMainPage.propTypes = {
  match: PropTypes.object,
};

export default TravelMainPage;
