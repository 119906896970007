import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FadeLoader } from '../../../components/UI/FadeLoader';
import PropTypes from 'prop-types';
import { getFormHeaders } from '../../../config';

import added from '../../../img/added.svg';

const FileUpload = props => {
  return (
    <div className="flex-row mb-1rem">
      {props.document ? (
        <img
          src={added}
          alt=""
          style={{
            height: 'auto',
            maxHeight: '1rem',
            marginRight: '5px',
          }}
        />
      ) : (
        <span style={{ marginRight: '5px' }}>+</span>
      )}
      <label>{props.label}</label>
      <label className="form__file-upload">
        {props.document ? (
          <span style={{ color: '#20d670' }}>{props.document.name}</span>
        ) : (
          '+ ADD FILE'
        )}
        <input
          type="file"
          onChange={props.handleFiles(
            props.stateName,
            // props.documentName,
            props.fileName,
          )}
          accept="application/pdf"
        />
      </label>
    </div>
  );
};
FileUpload.propTypes = {
  fileName: PropTypes.string,
  document: PropTypes.object,
  label: PropTypes.string,
  handleFiles: PropTypes.func,
  stateName: PropTypes.string,
};

class PolicyCancellationForm extends Component {
  state = {
    visaRefusal: null,
    isCancelling: false,
    isSubmitting: false,
    errorMessage: '',
    cancellationId: 0,
  };

  // handleInputUpdate = (stateName, event) => {
  //   this.setState({ [stateName]: event.target.value });
  // };

  handleFiles = stateName => event => {
    this.setState({
      [stateName]: event.target.files[0],
    });
  };

  onSubmit = async event => {
    event.preventDefault();

    if (this.state.visaRefusal) {
      let formData = new FormData();
      formData.append('document', this.state.visaRefusal);
      formData.append('document_name', 'Visa Refusal');
      formData.append('reason', 'Default');

      await axios({
        headers: getFormHeaders(),
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE}/api/v1/policies/${this.props.match.params.policyId}/cancel/`,
        data: formData,
      });

      this.setState({ errorMessage: 'Refund request success' });

      setTimeout(() => {
        this.props.history.push('/account/cancellations');
      }, 1500);
    } else {
      this.setState({
        errorMessage: 'Please make sure you upload all required files',
      });
    }
  };

  render() {
    let product = this.props.policy.product;

    return (
      <div>
        <p className="mb-1rem">Cancel This Policy</p>
        <p className="text--muted mb-2rem">
          Review the policy below and add the required details to cancel
        </p>
        <p style={{ color: 'red' }}>{this.state.errorMessage}</p>

        <div className="align-vertical mb-2rem" style={{ fontSize: '12px' }}>
          <img
            alt={product.company.name}
            className="mr-2rem"
            src={product.company.logoUrl}
            style={{ maxHeight: '3rem' }}
          />
          <span>{product.company.name}</span>
        </div>

        <label className="">
          <input
            value={this.state.isCancelling}
            type="checkbox"
            onClick={() =>
              this.setState({ isCancelling: !this.state.isCancelling })
            }
            className="mr-1rem mb-2rem"
          />
          Request refund of premium paid for this policy
        </label>

        {this.state.isCancelling ? (
          <form className="form" onSubmit={this.onSubmit}>
            <div className="mb-5rem" style={{ fontSize: '12px' }}>
              <label className="form__label mt-1rem mb-1rem">
                Acceptable reasons for cancellation
              </label>
              <p style={{ maxWidth: '80%' }}>
                Cancellation is possible if you’ve been refused a visa by an
                Embassy. You cannot make a request to cancel this policy less
                than 48 hours to your trip date.
              </p>
            </div>

            <FileUpload
              document={this.state.visaRefusal}
              fileName="Visa Refusal"
              // documentName=""
              label="Proof of Visa Refusal"
              stateName="visaRefusal"
              handleFiles={this.handleFiles}
            />

            <hr />

            {this.state.isSubmitting ? (
              <div className="mt-5rem">
                <FadeLoader />
              </div>
            ) : (
              <div className="mt-5rem">
                <button
                  className="btn btn--gradient-primary btn--width-md mb-2rem mr-2rem"
                  type="submit"
                >
                  Cancel Policy
                </button>
                <button
                  className="btn btn--width-md mb-2rem mr-2rem"
                  onClick={event => {
                    event.preventDefault();
                    this.handleRevertCancellation();
                  }}
                >
                  Go Back
                </button>
              </div>
            )}
          </form>
        ) : null}
      </div>
    );
  }
}
PolicyCancellationForm.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  policy: PropTypes.shape({
    product: PropTypes.shape({
      company: PropTypes.shape({
        name: PropTypes.string,
        logoUrl: PropTypes.string,
      }),
    }),
  }),
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
  document: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default withRouter(
  connect(state => ({
    authUser: state.authUser,
  }))(PolicyCancellationForm),
);
