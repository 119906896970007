import axios from 'axios';
import store from '../store';
import { message, notification } from 'antd';

export const baseUrl = process.env.REACT_APP_API_BASE + '/api/v1/';

// auth endpoints
export const baseAuth = baseUrl + 'auth/';
export const loginUrl = baseAuth + 'login/';
export const logoutUrl = baseAuth + 'logout/';
export const signupUrl = baseAuth + 'signup/';
export const authTokenUrl = baseAuth + 'signup/token-auth/';
export const verifyAccountUrl = baseAuth + 'signup/verify-email/';
export const passwordChangeUrl = baseAuth + 'password/change/';
export const refreshTokenUrl = baseAuth + 'refresh-token/';
// export const userUrl = baseAuth + "user/";
export const usersUrl = baseUrl + 'users';
export const passwordResetUrl = usersUrl + '/password-reset';
export const passwordResetConfirmUrl = usersUrl + '/confirm-password-reset';
export const OTPUrl = usersUrl + '/send-otp';
export const OTPVerificationUrl = usersUrl + '/verify-otp';
export const newUserPasswordUrl = usersUrl + '/set-new-password';
export const updateNewUserInfo = usersUrl + '/update-info';
export const subscriptionsUrl = baseUrl + 'subscriptions/';

export const motorUrl = baseUrl + 'motor-insurance';
export const travelUrl = baseUrl + 'travel-insurance';
export const propertyUrl = baseUrl + 'property-insurance';
export const lifeUrl = baseUrl + 'life-insurance';
export const healthUrl = baseUrl + 'health-insurance';

export const productsUrl = baseUrl + 'products';
export const motorPaymentsUrl = baseUrl + 'motor-payments/';
export const travelPaymentsUrl = baseUrl + 'policy-payments/';
export const propertyPaymentsUrl = baseUrl + 'property-payments/';
export const transactionsUrl = baseUrl + 'transactions/';

export const companyUrl = baseUrl + 'companies';
export const statsUrl = baseUrl + 'stats/overview';
export const promoUrl = baseUrl + 'promo-codes';

export const getHeaders = () => {
  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';

  const authUser = localStorage.getItem('bestquoteUser')
    ? JSON.parse(localStorage.getItem('bestquoteUser'))
    : '';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: authUser ? 'JWT ' + authUser.token : '',
    'BQ-Region': store.getState().region.region,
  };
  return headers;
};

export const getFormHeaders = () => {
  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';

  const authUser = localStorage.getItem('bestquoteUser')
    ? JSON.parse(localStorage.getItem('bestquoteUser'))
    : '';
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: authUser ? 'JWT ' + authUser.token : '',
    'BQ-Region': store.getState().region.region,
  };
  return headers;
};

export const successMessage = msg => {
  message.success(msg);
};

export const errorMessage = msg => {
  message.error(msg);
};

export const warningMessage = msg => {
  message.warning(msg);
};

export const successNotification = msg => {
  notification.success({
    message: msg,
    description: msg,
  });
};

export const errorNotification = msg => {
  notification.error({
    message: msg,
    description: msg,
  });
};

export const warningNotification = msg => {
  notification.warning({
    message: msg,
    description: msg,
  });
};

export const infoNotification = msg => {
  notification.info({
    message: msg,
    description: msg,
  });
};
