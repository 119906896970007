export const MANAGEMENT = 'M';
export const FINANCE = 'F';
export const SALES = 'S';

export const ACCESS_LEVEL_CHOICES = {
  [MANAGEMENT]: 'Management',
  [FINANCE]: 'Finance',
  [SALES]: 'Sales',
};

export const CUSTOMER = 'C';
export const INSURANCE = 'I';
export const NIC = 'N';
export const DVLA = 'D';
export const GPRTU = 'G';
export const POLICE = 'P';
export const BESTQUOTE = 'B';

export const USER_TYPE_CHOICES = {
  [CUSTOMER]: 'Customer',
  [INSURANCE]: 'Insurance',
  [NIC]: 'NIC',
  [DVLA]: 'DVLA',
  [GPRTU]: 'GPRTU',
  [POLICE]: 'Police',
  [BESTQUOTE]: 'Bestquote',
};

export const CHILD = 'C';
export const PARENT = 'P';
export const SIBLING = 'S';
export const SPOUSE = 'U';
export const OTHER = 'O';

export const RELATIONSHIP_CHOICES = {
  [CHILD]: 'Child',
  [PARENT]: 'Parent',
  [SIBLING]: 'Sibling',
  [SPOUSE]: 'Spouse',
  [OTHER]: 'Other',
};