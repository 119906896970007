import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import LifePage from './LifeMainPage';

import PageNotFound from '../PageNotFound';
import LifeCompare from './LifeCompare';
import LifeBuy from './LifeBuy';
import Footer from '../../components/Footer';
import PrivateRoute from '../../hocs/PrivateRoute';
import LifeCompanySearch from './companies/LifeCompanySearch';

const LifeRoutes = props => (
  <>
    <Switch>
      <Route exact path={props.match.path} component={LifePage} />
      <Route
        exact
        path={`${props.match.path}/compare`}
        component={LifeCompare}
      />
      <PrivateRoute path={`${props.match.path}/:id/buy`} component={LifeBuy} />
      <Route path={`${props.match.path}/:slug`} component={LifeCompanySearch} />
      <Route component={PageNotFound} />
    </Switch>
    <Footer />
  </>
);
LifeRoutes.propTypes = {
  match: PropTypes.object,
};

export default LifeRoutes;
