/* eslint-disable react/prop-types */
import SetNewPasswordForm from '../../components/Auth/SetNewPasswordForm';
import React from 'react';
import { withRouter } from 'react-router-dom';
import bqLogo from '../../img/bestquote-logo.png';

const SetNewPasswordPage = props => (
    <React.Fragment>
         <div className="auth">
        <div className="auth__bounding-box">
          <img
            src={bqLogo}
            alt="BestQuote Logo"
            className="bq-logo bq-logo--auth mb-2rem"
          />
        <div>
              <p className="site-content__lead mb-3rem">
                Set Your New Password
              </p>
        <SetNewPasswordForm reference={props.match.params.reference} />
        </div>
        </div>
      </div>
    </React.Fragment>
);

export default withRouter(SetNewPasswordPage);
