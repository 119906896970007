import { constants } from '../../app-constants';

export const setSearchItem = search => ({
  type: constants.SET_SEARCH_ITEM,
  payload: search,
});

export const clearSearchItem = () => ({
  type: constants.CLEAR_SEARCH_ITEM,
});
