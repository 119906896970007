import React, { Component } from 'react';

// TODO: Actually make this work

class UpdateAccountForm extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };

  handleInputUpdate = stateName => event => {
    this.setState({ [stateName]: event.target.value });
  };

  render() {
    return (
      <div>
        <p className="mb-1rem">Update Account</p>
        <p className="text--muted mb-2rem">
          You may update your account details any time. You’d have to confirm
          all changes made with your BestQuote Password.
        </p>

        <form className="form">
          <div className="form__inputs">
            <div className="form__wrapper mb-2rem">
              <label className="form__label mt-1rem mb-1rem">First Name</label>
              <input
                className="form__input"
                onChange={this.handleInputUpdate('firstName')}
                required
              />
            </div>
            <div className="form__wrapper">
              <label className="form__label mt-1rem mb-1rem">Last Name</label>
              <input
                className="form__input"
                onChange={this.handleInputUpdate('lastName')}
                required
              />
            </div>
          </div>

          <div className="form__inputs mb-5rem">
            <div className="form__wrapper mb-2rem">
              <label className="form__label mt-1rem mb-1rem">
                Email Address
              </label>
              <input
                className="form__input"
                // type="email"
                onChange={this.handleInputUpdate('email')}
                // autoComplete="current-email"
                required
              />
            </div>
          </div>

          <hr />

          <div className="form__inputs">
            <div className="form__wrapper mt-5rem">
              <label className="form__label mt-1rem mb-1rem">
                BestQuote Password
              </label>
              <input
                className="form__input"
                placeholder="Enter password"
                type="password"
                onChange={this.handleInputUpdate('password')}
                autoComplete="current-password"
                required
              />
            </div>
          </div>

          <div className="mt-5rem">
            <button className="btn btn--gradient-primary btn--width-md  mb-2rem mr-2rem">
              Save Changes
            </button>
            {/* <span className="text--aqua">CANCEL</span> */}
          </div>
        </form>
      </div>
    );
  }
}

export default UpdateAccountForm;
