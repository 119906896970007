import React, { Component } from 'react';

class NotifyMeForm extends Component {
  render() {
    return (
      <form className="notify-form">
        <div className="mt-2rem form__inputs center-content">
          <div className="form__input-group">
            <input className="form__input form__input-group-field" placeholder="john@doe.com" />
            <button
              className="btn btn--gradient-primary form__input-group-submit"
            >
              Notify Me
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default NotifyMeForm;
