import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { FadeLoader } from '../../components/UI/FadeLoader';
import PropTypes from 'prop-types';

import WithUser from '../../hocs/WithUser';
import PolicyCancellationForm from './travel/PolicyCancellationForm';
import { getHeaders } from '../../config';

class NewCancellation extends React.Component {
  state = {
    policy: null,
  };

  componentDidMount() {
    let URL = `${process.env.REACT_APP_API_BASE}/api/v1/policies/${this.props.match.params.policyId}/`;
    axios
      .get(URL, {
        headers: getHeaders(),
      })
      .then(res => {
        this.setState({ policy: res.data });
      });
  }

  render() {
    let policy = this.state.policy;

    return (
      <div className="user-dash__main">
        {policy ? (
          <PolicyCancellationForm policy={policy} />
        ) : (
          <div style={{ width: '100%' }} className="center-auto">
            <FadeLoader />
          </div>
        )}
      </div>
    );
  }
}
NewCancellation.propTypes = {
  match: PropTypes.object,
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
};

export default withRouter(WithUser(NewCancellation));
