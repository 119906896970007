import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { OTPVerificationUrl, OTPUrl } from '../../config';
import WithUser from '../../hocs/WithUser';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

class LoginForm extends Component {
  state = {
    otp: '',
    phoneNumber: '',
    otpSent: false,
    isSubmitting: false,
    errorMessage: {},
  };

  handleInputUpdate = (stateName, event) => {
    this.setState({ [stateName]: event.target.value });
  };

  getHeaders = token => {
    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';

    return {
      'Content-Type': 'application/json',
      'Authorization': token ? 'JWT ' + token : '',
    };
  };

  handleSendOTP = event => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    let { phoneNumber } = this.state;
  
    axios
      .post(OTPUrl, { phoneNumber }, { headers: this.getHeaders(this.props.authToken) })
      .then(() => {
        this.setState({ otpSent: true, isSubmitting: false });
      })
      .catch(error => {
        if (error.response && error.response.status < 500) {
          this.setState({
            errorMessage: {
              nonFieldErrors: [error.response.data.message],
            },
          });
        } else {
          this.setState({
            errorMessage: {
              nonFieldErrors: ['Oops! Something happened. Please try again.'],
            },
          });
        }

        this.setState({ isSubmitting: false, otpSent: false });
      });
  };

  handleVerifyOtp = event => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    let { otp, phoneNumber } = this.state;

    axios
      .post(OTPVerificationUrl, { otp, phoneNumber }, { headers: this.getHeaders(this.props.authToken) })
      .then(() => {
        this.props.handleSuccess();
      })
      .catch(error => {
        if (error.response && error.response.status < 500) {
          this.setState({
            errorMessage: {
              nonFieldErrors: [error.response.data.message],
            },
          });
        } else {
          this.setState({
            errorMessage: {
              nonFieldErrors: ['Oops! Something happened. Please try again.'],
            },
          });
        }

        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { phoneNumber, otp } = this.state;

    return (
      <React.Fragment>
          <form
            className=""
            onSubmit={
              !this.state.otpSent ? this.handleSendOTP : this.handleVerifyOtp
            }
          >
            <div className="row">
              {this.state.otpSent ? (
                <>
                  <div className="col-md-12">
                    <span className="text--muted">
                      Please check your phone or email for verification code.
                    </span>
                    <label className="form__label mt-1rem mb-1rem">
                      Enter Verification Code
                    </label>
                    <input
                      className="form__input"
                      type="text"
                      placeholder="XXXX"
                      onChange={this.handleInputUpdate.bind(this, 'otp')}
                      value={otp}
                      required
                    />
                    {this.state.errorMessage.otp
                      ? this.state.errorMessage.email.map((err, i) => (
                          <p key={i} style={{ color: 'red', marginTop: '1em' }}>
                            {err}
                          </p>
                      ))
                      : null}

{this.state.errorMessage.nonFieldErrors
  ? this.state.errorMessage.nonFieldErrors.map((err, i) => (
                <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
  ))
  : null}

             
                  </div>
                </>
              ) : (
                <div className="col-md-6">
                  <label className="mt-1rem mb-1rem">Phone Number</label>
                  <Input
                    className=""
                    placeholder="0245xxxxxx"
                    type="text"
                    onChange={this.handleInputUpdate.bind(this, 'phoneNumber')}
                    value={phoneNumber}
                    required
                  />
                  {this.state.errorMessage.phoneNumber
                    ? this.state.errorMessage.phoneNumber.map((err, i) => (
                        <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                    ))
                    : null}

          {this.state.errorMessage.nonFieldErrors
            ? this.state.errorMessage.nonFieldErrors.map((err, i) => (
                <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
            ))
            : null}
       
                </div>
              )}
            </div>

            <button
              className="btn btn--gradient-primary btn--width-auto mt-2rem mb-2rem"
              type="submit"
            >
              {this.state.isSubmitting ? <LoadingOutlined /> : 'Submit'}
            </button>
          </form>
    
      </React.Fragment>
    );
  }
}

LoginForm.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  authToken: PropTypes.string.isRequired,
};

export default withRouter(WithUser(LoginForm));
