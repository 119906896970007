import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageNotFound from '../../pages/PageNotFound';
import PrivateRoute from '../../hocs/PrivateRoute';
import PaymentPage from './PaymentPage';
import PaymentSuccessfulPage from './PaymentSuccessfulPage';
import Header from '../Header';
import Footer from '../Footer';

export const PaymentRoutes = (props) => (
  <div>
    <Header />
    <Switch>
      <PrivateRoute
        exact
        path={`${props.match.path}/new/:policyId/`}
        component={PaymentPage}
      />
      <PrivateRoute
        exact
        path={`${props.match.path}/:paymentId/complete`}
        component={PaymentSuccessfulPage}
      />
      <PrivateRoute
        exact
        // if you change this path, update the code in PaymentSuccessfulPage's
        // componentDidMount that checks for the insurance type
        path={`${props.match.path}/motor/:paymentId/complete`}
        component={PaymentSuccessfulPage}
      />
      <PrivateRoute
        exact
        // if you change this path, update the code in PaymentSuccessfulPage's
        // componentDidMount that checks for the insurance type
        path={`${props.match.path}/property/:paymentId/complete`}
        component={PaymentSuccessfulPage}
      />
      <Route component={PageNotFound} />
    </Switch>
    <Footer />
  </div>
);
PaymentRoutes.propTypes = {
  match: PropTypes.object,
};

export default withRouter(PaymentRoutes);
