import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import typewriter from '../img/typewriter.svg';

class ToSPage extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <main className="center-auto mt-10rem wrapper--sub">
          <div
            className="mt-5rem"
            style={{ maxWidth: '800px', lineHeight: '2.5rem' }}
          >
            <div>
              <img src={typewriter} className="mr-1rem" alt="" />
              <h3 className="inline-block mb-2rem text--aqua">
                Our Terms of Service
              </h3>
            </div>
            <p className="mb-5rem">
              <span className="block mb-2rem">
                {`This simply says that we've got your best interest at heart and
                will always ensure that your data is safe and protected while
                using our service. This service is free to use for you and we'll
                get paid when you make a purchase from any of our listed
                insurance companies - so make it rain!`}
              </span>

              <span className="block mb-2rem">
                {`Should you decide to make a purchase from a company, we'll
                facilitate that purchase but the agreement is always between you
                and the company - that way things become simpler for all of us.`}
              </span>

              <span className="block mb-2rem">
                <strong>
                  <em>Last Updated: July 10, 2018</em>
                </strong>
              </span>
            </p>

            <h4 className="mb-2rem">Welcome to BestQuote </h4>
            <p className="mb-5rem">
              {`Thank you for using our price comparison website which is governed
              by these Terms and Conditions. Please read them carefully. In
              these Terms and Conditions, "we", "our" and "us" means BestQuote
              (Ghana) Limited and "you" and "your" means any person who uses our
              website. If you are unsure about any aspect of these Terms &amp;
              Conditions, or have any questions regarding our relationship with
              you, please contact us.`}
            </p>

            <h4 className="mb-2rem">About Our Insurance Services </h4>
            <p className="mb-5rem">
              {`We provide an independent online insurance search engine that has
              been designed to save you time and money. Not only do we strive to
              find you the most competitive quotes for insurance, we also aim to
              provide you with the information you need to make an informed
              choice on which policy or product best suits your needs. We
              compare insurance products from a range of insurers. We will ask
              you some questions and produce a list of quotations based on the
              answers you give and provide the information you need to help
              decide which is the right policy or product for you. This will
              enable you to make your own choice about how to proceed. We don't
              offer advice or make recommendations.`}
            </p>

            <h4 className="mb-2rem">How Are We Paid?</h4>
            <p className="mb-5rem">
              You will not be charged for using our website. Instead, when you
              use our website to buy a product or service from a third party,
              that third party pays a commission to us
            </p>

            <h4 className="mb-2rem">About mybestquote.com</h4>
            <p className="mb-5rem">
              BestQuote (Ghana) Limited is a price comparison website which
              helps you compare and buy insurance from every major insurance
              company in Ghana. BestQuote (Ghana) Limited is registered in Ghana
              (Company number CS384542014) and our registered address is The
              Victoria, First Norla Street, North Labone, Accra. BestQuote is
              licensed by the National Insurance Commission (“NIC”) as a
              corporate agent of Marine & General Insurance Brokers Limited
              licensed by the NIC.
            </p>

            <h4 className="mb-2rem">Scope</h4>
            <p className="mb-5rem">
              These Terms of Use govern the opening, use and closure of your
              BestQuote account and they constitute the legal relationship
              between you and us. These Terms and Conditions may be amended from
              time to time. Such amendments take effect immediately upon their
              publication on this website. The currently valid version is the
              version accessible on the website at the time the internet user
              makes concrete use of the website and its services. By accessing
              or using any part of the website or the services offered, the user
              agrees to be legally bound by these Terms and Conditions.
              Otherwise, use of the website and the services offered is not
              permitted. These Terms and Conditions (together with our Privacy
              Policy and Cookie Policy) represent the entire agreement between
              you and us relating to your use of our website. These terms also
              supercede any previous agreements, including previous terms and
              conditions of our website. These Terms and Conditions are subject
              to the laws of Ghana and the exclusive jurisdiction of their
              Courts. You are also advised to read the answers to the
              “Frequently Asked Questions” which are published on our website.
            </p>

            <h4 className="mb-2rem">Services of the Website</h4>
            <p className="mb-5rem">
              The website is aimed at end consumers (hereinafter also referred
              to as “customers”) who purchase, apply for or inform themselves
              (or each other) about insurance products online via the internet.
              On the website, customers receive free price overviews and
              references to the sources of supply of products that are offered
              online by major insurance companies registered with BestQuote. The
              information provided on the website does not represent any legally
              binding offer on the part of BestQuote. The information research
              is widely automated and based to a great extent on information
              that BestQuote receives from the insurance companies. Data from
              the insurance companies is repeatedly updated by the insurance
              company when need be. Therefore, BestQuote cannot assume any
              liability for the accuracy or inaccuracy of the information
              provided.
            </p>

            <h4 className="mb-2rem">Contractual Relationships </h4>
            <p className="mb-5rem">
              BestQuote does not become party to any purchase made in the course
              of acquiring the insurance products represented on the website.
              Contracts involving the acquisition of products or services
              represented on the website are concluded exclusively between the
              customers and insurance company. In the case of such contracts,
              the respective terms and conditions (including cancellation,
              refund and extension policies) of the individual insurance
              companies apply. BestQuote assumes no liability for all products
              purchased on the website. All brand names, logos and/or other
              product designations are registered and protected trademarks or
              trade names of the respective owners.
            </p>

            <h4 className="mb-2rem">Liability </h4>
            <p className="mb-5rem">
              The following exclusions and limitations apply with regard to
              BestQuote’s liability, notwithstanding other legal claim
              requirements: BestQuote assumes liability if the cause of damage
              is the result of willful misconduct or gross negligence on the
              part of BestQuote. In all other respects, liability for
              compensation for damage of any kind, regardless of the basis for
              claim, including liability for violation of mutual confidence in
              the preparation of contract, are excluded. If BestQuote assumes
              liability for an instance of simple negligence, this liability is
              limited to damage the occurrence of which could typically be
              expected due to circumstances known upon conclusion of the
              contract. BestQuote assumes no liability and makes no guarantee as
              to the type, completeness and quality of the insurance products,
              accessible on the website.
            </p>

            <h4 className="mb-2rem">Intellectual Property Rights </h4>
            <p className="mb-5rem">
              {`The website contains data, search results, texts, graphics,
              software and other information that are protected under
              intellectual property right provisions, copyright law and
              trademark law for the benefit of BestQuote and its employees.
              Reproduction, public distribution, modification and other
              encroachments on these rights, whether entirely or in part, are
              only permitted with the explicit written permission of BestQuote.
              Each unauthorised encroachment represents a violation of these
              Terms and Conditions and will be prosecuted. The automated reading
              of databases and website (e.g., via scraping) and the integration
              of the contents of the website in a frame without BestQuote's
              permission is not permitted. The user is solely permitted to use
              the application for the individual comparison of offers from
              insurance companies to meet his/her own private needs.`}
            </p>

            <h4 className="mb-2rem">Your BestQuote Account </h4>
            <p className="mb-5rem">
              Your BestQuote account enables you compare prices and benefits of
              insurance products from the major insurance companies after
              submitting your specifications and purchase with any of the
              payment instruments suitable for you. Your account provides you
              the history of all policies purchased and yet to be purchased. You
              have the right to update all the information in your account with
              regards to basic information, duration, destination, type of the
              insurance policy.
            </p>

            <h4 className="mb-2rem">Opening your BestQuote Account </h4>
            <p className="mb-5rem">
              To use our price comparison website, you must first open a
              BestQuote Account by registering your details on our website. As
              part of the signup process you will need to accept these Terms and
              Conditions and you must have legal capacity to accept the same.
              You may only open one BestQuote Account per email address. All
              information you provide during the signup process or any time
              thereafter must be accurate and truthful.
            </p>
          </div>
        </main>
      </div>
    );
  }
}

export default ToSPage;
