import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import Navbar from '../components/Navbar';

const FAQPage = () => {
  return (
    <div>
      <Navbar />
      <main className="site-content center-auto wrapper--sub">
        <div className="mt-5rem">
          <h3 className="mb-2rem">Our Customers Keep Asking Us</h3>
          <p className="mb-1rem text--muted">
            {`We've put together a curated list of frequently asked questions
            we've been getting from customers.`}
          </p>
          <p className="mb-1rem text--muted">
            {`Search through them - we do hope that these will resolve any
            concerns you've got`}
          </p>
        </div>

        <div className="mt-10rem">
          <h3 className="mb-2rem">Getting Started with BestQuote</h3>
          <p className="mb-5rem text--muted">
            {`Here's a curated list of frequently asked questions we've been
            getting from customers.`}
          </p>
        </div>

        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>What is BestQuote?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                BestQuote helps you compare and buy insurance from every major
                insurance company.
              </p>
              <p>
                www.mybestquote.com is a price comparison website operated by
                BestQuote Limited. BestQuote is convenient, free, and provides
                a great money saving opportunity to all its customers.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Is BestQuote an insurance company?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                No, BestQuote is not an insurance company. BestQuote Limited
                provides technology, marketing and sales services and partners
                with leading insurance companies to help customers make the
                best choice for insurance.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Is BestQuote authorised to sell insurance?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                BestQuote Limited is licensed by the National Insurance
                Commission (NIC) as a corporate agent of Marine & General
                Insurance Brokers.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How do I compare products?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {`You can compare prices and benefits of insurance products
                online. Simply select your preferred insurance product,
                complete the form, click on “Get BestQuote” and the relevant
                insurance products will be shown to you in ascending order by
                price.`}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How do I buy insurance?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>To buy insurance:</p>

              <ol className="ml-3rem mt-2rem mb-2rem">
                <li>Click the “Buy Now” button at the bottom of a policy</li>
                <li>
                  Check the policy details then enter the required information
                </li>
                <li>
                  Choose a payment method and you will be redirected to
                  complete payment for your selected method.
                </li>
              </ol>

              <p>
                When you have completed your purchase, you will be redirected
                back to mybestquote.com. The policy documents for your chosen
                insurance product will be made available to you.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How do I pay for insurance products?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Currently, BestQuote allows you to pay for your insurance
                product by mobile money (MTN, Airtel), or by card
                (Visa/MasterCard).
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What are the costs involved?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Comparing and purchasing products on BestQuote is 100% free
                and very easy.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How about the security of my data entered via mybestquote.com?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Data security is a matter of great importance to us.
                Therefore, BestQuote is registered with the Data Protection
                Agency. We use SSL encryption for our entire website and
                protect our systems with state of the art technology against
                unauthorized access.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Where is BestQuote based?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>BestQuote is headquartered in North Labone, Accra, Ghana.</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How do I update my personal details?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>To change your personal details securely:</p>

              <ol className="ml-3rem mt-2rem mb-2rem">
                <li>
                  {' '}
                  Click on “Login” at the top of the page Login using your
                  email address / phone number and password
                </li>
                <li>Click on your name at the top of the page</li>
                <li>Select “Account Settings”</li>
                <li>Edit the relevant fields</li>
                <li> Click “Save” Your details will now be updated.</li>
              </ol>

              <p>
                Please note that changes will not affect purchased products.
                Saved searches will automatically become void and you will
                need to input your details again to obtain new quotes based on
                your updated information.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                {"I've forgotten my password. What should I do?"}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>To request a password reset link:</p>

              <ol className="ml-3rem mt-2rem mb-2rem">
                <li>
                  {' '}
                  Click on “Login” at the top of the page Login using your
                  email address and password
                </li>
                <li>
                  Click on the “Forgot your password?” link underneath the
                  space for your password
                </li>
                <li>
                  You’ll be asked to enter your email address and date of
                  birth. Do this and then click “Continue”
                </li>
                <li>Edit the relevant fields</li>
                <li> Click “Save” Your details will now be updated.</li>
              </ol>

              <p>
                We will send you a link to a page where you can reset your
                password. Once this has been done, you’ll be redirected to a
                page where you can sign in to your account.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How do I change my password?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>To change your password securely:</p>

              <ol className="ml-3rem mt-2rem mb-2rem">
                <li>
                  {' '}
                  Click on “Login” at the top of the page Login using your
                  email address and password
                </li>
                <li>Sign in using your existing password</li>
                <li>
                  {'Select "Account Settings" from the navigation dropdown'}
                </li>
                <li>{'Select "Add new password"'}</li>
                <li>Enter your new password and enter it again to confirm</li>
                <li>{'Click "Save Changes"'}</li>
                <li>Your details will now be updated.</li>
              </ol>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>

        <div className="mt-5rem">
          <h3 className="mb-2rem">Travel Insurance on BestQuote</h3>
          <p className="mb-5rem text--muted">
            Some frequently asked questions on Travel Insurance Policies
          </p>
        </div>

        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What does travel insurance protect me from?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {`Travel insurance is an important step when you're planning for
                a vacation or a business trip. Travel insurance may be a
                requirement to apply for certain visas in Ghana. A travel
                insurance policy can protect you from a variety of unexpected
                circumstances while you're travelling.`}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How will I receive my travel insurance policy documents?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Travel insurance policy documents will be sent to you by
                email, to the email address you provided.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What happens if I lose my travel insurance policy documents?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {`You can reprint copies of your travel insurance policy
                documents from your account. Click the 'Sign in' button at the
                top of the page Sign in using your email address & password
                Select “Active Policies” Select the applicable policy Click
                “Print”`}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>

        <div className="mt-5rem">
          <h3 className="mb-2rem">Motor Insurance on BestQuote</h3>
          <p className="mb-5rem text--muted">
            Some frequently asked questions on Motor Insurance Policies
          </p>
        </div>

        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Why should I insure my vehicle?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                It is required by law to do so. Secondly, you get covered and
                compensated for all injuries and damages to your vehicle as
                well as that for third parties.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What are the different types of motor insurance?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                There are three types of motor insurance; Third Party motor
                insurance, Third Party Fire and Theft motor insurance and
                Comprehensive motor insurance. Third Party motor insurance
                indemnifies you against death or bodily injury to any third
                parties, and/or damage to property belonging to third parties
                Third Party Fire and Theft is an extension of the Third Party
                Cover, and provides indemnity for loss of or damage to your
                vehicle occasioned by Fire or Theft Comprehensive motor
                insurance cushions you against all of the risks described
                above, in addition to all other accidental damages to your
                vehicle.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What is extra TPPD cover?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {`TPPD stands for Third Party Property Damage. TPPD provides
                cover to pay for damages that your vehicles causes to another
                person's property. The minimum limit for third party property
                damage at present is GH¢2,000. However this can be increased
                at your request.`}
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Can I make a claim on my motor insurance policy on BestQuote?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                You can send a request for a claim on your motor insurance
                policy purchased on BestQuote. The request will be forwarded
                to the insurance company and the claim will be fulfilled by
                the insurance company if the request is valid.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What happens after I purchase a motor insurance policy on
                BestQuote?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                The details of the purchased policy will be sent to the
                respective insurance company. The insurance company will enter
                the details of the policy into the Motor Insurance Database
                and issue an electronic sticker which will be sent to you via
                your email as soon as possible.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>

        <div className="mt-5rem">
          <h3 className="mb-2rem">Property Insurance on BestQuote</h3>
          <p className="mb-5rem text--muted">
            Some frequently asked questions on Property Insurance Policies
          </p>
        </div>

        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Why do I need property insurance?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {`Property insurance covers losses and damages to an
                individual's residence or commercial building(s), along with
                their furnishings and other assets. Property insurance also
                provides liability coverage against accidents in the home or
                in the commercial building.`}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What are the types of property insurance?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {`There are two types of property insurance; homeowner's
                insurance and insurance for commercial building(s).`}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Is property insurance a mandatory requirement?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {`In Ghana the Insurance Act 2006, Act 724 requires that all
                commercial buildings and those under construction are insured
                with an insurance company against the hazards of collapse,
                fire, earthquake, storm and flood, and an insurance policy
                issued for it. However, homeowner's insurance is not
                mandatory.`}
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How much insurance do i need for my property?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {`It depends on the value of the building's physical structure
                and the value of all the assets contained in it. The value of
                the building and its assets becomes the sum insured.`}
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What happens when I purchase a property insurance policy on
                mybestquote.com?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                After purchasing a property insurance policy on
                mybestquote.com, a vetting officer from the insurance company
                will assess and evaluate your building and its assets before
                an official policy is issued to you from the insurance
                company. This process will be completed in 48 hours.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Can i make a claim on my property insurance policy on
                mybestquote.com?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                You can send a request to make a claim on your property
                insurance policy on mybestquote.com. The request will be sent
                to the insurance company and the claim will be fulfilled if it
                is deemed to be valid.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </main>
    </div>
  );
};


export default FAQPage;
