import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import WithProducts from '../../hocs/WithProducts';
import PropTypes from 'prop-types';

const  MotorProductBreakdown = props => {
  let nfNoCurrency = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const urlOptions = { T: 'travel', M: 'motor', L: 'life' };

  const policyTypes = {
    T: 'Third Party',
    F: 'Third Party Fire and Theft',
    C: 'Comprehensive',
  };

  return (
    <React.Fragment>
      <div
        className="policy-breakdown"
        style={{
          boxShadow: '2px 2px 40px 1px rgba(0, 0, 0, 0.15)',
          border: 'none',
        }}
      >
        <div className="policy__header">
          <img
            alt=""
            className="policy__logo-img policy__logo-img--summary"
            src={props.product.company.logoUrl}
          />
        </div>
        <div
          className="policy__info policy__info--summary policy__info--breakdown"
          style={{ marginBottom: '1rem' }}
        >
          <p className="policy__category">{props.product.name}</p>
          <p className="text--muted mb-1rem">by {props.product.company.name}</p>

          <p className="policy-breakdown__premium mt-1rem">
              <span
                style={{
                  fontSize: '2rem',
                  fontWeight: 'bolder',
                  color: '#1b4241',
                }}
              >
                Premium: {props.premium.currency}{' '}
                {nfNoCurrency.format(props.premium.amount)}
              </span>
          </p>
        </div>

        <hr />

        <div className="policy__features">
          <div className="policy__feature">
            <span>Policy type</span>
            <span>{policyTypes[props.product.motorType]}</span>
          </div>
          <div className="policy__feature">
            <span>Start date</span>
            <span>{moment(props.product.startDate).format('ll')}</span>
          </div>
          <div className="policy__feature">
            <span>Duration</span>
            <span style={{ width: '300px' }}>
              {props.product.motorPolicyInfo.duration} months
              {/* - ({endDateApprox}) */}
            </span>
          </div>
          <div className="policy__feature">
            <span>Excess bought back</span>
            <span>
              {props.product.motorPolicyInfo.excessBoughtBack
                ? props.product.motorPolicyInfo.excessBoughtBack
                : 'None'}
            </span>
          </div>
          <div className="policy__feature">
            <span>Extra cover</span>
            <span>
              {props.product.motorPolicyInfo.extraCover ? props.product.motorPolicyInfo.extraCover : 'None'}
            </span>
          </div>
        </div>

        <hr />

        {!props.isPurchasing ? (
          <div className="policy__buttons">
            <Link
              to={{
                pathname: `/${urlOptions[props.product.type]}/products/${
                  props.product.id
                }/details`,
                state: { id: props.product.id },
              }}
            >
              <button className="btn btn--buy btn--gradient-border">
                Buy Now
              </button>
            </Link>
          </div>
        ) : null}
      </div>
      </React.Fragment>
  );
};

MotorProductBreakdown.propTypes = {
  isPurchasing: PropTypes.bool,
  product: {
    id: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    motorPolicyInfo: {
      duration: PropTypes.number,
      excessBoughtBack: PropTypes.number,
      extraCover: PropTypes.string,
    },
    company: {
      logoUrl: PropTypes.string,
      name: PropTypes.string,
    },
    motorType: PropTypes.string,
  },
  premium: {
    amount: PropTypes.number,
    currency: PropTypes.string,
  },
};
export default WithProducts(MotorProductBreakdown);
