import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';

import WithUser from '../../hocs/WithUser';
import bqLogo from '../../img/bestquote-logo.png';

class FacebookLogin extends Component {
  state = {
    isSubmitting: false,
    errorMessage: '',
  };

  componentDidMount() {
    this.setState({ isSubmitting: true }, () => {
      let code = this.props.location.search.substring(6);
      axios
        .post(
          `${process.env.REACT_APP_API_BASE}/api/v1/users/facebook-login/`,
          {
            code: code,
          },
        )
        .then(res => {
          this.props.setUser(res.data.results);
          this.props.history.push('/account');
        })
        .catch(() => {
          this.setState({ errorMessage: 'Unable to activate account' });
          this.setState({ isSubmitting: false });
        });
    });
  }

  render() {
    return (
      <div className="auth">
        <div className="auth__bounding-box">
          <div
            className="align--right mt-2rem"
            style={{ fontSize: '3rem', cursor: 'pointer' }}
          >
            <span onClick={this.props.history.goBack}>&times;</span>
          </div>
          <img
            src={bqLogo}
            alt="BestQuote Logo"
            className="bq-logo bq-logo--auth mb-2rem"
          />
          <p className="site-content__lead">Hello *name*,</p>
          <p className="">Thank you for signing up to BestQuote</p>
          <p className="">
            {"We're delighted to have you here! Please wait while we log you in."}
          </p>
          <br />
          <br />
          {this.state.isSubmitting ? <p>... Logging in ...</p> : null}
          <p>{this.state.errorMessage}</p>
          {this.state.errorMessage ? <Link to="/">Go home</Link> : null}
          <br />
          <hr />
          <br />
          <small>
            If you have any questions about using the product, feel free to
            message us at hello@mybestquote.com.
          </small>
          <small>
            This message confirms activation of account for the product or
            products listed above
          </small>
        </div>
      </div>
    );
  }
}
FacebookLogin.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  setUser: PropTypes.func,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default withRouter(WithUser(FacebookLogin));
