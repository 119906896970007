/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PolicyCard from '../../../components/Cards/PolicyCard';
import { getHeaders, errorMessage, lifeUrl } from '../../../config';
import axios from 'axios';
import WithProducts from '../../../hocs/WithProducts';
import WithUser from '../../../hocs/WithUser';
import { withRouter } from 'react-router-dom';

const LifeCompanyPolicyCard = props => {
  const [policies, setPolicies] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBuy = policy => {
    if (props.authUser.token) {
      setIsSubmitting(true);
      let data = {
        ...policy.payload,
        companyId: policy.companyId,
        planId: policy.planId,
      };

      axios
        .post(lifeUrl, data, { headers: getHeaders() })
        .then(res => {
          let id = res.data.data.id;
          props.history.push(`/buy-life-insurance/${id}/buy`);
          setIsSubmitting(false);
        })
        .catch(err => {
          setIsSubmitting(false);
          if (err.response) {
            errorMessage('Oops! Something went wrong, kindly retry.');
          } else {
            errorMessage(
              'Error connecting, kindly check your internet connection.',
            );
          }
        });
    } else {
      props.history.push({
        pathname: '/auth/login',
        state: {
          from: { pathname: `/buy-life-insurance/${props.match.params.slug}` },
        },
      });
    }
  };

  const formatProducts = products => {
    let prods = [];

    products.forEach(p => {
      prods.push({
        isPolicy: true,
        payload: p.payload,
        premium: {
          currency: p.preview.lifePlan.premiumCurrency,
          amount:
            p.payload.formType === 'premium'
              ? p.preview.sumAssured
              : p.preview.premium,
        },
        formType:
          p.payload.formType === 'premium'
            ? `Benefits at a monthly premium of ${p.preview.lifePlan.premiumCurrency} ${p.preview.premium} for ${p.preview.lifePlan.duration} years`
            : `Monthly Contributions with benefits of ${p.preview.lifePlan.premiumCurrency} ${p.preview.sumAssured} for ${p.preview.lifePlan.duration} years`,
        planName: p.preview.lifePlan.name,
        companyLogo: p.company.logoUrl,
        companyName: p.company.name,
        companyId: p.company.id,
        lifePlan: p.preview.lifePlan,
        planId: p.preview.lifePlan.id,
        compare: false,
        showDetail: false,
      });
    });

    return prods;
  };

  useEffect(() => {
    if (props.lifeCompanyProducts.length) {
      setPolicies(formatProducts(props.lifeCompanyProducts));
    } else {
      axios
        .post(`${lifeUrl}/preview`, props.lifeCompanyPayload, {
          headers: getHeaders(),
        })
        .then(res => {
          props.setLifeCompanyProducts(res.data.data);
          setPolicies(formatProducts(res.data.data));
        })
        .catch(err => {
          if (err.response) {} else {}
        });
    }
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <div className="">
        {policies.length ? (
          <p style={{ textAlign: 'center', color: '#009B9C' }}>
            <>
              {`${policies[0].planName}`} {policies[0].formType}
            </>
          </p>
        ) : null}
        {policies.length ? (
          <>
            {policies.map((policy, i) =>
              policy.isPolicy ? (
                <div className="mb-3rem" key={i}>
                  <PolicyCard
                    policy={policy}
                    handleBuy={handleBuy.bind(this, policy)}
                    isSubmitting={isSubmitting}
                  />
                </div>
              ) : null,
            )}

            {policies.map((policy, i) =>
              policy.isPolicy ? (
                <div className="motor_compare_policies_list_card_mobile mb-3rem" key={i}>
                  <PolicyCard
                    policy={policy}
                    handleBuy={handleBuy.bind(this, policy)}
                    isSubmitting={isSubmitting}
                  />
                </div>
              ) : null,
            )}
          </>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default withRouter(WithProducts(WithUser(LifeCompanyPolicyCard)));
