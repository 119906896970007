import { constants } from '../../app-constants';

export const setProducts = products => ({
  type: constants.SET_PRODUCTS,
  payload: products,
});

export const setMotorProducts = products => ({
  type: constants.SET_MOTOR_PRODUCTS,
  payload: products,
});

export const setHealthProducts = products => ({
  type: constants.SET_HEALTH_PRODUCTS,
  payload: products,
});

export const setLifeProducts = products => ({
  type: constants.SET_LIFE_PRODUCTS,
  payload: products,
});

export const setLifeCompanyProducts = products => ({
  type: constants.SET_LIFE_COMPANY_PRODUCTS,
  payload: products,
});

export const setPropertyProducts = products => ({
  type: constants.SET_PROPERTY_PRODUCTS,
  payload: products,
});

export const setPayload = payload => ({
  type: constants.SET_PAYLOAD,
  payload: payload,
});

export const setPropertyPayload = payload => ({
  type: constants.SET_PROPERTY_PAYLOAD,
  payload: payload,
});

export const setMotorPayload = payload => ({
  type: constants.SET_MOTOR_PAYLOAD,
  payload: payload,
});

export const setLifePayload = payload => ({
  type: constants.SET_LIFE_PAYLOAD,
  payload: payload,
});

export const setLifeCompanyPayload = payload => ({
  type: constants.SET_LIFE_COMPANY_PAYLOAD,
  payload: payload,
});

export const setHealthPayload = payload => ({
  type: constants.SET_HEALTH_PAYLOAD,
  payload: payload,
});

export const clearPayload = () => ({
  type: constants.CLEAR_PAYLOAD,
});

export const toggleProduct = id => ({
  type: constants.TOGGLE_PRODUCT,
  payload: id,
});

export const clearProductToggles = () => ({
  type: constants.CLEAR_PRODUCT_TOGGLES,
});

export const setCompareProducts = products => ({
  type: constants.SET_COMPARE_PRODUCTS,
  payload: products,
});

export const clearCompareProducts = () => ({
  type: constants.CLEAR_COMPARE_PRODUCTS,
});

export const toggleMessenger = () => ({
  type: constants.TOGGLE_MESSENGER,
});
