import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
class MailingListForm extends Component {
  state = {
    email: '',
    emailSaved: false,
  };

  handleInputUpdate = event => {
    this.setState({ email: event.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API_BASE}/api/v1/subscriptions/`;
    const headers = { 'Content-Type': 'application/json' };
    const { email } = this.state;
    const payload = { email };

    axios
      .post(url, payload, { headers })
      .then(() => {
        this.setState({ emailSaved: true });
      })
      .catch(() => {});
  };

  render() {
    const { email, emailSaved } = this.state;

    return (
      <>
        {emailSaved ? (
          <div className="" style={{ color: 'skyblue' }}>
            You Joined Successfully <FontAwesomeIcon icon={faCheckCircle} />{' '}
          </div>
        ) : (
          <form className="flex mailing-list" onSubmit={this.handleSubmit}>
            <input
              className={cx(
                this.props.width < 1025 ? 'mt-2rem center-auto' : null,
                'form__input form__input--transparent',
              )}
              type="email"
              onChange={this.handleInputUpdate}
              value={email}
              required
            />
            <button
              type="submit"
              className={cx(
                this.props.width < 1025 ? 'mt-2rem center-auto' : null,
                'btn btn--width-lng btn--gradient-primary btn--no-case',
              )}
            >
              Give me the BestQuotes
            </button>
          </form>
        )}
      </>
    );
  }
}
MailingListForm.propTypes = {
  width: PropTypes.number,
};

class MailingList extends Component {
  state = {
    width: 0,
    height: 0,
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    return (
      <div className="site-content__muted v-spacer">
        <div className="wrapper">
          <h3 className="mb-2rem h-spacer">
            Want to have the best, cost-saving products and more delivered to
            your mailbox?
          </h3>

          <div className="v-spacer center-content flex">
            <div
              className={cx(
                'mb-2rem',
                this.state.width > 767 ? 'mr-2rem' : null,
              )}
            >
              <p className="mt-2rem mb-1rem">
                Get the latest updates on BestQuotes, served fresh!
              </p>
              <small className="text--muted">
                {`*We'll never spam your inbox or share your details with a third
                party.`}
              </small>
            </div>

            <div className="site-content">
              <MailingListForm width={this.state.width} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MailingList;
