import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import BasicTooltip from './BasicTooltip';

const SelectMultipleInput = props => {
  const [showToolTip, setShowToolTip] = useState(false);
  const [open, setOpen] = useState();
  const {
    toolTipLabel,
    name,
    label,
    toolTipLabel2,
    required,
    value,
    onChange,
    optionList,
    placeholder,
  } = props;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    toolTipLabel && setShowToolTip(true);
  }, [toolTipLabel]);

  const handleOpen = value_ => {
    setOpen(value_);
  };

  return (
    <React.Fragment>
      <Form.Item
        name={name}
        label={
          <>
            {label ? label : ''}
            {showToolTip ? (
              <>
                <BasicTooltip label={toolTipLabel} label2={toolTipLabel2} />
              </>
            ) : (
              ''
            )}
          </>
        }
        initialValue={value}
        rules={[
          {
            required: required,
          },
        ]}
        colon={false}
      >
        <Select
          value={value}
          name={name}
          onChange={val => {
            onChange(val);
            setOpen(false);
          }}
          open={open}
          onFocus={() => handleOpen(true)}
          onBlur={() => handleOpen(false)}
          onSearch={() => handleOpen(true)}
          mode={'multiple'}
          placeholder={placeholder}
          defaultValue={value}
          optionLabelProp="label"
          optionFilterProp="label"
          options={optionList}
          style={{ width: '100%', backgroundColor: '#f2f2f2' }}
          className="form_input_muted"
        />
      </Form.Item>
    </React.Fragment>
  );
};

SelectMultipleInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  inputType: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  toolTipLabel: PropTypes.string,
  toolTipLabel2: PropTypes.string,
  optionList: PropTypes.array,
  required: PropTypes.bool,
};

export default SelectMultipleInput;
