/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import WithProducts from '../../hocs/WithProducts';

class LifeProductBreakdown extends React.Component {
  render() {
    let product = this.props.product;
    let premium = this.props.premium;

    let { plan, startDate } = product;

    let nfNoCurrency = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const urlOptions = { T: 'travel', M: 'motor', L: 'life', H: 'health' };

    return (
      <div className="policy-breakdown">
        <div className="policy__header">
          <img
            alt=""
            className="policy__logo-img policy__logo-img--summary"
            src={product.company.logoUrl}
          />
        </div>
        <div
          className="policy__info policy__info--summary policy__info--breakdown"
          style={{ marginBottom: '3rem' }}
        >
          <p className="policy__category">{plan.name}</p>
          <p className="text--muted mb-1rem">by {product.company.name}</p>

          <p className="policy-breakdown__premium mt-1rem">
            {premium ? (
              <span
                style={{
                  fontSize: '2rem',
                  fontWeight: 'bolder',
                  color: '#1b4241',
                }}
              >
                Premium: {premium ? premium.currency : null}{' '}
                {nfNoCurrency.format(premium.amount)}
              </span>
            ) : null}
          </p>
        </div>

        <div />

        <hr />

        <div className="policy__features">
          <div className="policy__feature">
            <span>Policy type</span>
            <span>{plan.planType}</span>
          </div>
          <div className="policy__feature">
            <span>Start date</span>
            <span>{moment(startDate).format('ll')}</span>
          </div>
          {/* <div className="policy__feature">
            <span>Duration</span>
            <span style={{ width: '300px' }}>
              {duration} months
              
            </span>
          </div> */}
          {/* <div className="policy__feature">
            <span>Excess bought back</span>
            <span>
              {motorPolicyInfo.excessBoughtBack
                ? motorPolicyInfo.excessBoughtBack
                : 'None'}
            </span>
          </div>
          <div className="policy__feature">
            <span>Extra cover</span>
            <span>
              {motorPolicyInfo.extraCover ? motorPolicyInfo.extraCover : 'None'}
            </span>
          </div> */}
        </div>

        <hr />

        {!this.props.isPurchasing ? (
          <div className="policy__buttons">
            <Link
              to={{
                pathname: `/${urlOptions.L}/products/${product.id}/details`,
                state: { id: product.id },
              }}
            >
              <button className="btn btn--buy btn--gradient-border">
                Buy Now
              </button>
            </Link>
          </div>
        ) : null}
      </div>
    );
  }
}
LifeProductBreakdown.propTypes = {
  isPurchasing: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    motorPolicyInfo: PropTypes.shape({
      duration: PropTypes.number,
      excessBoughtBack: PropTypes.number,
      extraCover: PropTypes.string,
    }),
    company: PropTypes.shape({
      logoUrl: PropTypes.string,
      name: PropTypes.string,
    }),
    motorType: PropTypes.string,
  }),
  premium: PropTypes.number,
};

export default WithProducts(LifeProductBreakdown);
