import React, { useEffect, useState } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import bqLogo from '../img/bestquote-logo.png';
import WithUser from '../hocs/WithUser';
import Sidenav from '../components/Sidenav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import bqUserIcon from '../img/bq-user-icon.svg';

const Navbar = (props) => {
  const [showSidenav, setShowSidenav] = useState(false);

  const handleToggleSidenave = () => {
    setShowSidenav(!showSidenav);
  };

  const handleSidenavLogout = () => {
    props.unsetUser();
    setShowSidenav(false);
  };

  useEffect(() => {}, []);

  const menu = [
    {
      key: '1',
      label: (
        <Link to="/account" className="">
          My Policies
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to="/account/settings" className="">
          My Account
        </Link>
      ),
    },
    {
      key: '3',
      label: (
        <div className="cursor-pointer" onClick={props.unsetUser}>
          Logout
        </div>
      ),
    },
  ];

  return (
    <div className="bq-wrapper">
      <Sidenav
        showDrawer={showSidenav}
        handleClose={handleToggleSidenave}
        authUser={props.authUser}
        handleLogout={handleSidenavLogout}
      />
      <nav className={'bq-navbar'}>
        <div className="align-vertical">
          <a href={process.env.REACT_APP_HOMEPAGE_URL}>
            <img
              src={bqLogo}
              className="bq-logo bq-logo--navbar"
              alt="BestQuote Logo"
            />
          </a>
          {/* {props.hidden404 ? null : (
            <span className="navbar__contact navbar--hidden">
              +233 302 904 929
            </span>
          )} */}
        </div>
        {props.hidden404 ? null : (
          <div className="bq-navbar__links bq-navbar--hidden">
            <NavLink
              to="/buy-motor-insurance"
              className={'bq-navbar__link'}
              activeClassName="bq-navbar__link--active"
            >
              Car
            </NavLink>
            <NavLink
              to="/buy-travel-insurance"
              className={'bq-navbar__link'}
              activeClassName="bq-navbar__link--active"
            >
              Travel
            </NavLink>
            <NavLink
              to="/buy-life-insurance"
              className={'bq-navbar__link'}
              activeClassName="bq-navbar__link--active"
            >
              Life
            </NavLink>
            <NavLink
              to="/property"
              className={'bq-navbar__link'}
              activeClassName="bq-navbar__link--active"
            >
              Home
            </NavLink>
          </div>
        )}
        <div className="bq-navbar__auth bq-navbar--hidden">
          {props.authUser.user ? (
              <Dropdown menu={{ items: menu }} placement="bottom">
                <div className='cursor-pointer'>
                  <Space>
                    <img src={bqUserIcon} alt="user-icon" />{' '}
                    {`${
                      props.authUser.user.firstName
                        ? props.authUser.user.firstName.toUpperCase()
                        : 'Hello Friend'
                    }`}
                  <DownOutlined />
                  </Space>
                </div>
              </Dropdown>
          ) : (
            <div className="flex gap-4">
              <button
                onClick={() =>
                  props.history.push({
                    pathname: '/auth/login',
                    state: { from: { pathname: window.location.pathname } },
                  })
                }
                className="btn btn--width-sm ml-2rem btn--white cursor-pointer"
              >
                Log in
              </button>

              <Link to={'/auth/signup'}>
                <button className="btn btn--width-sm ml-2rem btn--gradient-primary btn--width-auto">
                  Sign Up
                </button>
              </Link>
            </div>
          )}
        </div>
        <div className="bq-navbar__menu-button">
          <span onClick={handleToggleSidenave} className="pointer">
            {showSidenav ? (
              <button
                className={'btn btn--width-sm ml-2rem btn--gradient-border'}
              >
                Close <FontAwesomeIcon icon={faTimes} />
              </button>
            ) : (
              <button
                className={'btn btn--width-sm ml-2rem btn--gradient-border'}
              >
                Menu <FontAwesomeIcon icon={faBars} />
              </button>
            )}
          </span>
        </div>
      </nav>
    </div>
  );
};

Navbar.propTypes = {
  match: PropTypes.object,
  unsetUser: PropTypes.func,
  history: PropTypes.object,
  authUser: PropTypes.shape({
    token: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
    }),
  }),
  hidden404: PropTypes.bool,
};

export default withRouter(WithUser(Navbar));
