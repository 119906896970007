import React from 'react';
import Helmet from 'react-helmet';
import Policies from './Policies';
import * as c from '../../../app-constants/accounts';

class MotorPolices extends React.Component {
  static defaultProps = {
    pathName: 'Policies',
    roles: [c.MANAGEMENT, c.FINANCE, c.SALES],
  };

  render() {
    return (
      <div className="container">
        <Helmet title="Motor Polices" />
        <Policies />
      </div>
    );
  }
}

export default MotorPolices;
