/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SafetyCertificateFilled } from '@ant-design/icons';
import { statsUrl, getHeaders } from '../../config';
import axios from 'axios';

const DashboardOverview = props => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    axios.get(statsUrl, { headers: getHeaders() }).then(res => {
      setStats(res.data.data);
    });
  }, []);

  const handleClick = type => {
    if (type === 'T') {
      props.history.push('/account/travel');
    } else if (type === 'M') {
      props.history.push('/account/motor');
    } else if (type === 'P') {
      props.history.push('/account/property');
    } else if (type === 'H') {
      props.history.push('/account/health');
    } else if (type === 'L') {
      props.history.push('/account/life');
    }
  };

  const OverviewCard = ({ stat }) => (
    <div
      className="dashboard_home_overview_card"
      onClick={() => handleClick(stat.type)}
    >
      <div className="dashboard_home_overview_card_top">
        <div>
          <span>{stat.currency}</span>
        </div>

        <SafetyCertificateFilled style={{ color: '#009B9C' }} />
      </div>
      <h2>{stat.amount}</h2>
      <div className="dashboard_home_overview_card_bottom">
        <span>{stat.title}</span>
        <span>{stat.count}</span>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="dashboard_home">
        <div className="dashboard_home_overview">
          {stats.map((s, i) => (
            <OverviewCard stat={s} key={i} />
          ))}
          <hr />
        </div>
      </div>
    </React.Fragment>
  );
};
DashboardOverview.propTypes = {
  match: PropTypes.object,
};

export default withRouter(DashboardOverview);
