import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FadeLoader } from '../../../components/UI/FadeLoader';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { getFormHeaders, lifeUrl } from '../../../config';
import FileUpload from '../FileUpload';

class LifeClaimForm extends Component {
  state = {
    claimId: 0,
    required: {
      // PROPERTY DAMAGE DOCUMENTS
      policePropertyReport: null,
      picturesOfDamagedVehicle: null,
      licenseOfDriver: null,

      // INJURY CASE DOCUMENTS
      policeInjuryReport: null,
      medicalReport: null,
      medicalReceipt: null,
      passportPicture: null,

      // DECEASED CASE DOCUMENTS
      policeDeceasedReport: null,
      deathCertificate: null,
      deceasedAffidavit: null,
      letterOfAdministration: null,
      endorsedPassportPictureOfAdministrator: null,
    },
    estimate_of_cost: '',
    dob_of_injured_victim: null,
    dob_of_deceased: null,

    successMessage: '',
    errorMessage: '',
    isSubmitting: false,
    isClaiming: false,
  };

  handleFiles = (stateName, document) => event => {
    this.setState({
      [stateName]: {
        ...this.state[stateName],
        [document]: event.target.files[0],
      },
    });
  };

  handleDateUpdate = stateName => date => {
    this.setState({ [stateName]: date });
  };

  handleInput = event => {
    let { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  onSubmit = async event => {
    event.preventDefault();

    let documents = [
      ...Object.keys(this.state.required).map(key => {
        return { document_name: key, document: this.state.required[key] };
      }),
    ];

    let formData = new FormData();
    formData.append(
      'estimate_of_cost',
      Number(this.state.estimate_of_cost).toFixed(2),
    );

    formData.append(
      'dob_of_deceased',
      moment(this.state.dob_of_deceased).format('YYYY-MM-DD'),
    );
    formData.append(
      'dob_of_injured_victim',
      moment(this.state.dob_of_injured_victim).format('YYYY-MM-DD'),
    );

    let claimFiles = new FormData();
    documents.forEach(document => {
      claimFiles.append(document.document_name, document.document);
    });

    this.setState({ isSubmitting: true });

    try {
      await axios({
        headers: getFormHeaders(),
        method: 'POST',
        url: `${lifeUrl}/${this.props.match.params.policyId}/claim/`,
        data: formData,
      });

      try {
        await axios({
          headers: getFormHeaders(),
          method: 'POST',
          url: `${lifeUrl}/${this.props.match.params.policyId}/claim-documents/`,
          data: claimFiles,
        });
      } catch (err) {
        console.error(err);
        this.setState({
          errorMessage: 'Something went wrong. Please try again',
        });
      }

      this.props.history.push('/account/claims');
    } catch (err) {
      console.error(err);
      this.setState({
        isSubmitting: false,
        errorMessage: 'Something went wrong. Please try again.',
      });
      window.scrollTo(0, 0);
    } finally {
      this.setState({ isSubmitting: false, isClaiming: false });
    }
  };

  render() {
    let { product } = this.props;

    return (
      <div>
        <p className="mb-1rem">Make a Claim on Policy</p>
        <p className="text--muted mb-2rem">
          Review the policy below and add the required details &amp; documents
          to make your claim
        </p>
        <p style={{ color: 'red' }}>{this.state.errorMessage}</p>

        <div className="align-vertical mb-2rem" style={{ fontSize: '12px' }}>
          <img
            alt={product.company.name}
            className="mr-2rem"
            src={product.company.logoUrl}
            style={{ maxHeight: '3rem' }}
          />
          <span>{product.company.name}</span>
        </div>

        <form className="form" onSubmit={this.onSubmit}>
          <div className="flex mb-2rem" style={{ fontSize: '12px' }}>
            <div className="claim-form mb-2rem">
              <div className="claim-form__loss-type flex-column mb-2rem">
                <label
                  className="form__label mt-1rem mb-1rem"
                  htmlFor="estimate_of_cost"
                >
                  Estimate of Cost
                </label>
                <input
                  className="form__input"
                  type="number"
                  min="0"
                  step="0.01"
                  name="estimate_of_cost"
                  onChange={this.handleInput}
                  value={this.state.estimate_of_cost}
                />

                <label
                  className="form__label mt-1rem mb-1rem"
                  htmlFor="dob_of_injured_victim"
                >
                  {/* // Todo: Cannot extend past today */}
                  Date of Birth of Injured Victim
                </label>
                <DatePicker
                  selected={this.state.dob_of_injured_victim}
                  placeholderText="DD/MM/YYYY"
                  dateFormat="DD/MM/YYYY"
                  className="form__input"
                  onChange={this.handleDateUpdate('dob_of_injured_victim')}
                  required
                />

                <label
                  className="form__label mt-1rem mb-1rem"
                  htmlFor="dob_of_deceased"
                >
                  Date of Birth of Deceased
                </label>
                <DatePicker
                  selected={this.state.dob_of_deceased}
                  placeholderText="DD/MM/YYYY"
                  dateFormat="DD/MM/YYYY"
                  className="form__input"
                  onChange={this.handleDateUpdate('dob_of_deceased')}
                  required
                />

                <p className="mt-1rem mb-1rem form__label">
                  Required Documents for all claims
                </p>

                <FileUpload
                  document={this.state.required.policePropertyReport}
                  fileName="Police property report"
                  documentName="policePropertyReport"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                {/* // TODO: update file handler to allow for multiple files */}
                <FileUpload
                  document={this.state.required.picturesOfDamagedVehicle}
                  fileName="Pictures of damaged vehicle"
                  documentName="picturesOfDamagedVehicle"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                <FileUpload
                  document={this.state.required.licenseOfDriver}
                  fileName="Licence of driver"
                  documentName="licenseOfDriver"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                <FileUpload
                  document={this.state.required.medicalReport}
                  fileName="Medical report"
                  documentName="medicalReport"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                <FileUpload
                  document={this.state.required.medicalReceipt}
                  fileName="Medical receipt"
                  documentName="medicalReceipt"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                <FileUpload
                  document={this.state.required.passportPicture}
                  fileName="Passport picture"
                  documentName="passportPicture"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                <FileUpload
                  document={this.state.required.policeInjuryReport}
                  fileName="Police injury report"
                  documentName="policeInjuryReport"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                <FileUpload
                  document={this.state.required.deathCertificate}
                  fileName="Death certificate"
                  documentName="deathCertificate"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                <FileUpload
                  document={this.state.required.deceasedAffidavit}
                  fileName="Deceased affidavit"
                  documentName="deceasedAffidavit"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                <FileUpload
                  document={this.state.required.letterOfAdministration}
                  fileName="Letter of administration"
                  documentName="letterOfAdministration"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                <FileUpload
                  document={
                    this.state.required.endorsedPassportPictureOfAdministrator
                  }
                  fileName="Endorsed passport picture of administrator"
                  documentName="endorsedPassportPictureOfAdministrator"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
              </div>
            </div>
          </div>

          <hr width="50%" />

          {this.state.isSubmitting ? (
            <div className="mt-5rem">
              <FadeLoader />
            </div>
          ) : (
            <div className="mt-5rem">
              <button
                className="btn btn--gradient-primary btn--width-md mb-2rem mr-2rem"
                type="submit"
              >
                Make Claim
              </button>
              <button
                className="btn btn--width-md mb-2rem mr-2rem"
                onClick={() => this.props.history.push('/account')}
              >
                CANCEL
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}
LifeClaimForm.propTypes = {
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
  match: PropTypes.object,
  history: PropTypes.object,
  product: PropTypes.shape({
    company: PropTypes.shape({
      logoUrl: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
};

export default withRouter(
  connect(state => ({
    authUser: state.authUser,
  }))(LifeClaimForm),
);
