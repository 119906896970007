import React, { Component } from 'react';
import Select from 'react-select';
import { withRouter, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import countryList from 'react-select-country-list';
import axios from 'axios';
import PropTypes from 'prop-types';
import { getHeaders, travelUrl, errorMessage } from '../../config';
import { getCookie } from '../../utils/urls';
import { Modal } from 'antd';

import WithUser from '../../hocs/WithUser';
import { getPremiumFromAges } from '../../utils/products';
import { LoadingOutlined } from '@ant-design/icons';
import Payments from '../Payments';
import { initiatePayment } from '../Payments/utils';

import UserInfoForm from '../Auth/UserInfoForm';
import LoginPopUp from '../Auth/LoginPopUp';

class TravelBuyForm extends Component {
  constructor(props) {
    super(props);
    let user = Object.assign({}, this.props.authUser.user);
    let search = Object.assign({}, this.props.search);
    let clientNumber = search.travellerAges ? search.travellerAges.length : 1;
    let client = {
      firstName: '',
      lastName: '',
      email: '',
      occupation: '',
      phoneNumber: '',
      passportNumber: '',
      dateOfBirth: '',
      emergencyContacts: [
        {
          name: '',
          phoneNumber: '',
          relationship: '',
        },
      ],
    };

    let clients = [
      {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        occupation: user.occupation || '',
        phoneNumber: user.phoneNumber || '',
        passportNumber: user.passportNumber || '',
        dateOfBirth: user.dateOfBirth || '',
        emergencyContacts: [
          {
            name: '',
            phoneNumber: '',
            relationship: '',
          },
        ],
      },
    ];

    for (let i = 0; i < clientNumber - 1; i++) {
      clients.push(Object.assign({}, client));
    }
    this.state = {
      id: null,
      productId: parseInt(this.props.match.params.id, 10),
      premiums: this.props.premiums,
      startDate: search ? search.startDate : new Date(),
      endDate: search ? search.endDate : new Date(),
      clients: clients,
      countries: search ? search.countries : [],
      tripDescription: '',
      destinationAddress: '',
      referrer: '',
      affiliateReferrer: false,
      isFamily: search ? search.isFamily : false,
      isSubmitting: false,
      errorMessage: '',

      // for payments
      policy: '',
      policyType: 'travel',
      showModal: false,
      showAuthModal: false,
      showUserInfoModal: false,
    };
  }

  handleFieldUpdate = stateName => event => {
    this.setState({ [stateName]: event.target.value });
  };

  handleDateUpdate = stateName => date => {
    this.setState({ [stateName]: date });
  };

  handleCheckbox = () => {
    this.setState({
      isFamily: !this.state.isFamily,
    });
  };

  handleCountriesUpdate = selectedOption => {
    this.setState({ countries: selectedOption });
  };

  handleBirthdateUpdate = (stateName, id) => date => {
    let newDate = moment(date).format('YYYY-MM-DD');
    let today = moment();
    let age = today.diff(newDate, 'years');

    let premium = getPremiumFromAges(this.state.premiums, [age]);
    const newClients = this.state.clients.map((client, clientId) => {
      if (id !== clientId) return client;
      return { ...client, [stateName]: newDate };
    });

    let msg = '';
    if (!premium) {
      msg = 'Traveller ages are out of range for this policy';
    }
    this.setState({ clients: newClients, errorMessage: msg });
  };

  handleInputUpdate = (stateName, id) => event => {
    const newClients = this.state.clients.map((client, clientId) => {
      if (id !== clientId) return client;
      return { ...client, [stateName]: event.target.value };
    });

    this.setState({ clients: newClients });
  };

  handleEmergencyContactUpdate = (stateName, id) => event => {
    const newClients = this.state.clients.map((client, clientId) => {
      if (id !== clientId) return client;
      return {
        ...client,
        emergencyContacts: [
          ...client.emergencyContacts.map(e => ({
            ...e,
            [stateName]: event.target.value,
          })),
        ],
      };
    });

    this.setState({ clients: newClients });
  };

  handleRelationshipUpdate = (stateName, id) => selectedOption => {
    if (selectedOption) {
      const newClients = this.state.clients.map((client, clientId) => {
        if (id !== clientId) return client;
        return {
          ...client,
          emergencyContacts: [
            ...client.emergencyContacts.map(e => ({
              ...e,
              [stateName]: selectedOption.value,
            })),
          ],
        };
      });

      this.setState({ clients: newClients });
    } else {
      const newClients = this.state.clients.map((client, clientId) => {
        if (id !== clientId) return client;
        return {
          ...client,
          emergencyContacts: {
            ...client.emergencyContacts,
            [stateName]: '',
          },
        };
      });

      this.setState({ clients: newClients });
    }
  };

  handleAddClient = event => {
    event.preventDefault();
    this.setState({
      clients: this.state.clients.concat([
        {
          firstName: '',
          lastName: '',
          email: '',
          occupation: '',
          phoneNumber: '',
          passportNumber: '',
          dateOfBirth: '',
          emergencyContacts: [
            {
              name: '',
              phoneNumber: '',
              relationship: '',
            },
          ],
        },
      ]),
    });
  };

  handleRemoveClient = id => event => {
    event.preventDefault();
    this.setState({
      clients: this.state.clients.filter((client, clientId) => id !== clientId),
    });
  };

  handleSubmit = () => {
    let data = this.state;
    let clientsNo = data.clients.length;

    if (this.state.errorMessage) {
      window.scrollTo(0, 0);
      return;
    }

    if (data.isFamily && (clientsNo < 3 || clientsNo > 5)) {
      this.setState(
        { errorMessage: 'Family policies require 3-5 travellers' },
        () => {
          window.scrollTo(0, 0);
        },
      );
      return;
    }

    data.countries = data.countries.map(country => country.value.toLowerCase());

    delete data.policy;
    delete data.policyType;
    delete data.showModal;
    delete data.showAuthModal;
    delete data.showUserInfoModal;

    this.setState({ isSubmitting: true });
    axios(travelUrl, {
      method: 'POST',
      data: data,
      headers: getHeaders(),
    })
      .then(res => {
        initiatePayment('travel', res.data.results.id);
        
        this.setState({
          isSubmitting: false,
          showModal: true,
          policy: res.data.results,
          policyType: 'travel',
        });
      })
      .catch(err => {
        this.setState({ isSubmitting: false });
        if (err.response) {
          errorMessage('Oops! Something went wrong, kindly retry.');
        } else {
          errorMessage(
            'Error connecting, kindly check your internet connection.',
          );
        }
      });
  };

  getAges = () => {
    let agedClients = this.state.clients.filter(
      client => client.dateOfBirth !== null,
    );
    return agedClients.map(client => {
      let dob = moment(client.dateOfBirth);
      let today = moment();
      return today.diff(dob, 'years');
    });
  };

  componentDidMount() {
    // initialize referrer code if available.
    if (getCookie('referrer')) {
      this.setState({
        affiliateReferrer: true,
        referrer: getCookie('referrer'),
      });
    }
  }

  componentDidUpdate() {
    this.props.setTravellerAges(this.getAges());
  }

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleOk = () => {
    this.setState({ showModal: false });
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleFormChecks = (e) => {
    e.preventDefault();
    // eslint-disable-next-line react/prop-types
    if (!this.props.authUser.user.isComplete) {
      this.setState({ showUserInfoModal: true });
    } else {
      this.handleSubmit();
    }
  };

  render() {
    const relationshipOptions = [
      { label: 'Parent', value: 'P' },
      { label: 'Child', value: 'C' },
      { label: 'Sibling', value: 'S' },
      { label: 'Spouse', value: 'U' },
      { label: 'Other', value: 'O' },
    ];
    const countryOptions = countryList().getData();

    return (
      <form onSubmit={this.handleFormChecks} className="form">
        <h3 className="site-content__lead">Trip Information</h3>
        <p className="text--muted">Add a few details about your trip</p>
        {this.state.errorMessage ? (
          <p style={{ color: 'red' }}>{this.state.errorMessage}</p>
        ) : null}
        <div className="mt-2rem form__inputs">
          <div className="form__wrapper">
            <div>
              <label className="form__label mt-1rem mb-1rem">
                Destination(s)
              </label>
              <Select
                required
                value={this.state.countries}
                onChange={this.handleCountriesUpdate}
                options={countryOptions}
                isMulti
              />
            </div>
          </div>
        </div>

        <div className="mt-2rem form__inputs">
          <div className="form__wrapper">
            <div>
              <label className="form__label mt-1rem mb-1rem">
                Trip Description
              </label>
              <textarea
                className="form__input"
                placeholder="Study"
                value={this.state.description}
                onChange={this.handleFieldUpdate('tripDescription')}
                rows="5"
                required
              />
            </div>
          </div>
          <div className="form__wrapper">
            <div>
              <label className="form__label mt-1rem mb-1rem">
                Destination Address
              </label>
              <textarea
                className="form__input"
                placeholder="15XX S Salisbury Blvd
                Salisbury, MD 2XX01"
                value={this.state.destinationAddress}
                onChange={this.handleFieldUpdate('destinationAddress')}
                rows="5"
                required
              />
            </div>
          </div>
        </div>

        <div className="form__inputs">
          <div className="form__wrapper">
            <div>
              <label className="form__label mt-1rem mb-1rem">Start Date</label>
              <DatePicker
                selected={this.state.startDate}
                placeholderText="DD/MM/YYYY"
                minDate={new Date()}
                dateFormat="dd/MM/yyyy"
                className="form__input"
                onChange={this.handleDateUpdate('startDate')}
                required
              />
            </div>
          </div>
          <div className="form__wrapper">
            <div>
              <label className="form__label mt-1rem mb-1rem">End Date</label>
              <DatePicker
                selected={this.state.endDate}
                placeholderText="DD/MM/YYYY"
                minDate={this.state.startDate}
                dateFormat="dd/MM/yyyy"
                className="form__input"
                onChange={this.handleDateUpdate('endDate')}
                required
              />
            </div>
          </div>
        </div>

        <div className="form__inputs">
          <label className="mt-1rem">
            <input
              type="checkbox"
              checked={this.state.isFamily}
              onChange={this.handleCheckbox}
              className="mr-1rem"
            />
            Travelling as a family
          </label>
        </div>

        <h3 className="mt-5rem site-content__lead">Traveller Information</h3>
        <p className="text--muted">{"Add information on who's making the trip"}</p>

        {this.state.clients.map((client, id) => (
          <div key={id}>
            <div className="mt-3rem">
              {this.state.clients.length > 1 ? (
                <h4>Traveller {id + 1}</h4>
              ) : null}
            </div>
            <div className="form__inputs">
              <div className="form__wrapper">
                <div>
                  <label className="form__label mt-1rem mb-1rem">
                    First Name
                  </label>
                  <input
                    className="form__input"
                    placeholder="John"
                    value={client.firstName}
                    onChange={this.handleInputUpdate('firstName', id)}
                    required
                  />
                </div>
              </div>
              <div className="form__wrapper">
                <div>
                  <label className="form__label mt-1rem mb-1rem">
                    Last Name
                  </label>
                  <input
                    className="form__input"
                    placeholder="Doe"
                    value={client.lastName}
                    onChange={this.handleInputUpdate('lastName', id)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form__inputs">
              <div className="form__wrapper">
                <div>
                  <label className="form__label mt-1rem mb-1rem">
                    Occupation
                  </label>
                  <input
                    className="form__input"
                    placeholder="Student"
                    value={client.occupation}
                    onChange={this.handleInputUpdate('occupation', id)}
                    required
                  />
                </div>
              </div>
              <div className="form__wrapper">
                <div>
                  <label className="form__label mt-1rem mb-1rem">
                    Email Address
                  </label>
                  <input
                    className="form__input"
                    placeholder="john.doe@email.com"
                    value={client.email}
                    onChange={this.handleInputUpdate('email', id)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form__inputs">
              <div className="form__wrapper">
                <label className="form__label mt-1rem mb-1rem">
                  Date of Birth
                </label>
                <DatePicker
                  selected={
                    client.dateOfBirth ? client.dateOfBirth : null
                  }
                  placeholderText="DD/MM/YYYY"
                  dateFormat="dd/MM/yyyy"
                  className="form__input"
                  onChange={this.handleBirthdateUpdate('dateOfBirth', id)}
                  required
                />
              </div>
              <div className="form__wrapper">
                <div>
                  <label className="form__label mt-1rem mb-1rem">
                    Passport Number
                  </label>
                  <input
                    className="form__input"
                    placeholder=" G0613XX"
                    value={client.passportNumber}
                    onChange={this.handleInputUpdate('passportNumber', id)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form__inputs">
              <div className="form__wrapper">
                <div>
                  <label className="form__label mt-1rem mb-1rem">
                    Phone Number
                  </label>
                  <input
                    className="form__input"
                    placeholder="02XX123456"
                    value={client.phoneNumber}
                    onChange={this.handleInputUpdate('phoneNumber', id)}
                    required
                  />
                </div>
              </div>
            </div>

            <h4 className="mt-2rem site-content__lead">Emergency Contact</h4>
            <p className="text--muted">
              Add details for an alternate contact to reach out to
            </p>
            <div className="mt-2rem form__inputs">
              <div className="form__wrapper">
                <label className="form__label mt-1rem mb-1rem">
                  Relationship
                </label>
                <Select
                  options={relationshipOptions}
                  value={client.emergencyContacts.relationship}
                  onChange={this.handleRelationshipUpdate('relationship', id)}
                  required
                />
              </div>
              <div className="form__wrapper">
                <div>
                  <label className="form__label mt-1rem mb-1rem">
                    Full Name
                  </label>
                  <input
                    className="form__input"
                    placeholder="Jane Doe"
                    value={client.emergencyContacts.name}
                    onChange={this.handleEmergencyContactUpdate('name', id)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form__inputs">
              <div className="form__wrapper">
                <div>
                  <label className="form__label mt-1rem mb-1rem">
                    Phone Number
                  </label>
                  <input
                    className="form__input"
                    placeholder="02XX123456"
                    value={client.emergencyContacts.phoneNumber}
                    onChange={this.handleEmergencyContactUpdate(
                      'phoneNumber',
                      id,
                    )}
                    required
                  />
                </div>
              </div>
            </div>

            {this.state.clients.length > 1 ? (
              <button
                className="btn btn--danger mt-2rem"
                onClick={this.handleRemoveClient(id)}
              >
                Remove Traveller
              </button>
            ) : null}
          </div>
        ))}

        <button
          className="btn btn--proceed mt-5rem"
          onClick={this.handleAddClient}
        >
          Add Traveller
        </button>

        {!this.state.affiliateReferrer ? (
          <>
            <h3 className="mt-5rem site-content__lead">
              Referral Code (Optional)
            </h3>
            {/* <p className="text--muted">Add information on who's making the trip</p> */}

            <div className="form__inputs">
              <div className="form__wrapper">
                <div>
                  <label className="form__label mt-1rem mb-1rem">
                    {/* Referral Code */}
                  </label>
                  <input
                    className="form__input"
                    placeholder="code here"
                    value={this.state.referrer}
                    onChange={this.handleFieldUpdate('referrer')}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}

        {/* <PromoForm
          policy={this.state.id}
          policyType={this.state.policyType}
          handleSuccess={this.getPolicy}
        /> */}

        <div
          className="mt-5rem mb-2rem"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Link
            style={{ color: 'grey', fontSize: '1.5rem' }}
            to="/buy-travel-insurance/compare"
          >
            Back
          </Link>

          {!this.state.isSubmitting ? (
            <button
            className="btn_normal btn_gradient btn_md mr-5rem"
            type="submit"
          >Continue</button>
          ) : (
              <button className='btn btn--grey' disabled>
                <span>
                Processing <LoadingOutlined />
              </span>
              </button>
          )}
        </div>

        <small>
          By proceeding you agree to the{' '}
          <a href="/terms-of-service">terms and conditions</a> governing the
          purchase of this policy as they exist on the the date of purchase.
        </small>

        {/* initialize payment process */}
        <Modal
          title=""
          centered
          open={this.state.showModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Payments
            policy={this.state.policy}
            policyType={this.state.policyType}
          />
        </Modal>

        {/* take user details if we don't have their email and name. */}
        <Modal
          title="Where should we send the policy document to?"
          centered
          open={this.state.showUserInfoModal}
          onOk={() => this.setState({ showUserInfoModal: false })}
          onCancel={() => this.setState({ showUserInfoModal: false })}
          footer={null}
        >
            <UserInfoForm 
            handleSuccess={() => {
              this.setState({ showUserInfoModal: false });
              this.handleSubmit();
            }} firstName={this.state.clients[0].firstName} lastName={this.state.clients[0].lastName} email={this.state.clients[0].email} />
          
        </Modal>

        {/* login modal */}
        <Modal
          title=""
          centered
          open={this.state.showAuthModal}
          onOk={() => this.setState({ showAuthModal: true })}
          onCancel={() => this.setState({ showAuthModal: true })}
          footer={null}
        >
          <LoginPopUp handleSuccess={() => {
            this.setState({ showAuthModal: false });
          }}/>
        </Modal>
      </form>
    );
  }
}

TravelBuyForm.propTypes = {
  match: PropTypes.object,
  search: PropTypes.object,
  premiums: PropTypes.array,
  setTravellerAges: PropTypes.func,
  history: PropTypes.object,
  authUser: PropTypes.shape({
    token: PropTypes.string,
    user: PropTypes.shape({}),
  }),
};

export default withRouter(WithUser(TravelBuyForm));
