import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import WithUser from '../../hocs/WithUser';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import Footer from '../Footer';
import { Drawer } from 'antd';

// eslint-disable-next-line react/prop-types
const DashboardLayout = ({ children }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <div className="dashboard">
      <div className="dashboard_nav">
        <div className="dashboard_nav_side">
          <DashboardSidebar />
        </div>

        <div className="dashboard_nav_top">
          <DashboardNavbar handleOpen={() => setShowDrawer(true)} />
        </div>
      </div>

      <div className="dashboard_page">
        {children}
        <Footer className="mt-5rem" />
      </div>
      <Drawer
        title="Menu"
        placement={'left'}
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
        className="navbar_drawer"
      >
        <DashboardSidebar />
      </Drawer>
    </div>
  );
};

export default withRouter(WithUser(DashboardLayout));
