import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setPaymentRegion, setSubaccount } from '../Payments/actions';
import PaystackPayment from './Paystack';
import WithUser from '../../hocs/WithUser';
import WithProducts from '../../hocs/WithProducts';
import WithPayments from '../../hocs/WithPayments';

class Payments extends Component {
  state = {
    policy: null,
    region: null,
    method: '',
    isSubmitting: false,
    errorMessage: '',
    showModal: false,
  };

  isMotor = this.props.policyType === 'motor';

  isTravel = this.props.policyType === 'travel';

  isProperty = this.props.policyType === 'property';

  isHealth = this.props.policyType === 'health';

  isLife = this.props.policyType === 'life';

  componentDidMount() {
    let { policy } = this.props;

    let region = null;
    if (this.isTravel) {
      region = policy.product.company.region;
      setPaymentRegion(region);
      setSubaccount(policy.product.company.paymentId);
    } else {
      region = policy.company.region;
      setPaymentRegion(region);
      setSubaccount(policy.company.paymentId);
    }
    this.setState({ policy, region });
  }

  handleSuccess = () => {
    let currentModule = 'travel';

    if (this.isMotor) {
      currentModule = 'motor';
    }

    if (this.isProperty) {
      currentModule = 'property';
    }

    if (this.isHealth) {
      currentModule = 'health';
    }

    if (this.isLife) {
      currentModule = 'life';
    }

    // eslint-disable-next-line react/prop-types
    this.props.history.push(`/account/${currentModule}`);
  };

  render() {
    return (
      <div className="mb-3rem">
        <div style={{ textAlign: 'center' }}>
          <span style={{ fontSize: '2.5rem' }} className='text-green-950'>
              {/* eslint-disable-next-line react/prop-types */}
              Continue to pay {this.props.payment.currency}{' '}
              {/* eslint-disable-next-line react/prop-types */}
              {this.props.payment.amount}?
          </span>
        </div>

        <div
          className="mt-3rem mb-2rem"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <PaystackPayment handleConfirmSuccess={this.handleSuccess} />
        </div>
      </div>
    );
  }
}

Payments.propTypes = {
  policyType: PropTypes.string,
  policy: PropTypes.object,
};

export default withRouter(WithProducts(WithPayments(WithUser(Payments))));
