import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BasicTooltip from './BasicTooltip';
import { Checkbox } from 'antd';

const CheckboxInput = props => {
  const [showToolTip, setShowToolTip] = useState(false);
  const { toolTipLabel } = props;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    toolTipLabel && setShowToolTip(true);
  }, [toolTipLabel]);

  return (
    <React.Fragment>
      <div className="form-group">
        <Checkbox
          className="form_input_muted"
          id={props.name}
          checked={props.checked}
          onChange={props.handleChange}
          name={props.name}
        >
          {props.label}
        </Checkbox>
        {showToolTip ? <BasicTooltip label={props.toolTipLabel} /> : null}
      </div>
    </React.Fragment>
  );
};

CheckboxInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  inputType: PropTypes.string,
  checked: PropTypes.any,
  handleChange: PropTypes.func,
  toolTipLabel: PropTypes.string,
};

export default CheckboxInput;
