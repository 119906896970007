import Navbar from '../../components/Navbar';
import React, { useEffect, useState } from 'react';
import PolicyCard from '../../components/Cards/PolicyCard';
import PhoneNumberVerifyForm from '../../components/Auth/PhoneNumberVerifyForm';
import GetHelpCard from '../../components/Cards/GetHelpCard';
import WithProducts from '../../hocs/WithProducts';
import carIcon from '../../img/travel/journey-icon.svg';
import noticeIcon from '../../img/motor/notice.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import helpImg from '../../img/get_help_image.png';
import helpImgMobile from '../../img/get_help_image_mobile.png';
import { getHeaders, errorMessage } from '../../config';
import { Modal } from 'antd';
import CompareTravelPolicies from '../../components/Travel/CompareTravelPolicies';
import { formatProducts } from '../../utils/products';
import WithUser from '../../hocs/WithUser';
import LoginPopUp from '../../components/Auth/LoginPopUp';
import { trackEvents } from '../../utils/gaTrack';

const TravelCompare = props => {
  const [policies, setPolicies] = useState([]);
  const [policyExist, setPolicyExist] = useState(false);
  const [comparePolicies, setComparePolicies] = useState([]);
  // eslint-disable-next-line
  const [searchData, setSearchData] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [showPhoneNumberModal, setShowPhoneNumberModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  // const [userData, setUserData] = useState(null)

  // eslint-disable-next-line react/prop-types
  let { search, payload } = props;

  // use search query to retrieve products
  const getProductsWithPayload = () => {
    const url = `${process.env.REACT_APP_API_BASE}/api/v1/search/travel`;
    axios
      .post(url, payload, { headers: getHeaders() })
      .then(() => {
        // eslint-disable-next-line react/prop-types
        const newUrl = `${url}/${search.id}/results`;
        axios.get(newUrl, { headers: getHeaders() }).then(res => {
          let data = res.data;
          let allProducts = data.results.map(product => {
            product.isToggled = false;
            return product;
          });
          // eslint-disable-next-line react/prop-types
          props.setProducts(allProducts);
          setPolicies(formatProducts(allProducts, search));
          setPolicyExist(true);
        });
      })
      .catch(() => {
        errorMessage('Error getting products, kindly retry.');
      });
  };

  const handleBuy = policyId => {
    // eslint-disable-next-line react/prop-types
    if (props.authUser.token) {
      // eslint-disable-next-line react/prop-types
      props.history.push(`/buy-travel-insurance/${policyId}/buy`);
      trackEvents(
        'Travel', 
        'Chose a policy',  
        // eslint-disable-next-line react/prop-types
        props.authUser.user.phoneNumber ? props.authUser.user.phoneNumber : 'Anonymous User',
      );
    } else {
      setShowPhoneNumberModal(true);
    }
  };

  const handleAdd = policy => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !comparePolicies.filter(p => p.id === policy.id).length &&
      setComparePolicies([...comparePolicies, policy]);
  };

  const handleRemove = policy => {
    if (comparePolicies.filter(p => p.id === policy.id).length) {
      setComparePolicies(comparePolicies.filter(p => p.id !== policy.id));
    }
  };

  const handleShowModal = () => {
    // eslint-disable-next-line react/prop-types
    props.setCompareProducts(
      comparePolicies.map(p => {
        return {
          ...p,
          premium: p.travelPremiums[0].premium,
          travellerAges: p.travellerAges ? p.travellerAges : [],
          breakdowns: p.travelInfo.travelCoverBreakdownSet,
        };
      }),
    );
    setShowModal(true);
  };

  const handleCloseModal = () => {
    // eslint-disable-next-line react/prop-types
    props.clearCompareProducts();
    setShowModal(false);
  };

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (props.products.length) {
      // eslint-disable-next-line react/prop-types
      setPolicies(formatProducts(props.products, props.search));
      setPolicyExist(true);
      setSearchData(search);
    } else {
      getProductsWithPayload();
    }

    // eslint-disable-next-line react/prop-types
    if (!props.authUser.token) {
      setShowPhoneNumberModal(true);
    }
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAuth = () => {
    setShowAuthModal(true);
  };

  return (
    <React.Fragment>
      <Navbar />
      <div className="travel_compare">
        <div className="travel_compare_header">
          {policies.length ? (
            <h2>
              {"We've found"}{' '}
              <span style={{ color: '#009B9C' }}>
                {policies.length} Travel Insurance
              </span>{' '}
              {/* eslint-disable-next-line react/prop-types */}
              Policies for {!search.id ? 'you' : null}
            </h2>
          ) : (
            <p>
              Please provide information about your journey{' '}
              <Link to="/buy-travel-insurance">here</Link>
            </p>
          )}
        </div>

        {/* Card showing details of the search and list of policies */}
        {policies.length ? (
          <>
          {/* eslint-disable-next-line react/prop-types */}
            {search.id ? (
              <div className="travel_compare_card">
                <img src={carIcon} alt="compare card" />
                <div className="travel_compare_card_caption">
                  <h3>
                    {/* eslint-disable-next-line react/prop-types */}
                    {payload.isFamily ? 'Family' : 'Individual'}{' '}
                    {/* eslint-disable-next-line react/prop-types */}
                    {payload.isSingleTrip ? 'Single Trip' : 'Multiple Trips'}
                  </h3>
                  <p>
                    <span>
                      {/* eslint-disable-next-line react/prop-types */}
                      From: {new Date(payload.startDate).toDateString()} -{' '}
                      {/* eslint-disable-next-line react/prop-types */}
                      {new Date(payload.endDate).toDateString()}
                    </span>
                  </p>
                  <p
                    style={{
                      display: 'inline-flex',
                      backgroundColor: '#3f3d56',
                      padding: '0.5rem 1rem',
                      float: 'right',
                      marginTop: '2rem',
                      borderRadius: '5px',
                      fontWeight: '700',
                      cursor: 'pointer',
                    }}
                  >
                    <Link to="/buy-travel-insurance">
                      <span style={{ color: '#f2f2f2' }}>
                        Edit Trip Details
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
            ) : null}
            {/* eslint-disable-next-line react/prop-types */}
            <div className={`travel_compare_policies ${search.id && 'mt-13rem'}`}>
              {comparePolicies.length ? (
                <div className="travel_compare_policies_cta">
                  <div className="travel_compare_policies_warning mb-1rem">
                    {comparePolicies.length === 1 ? (
                      <>
                        <img src={noticeIcon} alt="notice" />
                        <p>Choose 2 or more to compare</p>
                      </>
                    ) : null}

                    {!comparePolicies.length ? (
                      <>
                        <img src={noticeIcon} alt="notice" />
                        <p>Click on the compare button to start comparing</p>
                      </>
                    ) : null}
                  </div>

                  <div className="travel_compare_policies_cta_main">
                    <div className="">
                      <span>{comparePolicies.length}</span> Insurance{' '}
                      {comparePolicies.length > 1 ? 'Policies' : 'Policy'}{' '}
                      Selected
                    </div>

                    {comparePolicies.length >= 2 ? (
                      <div
                        className=""
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: '30%',
                        }}
                      >
                        <button
                          type="button"
                          className="btn_normal btn_green btn_md"
                          style={{ right: '2rem' }}
                          onClick={handleShowModal}
                        >
                          Compare
                        </button>

                        <button
                          type="button"
                          className="btn_normal btn_dark btn_md"
                          style={{ right: '2rem' }}
                          onClick={() => setComparePolicies([])}
                        >
                          Clear
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {comparePolicies.length ? (
                <div className="travel_compare_policies_cta_mobile">
                  <div className="travel_compare_policies_warning_mobile mb-1rem">
                    {comparePolicies.length === 1 ? (
                      <>
                        <img src={noticeIcon} alt="notice" />
                        <p>Choose 2 or more to compare</p>
                      </>
                    ) : null}

                    {!comparePolicies.length ? (
                      <>
                        <img src={noticeIcon} alt="notice" />
                        <p>Click on the compare button to start comparing</p>
                      </>
                    ) : null}
                  </div>

                  <div className="">
                    <span>{comparePolicies.length}</span> Insurance{' '}
                    {comparePolicies.length > 1 ? 'Policies' : 'Policy'}{' '}
                    Selected
                  </div>

                  {comparePolicies.length >= 2 ? (
                    <div
                      className=""
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <button
                        type="button"
                        className="btn_normal btn_green btn_md"
                        style={{ right: '2rem' }}
                        onClick={handleShowModal}
                      >
                        Compare
                      </button>

                      <button
                        type="button"
                        className="btn_normal btn_dark btn_md"
                        style={{ right: '2rem' }}
                        onClick={() => setComparePolicies([])}
                      >
                        Clear
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {/* <div className="travel_compare_policies_warning">
                {comparePolicies.length === 1 ? (
                  <>
                    <img src={noticeIcon} alt="notice" />
                    <p>Choose 2 or more to compare</p>
                  </>
                ) : null}

                {!comparePolicies.length ? (
                  <>
                    <img src={noticeIcon} alt="notice" />
                    <p>Click on the compare button to start comparing</p>
                  </>
                ) : null}
              </div> */}

              <div className="travel_compare_policies_list">
                {policies.map((policy, i) =>
                  policy.isPolicy ? (
                    <div key={i} className="travel_compare_policies_list_card mb-3rem">
                      <PolicyCard
                        policy={policy}
                        handleBuy={handleBuy.bind(this, policy.id)}
                        handleCompare={handleAdd.bind(this, policy)}
                        handleRemove={handleRemove.bind(this, policy)}
                        showRemoveBtn={
                          comparePolicies.filter(p => p.id === policy.id).length ? true : false
                        }
                      />
                    </div>
                  ) : (
                    <div key={i} className="travel_compare_policies_list_help mb-3rem">
                      <GetHelpCard image={helpImg} />
                    </div>
                  ),
                )}

                {policies.map((policy, i) =>
                  policy.isPolicy ? (
                    <div key={i} className="travel_compare_policies_list_card_mobile">
                      <PolicyCard
                        policy={policy}
                        handleBuy={handleBuy.bind(this, policy.id)}
                        handleCompare={handleAdd.bind(this, policy)}
                        handleRemove={handleRemove.bind(this, policy)}
                        showRemoveBtn={
                          comparePolicies.filter(p => p.id === policy.id).length ? true : false
                        }
                      />
                    </div>
                  ) : (
                    <div key={i} className="travel_compare_policies_list_help_mobile">
                      <GetHelpCard image={helpImgMobile} />
                    </div>
                  ),
                )}
              </div>
            </div>
          </>
        ) : null}

        <Modal
          title=""
          centered
          width={'90%'}
          open={showModal}
          onOk={handleCloseModal}
          onCancel={handleCloseModal}
          footer={null}
        >
          <CompareTravelPolicies />
        </Modal>
      </div>

      {policyExist ? (
            <Modal
              title="Discounts, Exclusive offers and Important updates."
              centered
              closable={false}
              maskClosable={false}
              wrapClassName="blurModalBg"
              open={showPhoneNumberModal}
              onOk={() => setShowPhoneNumberModal(false)}
              onCancel={() => setShowPhoneNumberModal(false)}
              footer={null}
            >
              <div className='text-center'>
              <PhoneNumberVerifyForm handleSuccess={() => setShowPhoneNumberModal(false)} handleAuth={() => {
                setShowAuthModal(true);
                setShowPhoneNumberModal(false);
              }} />
              </div>
            </Modal>
      ) : null}

        <Modal
          title=""
          centered
          // width={'1'}
          open={showAuthModal}
          onOk={() => setShowPhoneNumberModal(true)}
          onCancel={() => setShowPhoneNumberModal(true)}
          footer={null}
        >
          <LoginPopUp handleSuccess={() => {
            setShowPhoneNumberModal(false);
            setShowAuthModal(false);
          }} />
        </Modal>
    </React.Fragment>
  );
};

export default WithProducts(WithUser(TravelCompare));
