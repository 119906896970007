import { createStore, compose, combineReducers, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';

import {
  products,
  motorProducts,
  healthProducts,
  lifeProducts,
  lifeCompanyProducts,
  propertyProducts,
  compareProducts,
  showMessenger,
  payload,
  motorPayload,
  propertyPayload,
  lifePayload,
  lifeCompanyPayload,
  healthPayload,
} from '../components/Product/reducers';
import { authUser } from '../pages/user-auth/reducers';
import { payment, exchangeRate } from '../components/Payments/reducers';
import { search } from '../components/Search/reducers';
import { region } from '../components/Region/reducers';

const reducers = {
  products,
  motorProducts,
  healthProducts,
  lifeProducts,
  lifeCompanyProducts,
  propertyProducts,
  compareProducts,
  payload,
  motorPayload,
  propertyPayload,
  lifePayload,
  lifeCompanyPayload,
  healthPayload,
  showMessenger,
  authUser,
  payment,
  exchangeRate,
  search,
  region,
};

const reducer = combineReducers(reducers);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));

export default store;
