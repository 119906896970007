import React, { Component } from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { signupUrl, OTPUrl, getHeaders } from '../../config';
import PropTypes from 'prop-types';
import { getCookie, setCookie } from '../../utils/urls';
import WithUser from '../../hocs/WithUser';
import { Input, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import SignupOTPForm from '../Auth/SignupOTPForm';

class SignupForm extends Component {
  state = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    country: 'gh',
    email: '',
    password1: '',
    password2: '',
    referredBy: '',
    isSubmitting: false,
    errorMessage: {},
    useComponentIn: this.props.useComponentIn, // page or modal
    redirectToReferrer: false,
    showOTPModal: false,
    userData: {},
  };

  componentDidMount() {
    this.setState({
      referredBy: getCookie('referrer') ? getCookie('referrer') : '',
    });
  }

  handleInputUpdate = (stateName, event) => {
    this.setState({ [stateName]: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    let {
      email,
      firstName,
      lastName,
      password1,
      password2,
      phoneNumber,
      country,
      referredBy,
    } = this.state;
    let data = {
      email,
      firstName,
      lastName,
      phoneNumber,
      country,
      referredBy,
      password1,
      password2,
    };

    axios
      .post(signupUrl, data)
      .then(res => {
        let userData = res.data;
        userData.phoneNumber = phoneNumber;

        axios
          .post(OTPUrl, { phoneNumber }, { headers: getHeaders() })
          .then(() => {
            // this.props.history.push('/auth/signup-verification')
            this.setState({ showOTPModal: true, userData });

            // remove referrer code from cookies
            let expiryDate = new Date('1970-01-01');
            expiryDate.setDate(expiryDate.getDate() - 1000);
            setCookie('referrer', referredBy, expiryDate);
          })
          .catch(() => {});
      })
      .catch(error => {
        if (error.response && error.response.status < 500) {
          this.setState({ errorMessage: error.response.data });
        } else
          this.setState({
            errorMessage: {
              nonFieldErrors: ['Oops! Something happened. Please try again.'],
            },
          });

        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const redirectPath = from.pathname;
    const { redirectToReferrer, useComponentIn } = this.state;

    if (redirectToReferrer === true && useComponentIn === 'page') {
      return <Redirect to={redirectPath} />;
    } else if (redirectToReferrer === false && useComponentIn === 'modal') {
      this.props.setSuccess();
    }

    return (
    <div>
      <form className="" onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className=" mb-2rem">
              <label htmlFor="firstName" style={{ fontWeight: '700' }}>
                First Name
              </label>
              <Input
                type="text"
                className=""
                id="firstName"
                placeholder="Kofi"
                onChange={this.handleInputUpdate.bind(this, 'firstName')}
              />
              {this.state.errorMessage.firstName
                ? this.state.errorMessage.firstName.map(err => (
                    // eslint-disable-next-line react/jsx-key
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2rem">
              <label htmlFor="lastName" style={{ fontWeight: '700' }}>
                Last Name
              </label>
              <Input
                className=""
                placeholder="Amankwa"
                onChange={this.handleInputUpdate.bind(this, 'lastName')}
              />
              {this.state.errorMessage.lastName
                ? this.state.errorMessage.lastName.map(err => (
                    // eslint-disable-next-line react/jsx-key
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group mb-2rem">
              <label htmlFor="email" style={{ fontWeight: '700' }}>
                Email Address
              </label>
              <Input
                className=""
                id="email"
                type="email"
                placeholder="kofi@gmail.com"
                onChange={this.handleInputUpdate.bind(this, 'email')}
              />
              {this.state.errorMessage.email
                ? this.state.errorMessage.email.map(err => (
                    // eslint-disable-next-line react/jsx-key
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-2rem">
              <label htmlFor="phone" style={{ fontWeight: '700' }}>
                Phone Number
              </label>
              <Input
                className=""
                id="phone"
                placeholder="0244xxxxxx"
                type="text"
                onChange={this.handleInputUpdate.bind(this, 'phoneNumber')}
              />
              {this.state.errorMessage.phoneNumber
                ? this.state.errorMessage.phoneNumber.map(err => (
                    // eslint-disable-next-line react/jsx-key
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-2rem">
              <label htmlFor="password1" style={{ fontWeight: '700' }}>
                Password
              </label>
              <Input.Password
                className=""
                id="password1"
                name="password1"
                placeholder="Enter password"
                type="password"
                onChange={this.handleInputUpdate.bind(this, 'password1')}
              />
              {this.state.errorMessage.password1
                ? this.state.errorMessage.password1.map(err => (
                    // eslint-disable-next-line react/jsx-key
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-1rem">
              <label htmlFor="password2" style={{ fontWeight: '700' }}>
                Confirm Password
              </label>
              <Input.Password
                className=""
                placeholder="confirm password"
                id="password2"
                type="password"
                onChange={this.handleInputUpdate.bind(this, 'password2')}
              />
              {this.state.errorMessage.password2
                ? this.state.errorMessage.password2.map(err => (
                    // eslint-disable-next-line react/jsx-key
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
            </div>
          </div>
        </div>

        <div>
          {this.state.errorMessage.nonFieldErrors
            ? this.state.errorMessage.nonFieldErrors.map(err => (
                // eslint-disable-next-line react/jsx-key
                <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
            ))
            : null}
        </div>

        <small className="text--muted">
          By clicking Sign Up, you agree to BestQuote’s
          <Link to="/terms-of-service">
            {' '}
            <span className="navbar__link--active">Terms of Service</span>
          </Link>{' '}
          &amp;
          <Link to="/privacy-policy">
            {' '}
            <span className="navbar__link--active">Privacy Policy.</span>
          </Link>
        </small>

        <div className="form__submit">
          <button
            className="btn btn--gradient-primary btn--width-lng mt-2rem mb-2rem"
            type="submit"
          >
            {this.state.isSubmitting ? <LoadingOutlined /> : 'Register'}
          </button>
        </div>
      </form>

      <Modal
          title="Verify Your Account!"
          centered
          open={this.state.showOTPModal}
          onOk={() => this.setState({ showOTPModal: false })}
          onCancel={() => this.setState({ showOTPModal: false })}
          footer={null}
        >
          <SignupOTPForm setSuccess={() =>  {
            // eslint-disable-next-line react/prop-types
            this.props.setUser(this.state.userData);
            this.setState({ showOTPModal: false, redirectToReferrer: true });
          }} />
        </Modal>
      </div>
    );
  }
}

SignupForm.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  setSuccess: PropTypes.func,
  useComponentIn: PropTypes.string,
};

SignupForm.defaultProps = {
  useComponentIn: 'page',
  setSuccess: () => {},
};

export default withRouter(WithUser(SignupForm));
