import React from 'react';
import PropTypes from 'prop-types';
import pigCoin from '../../img/motor/policy-pig.svg';
import check from '../../img/motor/policy-check.svg';

const LifeBannerPage = props => {
  return (
    <div className="motor_banner">
      <img src={props.bannerImage} alt="banner" />
      <p className="motor_banner_title">
        Get your{' '}
        <span className="motor_banner_title_">
          {props.moduleName} Insurance
        </span>{' '}
        starting at{' '}
        <span className="motor_banner_title_price">{props.price}.</span>{' '}
        {/* <img src={arrowRight} alt="arrow" width="30" /> */}
      </p>

      <div className="motor_banner_tag">
        <div className="motor_banner_tag1">
          <img src={pigCoin} alt="pig-coin" className="mr-1rem" />
          <div className="">
            <p>Save up to 60%</p>
            <span>Lowest premiums</span>
          </div>
        </div>

        <div className="motor_banner_tag2">
          <img src={check} alt="check" className="mr-1rem" />
          <div className="">
            <p>Plus Medical Coverage</p>
            <span>To choose from</span>
          </div>
        </div>
      </div>
    </div>
  );
};

LifeBannerPage.propTypes = {
  match: PropTypes.object,
  bannerImage: PropTypes.any,
  moduleName: PropTypes.string,
  price: PropTypes.string,
};

export default LifeBannerPage;
