import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { getHeaders, lifeUrl } from '../../config';
import { getCookie } from '../../utils/urls';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Modal } from 'antd';
import DatePickerInput from '../../components/Forms/DatePickerInput';
import BasicInput from '../../components/Forms/BasicInput';
import SelectInput from '../../components/Forms/SelectInput';
import PropTypes from 'prop-types';
import nationalsList from 'npm-nationality-list';

import { initiatePayment } from '../../components/Payments/utils';
import Payments from '../../components/Payments';
import PromoForm from '../../components/Promo/PromoForm';

let nationals = nationalsList.getList();

const nationalIdTypeList = [
  {
    label: 'Ghana Card',
    value: 'ghana card',
  },
  {
    label: 'Passport',
    value: 'passport',
  },
  {
    label: 'Drivers License',
    value: 'drivers license',
  },
  {
    label: 'Voters ID',
    value: 'voters id',
  },
];

const relationshipTypes = [
  {
    label: 'Child',
    value: 'C',
  },
  {
    label: 'Sibling',
    value: 'S',
  },
  {
    label: 'Parent',
    value: 'P',
  },
  {
    label: 'Spouse',
    value: 'U',
  },
  {
    label: 'Other',
    value: 'O',
  },
];

const LifeBuyForm = props => {
  const [policy, setPolicy] = useState({
    id: props.policy.id,
    firstName: '',
    lastName: '',
    otherNames: '',
    address: '',
    dateOfBirth: new Date(),
    occupation: '',
    email: '',
    phoneNumber: '',
    nationality: '',
    employer: '',
    nationalId: '',
    nationalIdType: '',
    startDate: new Date(),

    trusteelastName: '',
    trusteeotherNames: '',
    trusteedateOfBirth: new Date(),
    trusteerelationship: '',

    beneficiarylastName: '',
    beneficiaryotherNames: '',
    beneficiarydateOfBirth: new Date(),
    beneficiaryrelationship: '',

    userInGoodHealth: false,

    // for payments
    policy: null,
    policyType: 'life',
    showModal: false,
  });
  const [nationalities, setNationalities] = useState([]);

  let trustee = {
    lastName: policy.trusteelastName,
    otherNames: policy.trusteeotherNames,
    dateOfBirth: policy.trusteedateOfBirth
      ? moment(policy.trusteedateOfBirth).format('YYYY-MM-DD')
      : '',
    relationship: policy.trusteerelationship,
    isTrustee: true,
  };

  let beneficiary = {
    lastName: policy.beneficiarylastName,
    otherNames: policy.beneficiaryotherNames,
    dateOfBirth: policy.beneficiarydateOfBirth
      ? moment(policy.beneficiarydateOfBirth).format('YYYY-MM-DD')
      : '',
    relationship: policy.beneficiaryrelationship,
    isBeneficiary: true,
  };

  const [formSection, setFormSection] = useState(1);
  // eslint-disable-next-line
  const [referrer, setReferrer] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  // eslint-disable-next-line
  const [affiliateReferrer, setAffiliateReferrer] = useState(false)

  const removeErrorMessage = stateName => {
    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        nonFieldErrors: null,
      });
    } else if (errorMessage.vehicles) {
      setErrorMessage({
        ...errorMessage,
        vehicles: errorMessage.vehicles.map(v => {
          return { ...v, [stateName]: null };
        }),
        nonFieldErrors: null,
      });
    }
  };

  const handleFieldUpdate = stateName => event => {
    setPolicy({ ...policy, [stateName]: event.target.value });

    // remove form field errors
    removeErrorMessage(stateName);
  };

  const handleDateUpdate = stateName => date => {
    setPolicy({ ...policy, [stateName]: date });

    // remove form field errors
    removeErrorMessage(stateName);
  };

  const handleSelectUpdate = stateName => value => {
    setPolicy({ ...policy, [stateName]: value });

    // remove form field errors
    removeErrorMessage(stateName);
  };

  const handleOk = () => {
    setPolicy({ ...policy, showModal: false });
  };

  const handleCancel = () => {
    setPolicy({ ...policy, showModal: false });
  };

  const handleSubmit = async () => {
    let data = {
      ...policy,
      startDate: policy.startDate
        ? moment(policy.startDate).format('YYYY-MM-DD')
        : '',
      dateOfBirth: policy.dateOfBirth
        ? moment(policy.dateOfBirth).format('YYYY-MM-DD')
        : '',
      info: {
        userInGoodHealth: policy.userInGoodHealth,
        trustees: [trustee],
        beneficiaries: [beneficiary],
      },
    };

    delete data.beneficiarydateOfBirth;
    delete data.beneficiarylastName;
    delete data.beneficiaryotherNames;
    delete data.beneficiaryrelationship;

    delete data.trusteedateOfBirth;
    delete data.trusteelastName;
    delete data.trusteeotherNames;
    delete data.trusteerelationship;
    delete data.userInGoodHealth;

    delete data.policy;
    delete data.policyType;
    delete data.showModal;

    setIsSubmitting(true);

    axios
      .put(`${lifeUrl}/${policy.id}`, data, {
        headers: getHeaders(),
      })
      .then(res => {
        // props.history.push({
        //   pathname: `/payments/new/${res.data.data.id}`,
        //   state: { id: res.data.data.id, type: 'life' }
        // })

        initiatePayment('life', res.data.data.id);
        setPolicy({
          ...policy,
          showModal: true,
          policy: res.data.data,
          policyType: 'life',
        });
        setIsSubmitting(false);
      })
      .catch(err => {
        setIsSubmitting(false);
        if (err.response) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          err.response.status === 400
            ? setErrorMessage({
              ...err.response.data,
              nonFieldErrors: ["Kindly make sure you've completed the form"],
            })
            : setErrorMessage({
              ...errorMessage,
              nonFieldErrors: ['Oops, server Error! Retry'],
            });
        } else {
          setErrorMessage({
            ...errorMessage,
            nonFieldErrors: ['Error Connecting to Server, Retry.'],
          });
        }
      });
  };

  const handleNextSection = () => {
    setFormSection(formSection + 1);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let nationals_ = nationals.map(nat => {
      return { value: nat.nationality, label: nat.nationality };
    });
    setNationalities(nationals_);

    // initialize referrer code if available.
    if (getCookie('referrer')) {
      setAffiliateReferrer(true);
      setReferrer(getCookie('referrer'));
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Form
      onFinish={formSection === 5 ? handleSubmit : handleNextSection}
      layout="vertical"
      className="motor_form"
    >
      {formSection === 1 ? (
        <div className="row">
          <h3 className="mb-2rem">
            Finish up by giving more information about yourself
          </h3>
          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly enter your first name"
              value={policy.firstName}
              name={'first name'}
              placeholder="enter first name"
              handleChange={handleFieldUpdate('firstName')}
              required={true}
            />

            {errorMessage.firstName
              ? errorMessage.firstName.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly enter your last name"
              value={policy.lastName}
              name={'last name'}
              placeholder="enter last name"
              handleChange={handleFieldUpdate('lastName')}
              required={true}
            />

            {errorMessage.lastName
              ? errorMessage.lastName.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly enter your other names"
              value={policy.otherNames}
              name={'other name'}
              placeholder="enter other name"
              handleChange={handleFieldUpdate('otherNames')}
              required={false}
            />

            {errorMessage.otherNames
              ? errorMessage.otherNames.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <DatePickerInput
              label="Kindly indicate when you were born"
              value={moment(policy.dateOfBirth).format('YYYY-MM-DD')}
              handleChange={handleDateUpdate('dateOfBirth')}
              name="date of birth"
              required={true}
            />{' '}
            {errorMessage.dateOfBirth
              ? errorMessage.dateOfBirth.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>
        </div>
      ) : null}

      {formSection === 2 ? (
        <div className="row">
          <h3 className="mb-2rem">Give us more details about you</h3>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide your residential or GPS address"
              value={policy.address}
              name={'address'}
              placeholder="enter address"
              handleChange={handleFieldUpdate('address')}
              required={true}
            />

            {errorMessage.address
              ? errorMessage.address.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide your phone number"
              value={policy.phoneNumber}
              name={'phone number'}
              placeholder="enter phone number"
              handleChange={handleFieldUpdate('phoneNumber')}
              required={true}
            />

            {errorMessage.phoneNumber
              ? errorMessage.phoneNumber.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide your email address"
              inputType="email"
              value={policy.email}
              name={'email address'}
              placeholder="enter email address"
              handleChange={handleFieldUpdate('email')}
              required={true}
            />

            {errorMessage.email
              ? errorMessage.email.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <DatePickerInput
              label="Kindly chose when you want this policy to start"
              value={moment(policy.startDate).format('YYYY-MM-DD')}
              handleChange={handleDateUpdate('startDate')}
              name="starting date"
              required={true}
            />{' '}
            {errorMessage.startDate
              ? errorMessage.startDate.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>
        </div>
      ) : null}

      {formSection === 3 ? (
        <div className="row">
          <h3 className="mb-2rem">Add your final details</h3>
          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide your occupation or line of work"
              value={policy.occupation}
              name={'occupation'}
              placeholder="enter occupation"
              handleChange={handleFieldUpdate('occupation')}
              required={true}
            />

            {errorMessage.occupation
              ? errorMessage.occupation.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide your employer name (Company)"
              value={policy.employer}
              name={'employer'}
              placeholder="enter employer"
              handleChange={handleFieldUpdate('employer')}
              required={true}
            />

            {errorMessage.employer
              ? errorMessage.employer.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <SelectInput
              label={'Kindly select your nationality'}
              value={policy.nationality}
              name={'nationality'}
              optionList={nationalities}
              mode={'multiple'}
              onChange={handleSelectUpdate('nationality')}
              placeholder="select your nationality"
              required={true}
            />

            {errorMessage.nationality
              ? errorMessage.nationality.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <SelectInput
              label={'Kindly select your ID type'}
              value={policy.nationalIdType}
              name={'nationalIdType'}
              optionList={nationalIdTypeList}
              onChange={handleSelectUpdate('nationalIdType')}
              placeholder="select your ID Type"
              required={true}
            />

            {errorMessage.nationalIdType
              ? errorMessage.nationalIdType.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Provide the number on the selected ID above"
              inputType="text"
              value={policy.nationalId}
              name={'nationalId'}
              placeholder="enter national ID"
              handleChange={handleFieldUpdate('nationalId')}
              required={true}
            />

            {errorMessage.nationalId
              ? errorMessage.nationalId.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>
        </div>
      ) : null}

      {formSection === 4 ? (
        <div className="row">
          <h3 className="mb-2rem">Add some beneficiaries</h3>
          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide beneficiary  surname"
              value={policy.beneficiarylastName}
              name={'beneficiary surname'}
              placeholder="enter surname"
              handleChange={handleFieldUpdate('beneficiarylastName')}
              required={true}
            />

            {errorMessage.beneficiarylastName
              ? errorMessage.beneficiarylastName.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide beneficiary other name(s)"
              value={policy.beneficiaryotherNames}
              name={'beneficiary other name'}
              placeholder="enter other name"
              handleChange={handleFieldUpdate('beneficiaryotherNames')}
              required={true}
            />

            {errorMessage.beneficiaryotherNames
              ? errorMessage.beneficiaryotherNames.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <SelectInput
              label={'Kindly select your relationship with beneficiary'}
              value={policy.beneficiaryrelationship}
              name={'beneficiary relationship'}
              optionList={relationshipTypes}
              onChange={handleSelectUpdate('beneficiaryrelationship')}
              placeholder="select relationship"
              required={true}
            />

            {errorMessage.beneficiaryrelationship
              ? errorMessage.beneficiaryrelationship.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <DatePickerInput
              label="Kindly provide beneficiary date of birth"
              value={moment(policy.beneficiarydateOfBirth).format('YYYY-MM-DD')}
              handleChange={handleDateUpdate('beneficiarydateOfBirth')}
              name="beneficiary date of birth"
              required={true}
            />{' '}
            {errorMessage.beneficiarydateOfBirth
              ? errorMessage.beneficiarydateOfBirth.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>
        </div>
      ) : null}

      {formSection === 5 ? (
        <div className="row">
          <h3 className="mb-2rem">Finally add some trustees</h3>
          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide trustee  surname"
              value={policy.trusteelastName}
              name={' surname'}
              placeholder="enter surname"
              handleChange={handleFieldUpdate('trusteelastName')}
              required={true}
            />

            {errorMessage.trusteelastName
              ? errorMessage.trusteelastName.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide trustee other name(s)"
              value={policy.trusteeotherNames}
              name={'trustee other name'}
              placeholder="enter other name"
              handleChange={handleFieldUpdate('trusteeotherNames')}
              required={true}
            />

            {errorMessage.trusteeotherNames
              ? errorMessage.trusteeotherNames.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <SelectInput
              label={'Kindly select your relationship with trustee'}
              value={policy.trusteerelationship}
              name={'trustee relationship'}
              optionList={relationshipTypes}
              onChange={handleSelectUpdate('trusteerelationship')}
              placeholder="select relationship"
              required={true}
            />

            {errorMessage.trusteerelationship
              ? errorMessage.trusteerelationship.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <DatePickerInput
              label="Kindly provide trustee date of birth"
              value={moment(policy.trusteedateOfBirth).format('YYYY-MM-DD')}
              handleChange={handleDateUpdate('trusteedateOfBirth')}
              name="trustee date of birth"
              required={true}
            />{' '}
            {errorMessage.trusteedateOfBirth
              ? errorMessage.trusteedateOfBirth.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            {errorMessage.nonFieldErrors
              ? errorMessage.nonFieldErrors.map((err, i) => (
                  <div style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </div>
              ))
              : null}
          </div>

          <PromoForm
            policy={policy.id}
            policyType={policy.policyType}
            handleSuccess={props.getPolicy}
          />
        </div>
      ) : null}

      <div className="motor_form_buttons">
        <button
          className="btn_normal btn_dark btn_md"
          type="button"
          onClick={() =>
            setFormSection(formSection > 1 ? formSection - 1 : formSection)
          }
          style={formSection !== 1 ? {} : { visibility: 'hidden' }}
        >
          Back
        </button>

        <button className="btn_normal btn_gradient btn_md" type="submit">
          {isSubmitting ? (
            <LoadingOutlined />
          ) : formSection === 5 ? (
            'Submit'
          ) : (
            'Continue'
          )}
        </button>
      </div>

      {/* initialize payment process */}
      <Modal
        title="Proceed to Payment"
        centered
        visible={policy.showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Payments policy={policy.policy} policyType={policy.policyType} />
      </Modal>
    </Form>
  );
};

LifeBuyForm.propTypes = {
  policy: PropTypes.object,
  getPolicy: PropTypes.func,
};

export default withRouter(LifeBuyForm);
