import { constants } from '../../app-constants';
let data = {
  id: 0,
  travellerAges: [],
  isSingleTrip: null,
  isFamily: null,
  countries: [],
  startDate: null,
  endDate: null,
};

const initialState = JSON.parse(localStorage.getItem('bestquoteSearch')) || data;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const search = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_SEARCH_ITEM:
      localStorage.setItem('bestquoteSearch', JSON.stringify(action.payload));
      return action.payload;

    case constants.CLEAR_SEARCH_ITEM:
      localStorage.removeItem('bestquoteSearch');
      return {};
    default:
      return state;
  }
};
