import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { FadeLoader } from '../../components/UI/FadeLoader';
import PropTypes from 'prop-types';
import { getHeaders, lifeUrl } from '../../config';

import Header from '../../components/Header';
import LifeBuyForm from './LifeBuyForm';
import LifeProductBreakdown from './LifeProductBreakdown';

const LifeDetails = props => {
  const [policy, setPolicy] = useState(null);

  const getPolicy = () => {
    axios
      .get(`${lifeUrl}/${props.match.params.id}/get`, {
        headers: getHeaders(),
      })
      .then(res => {
        setPolicy(res.data);
      });
  };

  useEffect(() => {
    getPolicy();
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Header>{/* <ProductSearchSummary /> */}</Header>

      <div className="wrapper mt-5rem mb-5rem">
        {policy ? (
          <div className="row reverse-md">
            <div className="col-md-7">
              <LifeBuyForm policy={policy} getPolicy={getPolicy} />
            </div>
            <div className="col-md-5 mb-2rem">
              <p className="mb-2rem">{"Here's a breakdown of your policy:"}</p>
              <LifeProductBreakdown
                product={policy}
                premium={policy.premium}
                isPurchasing={true}
              />
            </div>
          </div>
        ) : (
          <div className="site-content mb-10rem">
            <FadeLoader />
          </div>
        )}
      </div>
    </div>
  );
};

LifeDetails.propTypes = {
  search: PropTypes.object,
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default withRouter(LifeDetails);
