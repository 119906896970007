import { constants } from '../../app-constants';

let savedUser = localStorage.getItem('bestquoteUser');
if (!savedUser) savedUser = '{}';
const initialState = JSON.parse(savedUser);

// eslint-disable-next-line @typescript-eslint/default-param-last
export const authUser = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_USER:
      localStorage.setItem('bestquoteUser', JSON.stringify(action.payload));
      return action.payload;
    case constants.UNSET_USER:
      localStorage.removeItem('bestquoteUser');
      return {};
    default:
      return state;
  }
};
