import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import PrivateRoute from '../../hocs/PrivateRoute';
import UserCancellations from './UserCancellations';
// import UserSearches from "./UserSearches";
import UserClaims from './UserClaims';
import UserAccount from './UserAccount';
import PageNotFound from '../PageNotFound';
import PolicyDetail from './PolicyDetail';
import NewCancellation from './NewCancellation';
import NewClaim from './NewClaim';
import DashboardLayout from '../../components/Layouts/DashboardLayout';
import DashboardOverview from '.';
// import MyMotorPolicies from './motor'
// import MyTravelPolicies from './travel'
import MyHomePolicies from './property';
import MyHealthPolicies from './health';
// import MyLifePolicies from './life'
import MotorPolicies from './motor-policies';
import TravelPolicies from './travel-policies';
import LifePolices from './life-policies';

const DashboardRoutes = props => {
  return (
    <DashboardLayout>
      <Switch>
        <PrivateRoute
          exact
          path={props.match.path}
          component={DashboardOverview}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/motor`}
          component={MotorPolicies}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/travel`}
          component={TravelPolicies}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/property`}
          component={MyHomePolicies}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/health`}
          component={MyHealthPolicies}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/life`}
          component={LifePolices}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/cancellations`}
          component={UserCancellations}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/cancellations/new/:policyId`}
          component={NewCancellation}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/claims`}
          component={UserClaims}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/claims/travel/new/:policyId`}
          component={NewClaim}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/claims/motor/new/:policyId`}
          component={NewClaim}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/claims/property/new/:policyId`}
          component={NewClaim}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/claims/health/new/:policyId`}
          component={NewClaim}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/claims/life/new/:policyId`}
          component={NewClaim}
        />
        {/* <PrivateRoute
              path={`${props.match.path}/saved`}
              component={UserSearches}
            /> */}
        <PrivateRoute
          path={`${props.match.path}/settings`}
          component={UserAccount}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/policies/travel/:policyId`}
          component={PolicyDetail}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/policies/motor/:policyId`}
          component={PolicyDetail}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/policies/property/:policyId`}
          component={PolicyDetail}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/policies/health/:policyId`}
          component={PolicyDetail}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}/policies/life/:policyId`}
          component={PolicyDetail}
        />
        <Route component={PageNotFound} />
      </Switch>
    </DashboardLayout>
  );
};

DashboardRoutes.propTypes = {
  match: PropTypes.object,
};

export default withRouter(DashboardRoutes);
