/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FadeLoader } from '../../../components/UI/FadeLoader';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { getHeaders, getFormHeaders } from '../../../config';
import FileUpload from '../FileUpload';

class PropertyClaimForm extends Component {
  constructor(props) {
    super(props);

    // third party
    let thirdParty = {
      name: '',
      contact: '',
    };

    let thirdParties = [
      {
        name: '',
        contact: '',
      },
    ];

    for (let i = 0; i < 0; i++) {
      thirdParties.push(Object.assign({}, thirdParty));
    }
    // end third party

    // injured party
    let injuredParty = {
      name: '',
      contact: '',
    };

    let injuredParties = [
      {
        name: '',
        contact: '',
      },
    ];

    for (let i = 0; i < 0; i++) {
      injuredParties.push(Object.assign({}, injuredParty));
    }
    // end injured party

    // witnesses
    let witness = {
      name: '',
      contact: '',
    };

    let witnesses = [
      {
        name: '',
        contact: '',
      },
    ];

    for (let i = 0; i < 0; i++) {
      witnesses.push(Object.assign({}, witness));
    }
    // end witnesses

    this.state = {
      claimId: 0,
      required: {
        // PROPERTY DAMAGE DOCUMENTS
        policeReport: null,
        policySchedule: null,
      },

      costOfRepair: '',
      costOfThirdparty: '',
      costOfPersonalAccident: '',
      damageDescription: '',
      damageTimestamp: '',

      natureOfLoss: [],
      natureOfLossOptions: [],
      witnesses: witnesses,
      thirdParties: thirdParties,
      injuredParties: injuredParties,

      showThirdPartyFields: false,
      showPersonalAccidentFields: false,

      successMessage: '',
      errorMessage: {},
      isSubmitting: false,
      isClaiming: false,
    };
  }

  async componentDidMount() {
    try {
      let resp = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE}/api/v1/property-claims/natures/`,
        headers: getHeaders(),
      });
      this.setState({ natureOfLossOptions: resp.data });
    } catch (err) {}
  }

  handleFiles = (stateName, document) => event => {
    this.setState({
      [stateName]: {
        ...this.state[stateName],
        [document]: event.target.files[0],
      },
    });

    this.setState(prevState => ({
      errorMessage: {
        ...prevState.errorMessage,
        [stateName]: null,
        nonFieldErrors: null,
      },
    }));
  };

  handleDateUpdate = stateName => date => {
    this.setState({ [stateName]: date });

    this.setState(prevState => ({
      errorMessage: {
        ...prevState.errorMessage,
        [stateName]: null,
        nonFieldErrors: null,
      },
    }));
  };

  handleInput = event => {
    let { name, value } = event.target;

    this.setState({
      [name]: value,
    });

    this.setState(prevState => ({
      errorMessage: {
        ...prevState.errorMessage,
        [name]: null,
        nonFieldErrors: null,
      },
    }));
  };

  checkForPersonalAccidentLoss = options => {
    for (let i = 0; i < options.length; i++) {
      let optionTitle = options[i].title.toLowerCase();
      if (optionTitle.includes('personal')) {
        return true;
      }
    }
    return false;
  };

  checkForThirdPartyLoss = options => {
    for (let i = 0; i < options.length; i++) {
      let optionTitle = options[i].title.toLowerCase();
      if (optionTitle.includes('third')) {
        return true;
      }
    }
    return false;
  };

  handleSelect = selectedOption => {
    this.setState({ natureOfLoss: selectedOption }, () => {
      this.setState({
        showPersonalAccidentFields: this.checkForPersonalAccidentLoss(
          this.state.natureOfLoss,
        ),
      });

      this.setState({
        showThirdPartyFields: this.checkForThirdPartyLoss(
          this.state.natureOfLoss,
        ),
      });
    });

    this.setState(prevState => ({
      errorMessage: {
        ...prevState.errorMessage,
        natureOfLoss: null,
        nonFieldErrors: null,
      },
    }));
  };

  // witnesses
  handleAddWitness = event => {
    event.preventDefault();
    this.setState({
      witnesses: this.state.witnesses.concat([
        {
          name: '',
          contact: null,
        },
      ]),
    });
  };

  handleRemoveWitness = id => event => {
    event.preventDefault();
    this.setState({
      witnesses: this.state.witnesses.filter((wit, witId) => id !== witId),
    });
  };

  handleWitnessInputUpdate = (stateName, id) => event => {
    const updatedWitness = this.state.witnesses.map((res, resId) => {
      // retain data of other witnesses
      if (id !== resId) return res;
      // update this witness
      return { ...res, [stateName]: event.target.value };
    });
    this.setState({ witnesses: updatedWitness });

    // update witness errors
    if (this.state.errorMessage.witnesses) {
      const updatedErrors = this.state.errorMessage.witnesses.map(w => {
        // maintain other house errors
        if (id !== w.witId) return w;

        return { ...w, [stateName]: null };
      });

      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          witnesses: updatedErrors,
          nonFieldErrors: null,
        },
      }));
    }
    // end update witnesses errors
  };
  // end witnesses

  // injured parties
  handleAddInjuredParties = event => {
    event.preventDefault();
    this.setState({
      injuredParties: this.state.injuredParties.concat([
        {
          name: '',
          contact: null,
        },
      ]),
    });
  };

  handleRemoveInjuredParties = id => event => {
    event.preventDefault();
    this.setState({
      injuredParties: this.state.injuredParties.filter(
        (wit, witId) => id !== witId,
      ),
    });
  };

  handleInjuredPartyInputUpdate = (stateName, id) => event => {
    const updatedInjureds = this.state.injuredParties.map((res, resId) => {
      // retain data of other injured parties
      if (id !== resId) return res;
      // update this injured party
      return { ...res, [stateName]: event.target.value };
    });
    this.setState({ injuredParties: updatedInjureds });

    // update injured party errors
    if (this.state.errorMessage.injuredParties) {
      const updatedErrors = this.state.errorMessage.injuredParties.map(i => {
        // maintain other house errors
        if (id !== i.injId) return i;

        return { ...i, [stateName]: null };
      });

      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          injuredParties: updatedErrors,
          nonFieldErrors: null,
        },
      }));
    }
    // end update injured party errors
  };
  // end injured parties

  // third parties
  handleAddThirdParties = event => {
    event.preventDefault();
    this.setState({
      thirdParties: this.state.thirdParties.concat([
        {
          name: '',
          contact: null,
        },
      ]),
    });
  };

  handleRemoveThirdParties = id => event => {
    event.preventDefault();
    this.setState({
      thirdParties: this.state.thirdParties.filter((wit, witId) => id !== witId),
    });
  };

  handleThirdPartyInputUpdate = (stateName, id) => event => {
    const updatedthirdParties = this.state.thirdParties.map((res, resId) => {
      // retain data of other third parties
      if (id !== resId) return res;
      // update this third party
      return { ...res, [stateName]: event.target.value };
    });
    this.setState({ thirdParties: updatedthirdParties });

    // update third party errors
    if (this.state.errorMessage.thirdParties) {
      const updatedErrors = this.state.errorMessage.thirdParties.map(t => {
        // maintain other house errors
        if (id !== t.tId) return t;

        return { ...t, [stateName]: null };
      });

      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          thirdParties: updatedErrors,
          nonFieldErrors: null,
        },
      }));
    }
    // end update third party errors
  };
  // end third parties

  onSubmit = async event => {
    event.preventDefault();

    let documents = [
      ...Object.keys(this.state.required).map(key => {
        return { document_name: key, document: this.state.required[key] };
      }),
    ];

    let payload = {
      costOfRepair: this.state.costOfRepair
        ? Number(this.state.costOfRepair).toFixed(2)
        : '',
      costOfThirdparty: this.state.showThirdPartyFields
        ? this.state.costOfThirdparty
          ? Number(this.state.costOfThirdparty).toFixed(2)
          : ''
        : '0.00',
      costOfPersonalAccident: this.state.showPersonalAccidentFields
        ? this.state.costOfPersonalAccident
          ? Number(this.state.costOfPersonalAccident).toFixed(2)
          : ''
        : '0.00',
      damageDescription: this.state.damageDescription,
      natureOfLoss: this.state.natureOfLoss.map(nat => {
        return nat.id;
      }),
      witnesses: this.state.witnesses,
      thirdParties: this.state.showThirdPartyFields
        ? this.state.thirdParties
        : [],
      injuredParties: this.state.showPersonalAccidentFields
        ? this.state.injuredParties
        : [],
      damageTimestamp: this.state.damageTimestamp
        ? moment(this.state.damageTimestamp).format('YYYY-MM-DDTHH:mm:ssZ')
        : '',
    };

    let claimFiles = new FormData();
    documents.forEach(document => {
      claimFiles.append(document.document_name, document.document);
    });

    this.setState({ isSubmitting: true });

    try {
      await axios({
        headers: getHeaders(),
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE}/api/v1/property-policies/${this.props.match.params.policyId}/claim/`,
        data: payload,
      });

      try {
        await axios({
          headers: getFormHeaders(),
          method: 'POST',
          url: `${process.env.REACT_APP_API_BASE}/api/v1/property-policies/${this.props.match.params.policyId}/claim-documents/`,
          data: claimFiles,
        });
      } catch (err) {
        console.error(err);
        this.setState({
          errorMessage: err.response.data,
        });
      }

      this.props.history.push('/account/claims');
    } catch (err) {
      this.setState({ isSubmitting: false });
      if (err.response) {
        err.response.status === 400
          ? this.setState(() => ({
            errorMessage: {
              ...err.response.data,
              nonFieldErrors: ['Solve form errors above'],
            },
          }))
          : this.setState({
            errorMessage: {
              nonFieldErrors: ['Oops, server Error! Retry'],
            },
          });
      } else {
        this.setState(prevState => ({
          errorMessage: {
            ...prevState.errorMessage,
            nonFieldErrors: ['Error Connecting to Server, Retry.'],
          },
        }));
      }
      window.scrollTo(0, 0);
    } finally {
      this.setState({ isSubmitting: false, isClaiming: false });
    }
  };

  render() {
    let { product } = this.props;

    return (
      <div>
        <p className="mb-1rem">Make a Claim on Policy</p>
        <p className="text--muted mb-2rem">
          Review the policy below and add the required details &amp; documents
          to make your claim
        </p>
        <div className="align-vertical mb-2rem" style={{ fontSize: '12px' }}>
          <img
            alt={product.company.name}
            className="mr-2rem"
            src={product.company.logoUrl}
            style={{ maxHeight: '3rem' }}
          />
          <span>{product.company.name}</span>
        </div>

        <form className="form" onSubmit={this.onSubmit}>
          <div className="flex mb-2rem" style={{ fontSize: '12px' }}>
            <div className="claim-form mb-2rem">
              <div className="claim-form__loss-type flex-column mb-2rem">
                <label
                  className="form__label mt-1rem mb-1rem"
                  htmlFor="natureOfLoss"
                  style={{ marginTop: '2rem' }}
                >
                  Nature of Loss
                </label>
                <Select
                  value={this.state.natureOfLoss}
                  onChange={this.handleSelect}
                  options={this.state.natureOfLossOptions}
                  multi
                />
                {this.state.errorMessage.natureOfLoss
                  ? this.state.errorMessage.natureOfLoss.map((err, i)=> (
                      <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                  : null}

                <label
                  className="form__label mt-1rem mb-1rem"
                  htmlFor="costOfRepair"
                  style={{ marginTop: '1.5rem' }}
                >
                  Cost of Repair
                </label>
                <input
                  className="form__input"
                  type="number"
                  min="0"
                  step="0.01"
                  name="costOfRepair"
                  onChange={this.handleInput}
                  value={this.state.costOfRepair}
                />
                {this.state.errorMessage.costOfRepair
                  ? this.state.errorMessage.costOfRepair.map((err, i)=> (
                      <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                  : null}

                {this.state.showThirdPartyFields && (
                  <>
                    <label
                      className="form__label mt-1rem mb-1rem"
                      htmlFor="costOfThirdparty"
                      style={{ marginTop: '2rem' }}
                    >
                      Estimated Cost of Third Party Damage
                    </label>
                    <input
                      className="form__input"
                      type="number"
                      min="0"
                      step="0.01"
                      name="costOfThirdparty"
                      onChange={this.handleInput}
                      value={this.state.costOfThirdparty}
                    />
                    {this.state.errorMessage.costOfThirdparty
                      ? this.state.errorMessage.costOfThirdparty.map((err, i)=> (
                          <p key={i} style={{ color: 'red', marginTop: '1em' }}>
                            {err}
                          </p>
                      ))
                      : null}
                  </>
                )}

                {this.state.showPersonalAccidentFields && (
                  <>
                    <label
                      className="form__label mt-1rem mb-1rem"
                      htmlFor="costOfPersonalAccident"
                      style={{ marginTop: '2rem' }}
                    >
                      Estimated Cost of Personal Accident Damage
                    </label>
                    <input
                      className="form__input"
                      type="number"
                      min="0"
                      step="0.01"
                      name="costOfPersonalAccident"
                      onChange={this.handleInput}
                      value={this.state.costOfPersonalAccident}
                    />
                    {this.state.errorMessage.costOfPersonalAccident
                      ? this.state.errorMessage.costOfPersonalAccident.map(
                        (err, i)=> (
                            <p key={i} style={{ color: 'red', marginTop: '1em' }}>
                              {err}
                            </p>
                        ),
                      )
                      : null}
                  </>
                )}

                <label
                  className="form__label mt-1rem mb-1rem"
                  htmlFor="damage_timestamp"
                  style={{ marginTop: '2rem' }}
                >
                  Date and Time of Damage
                </label>
                <DatePicker
                  selected={this.state.damageTimestamp}
                  placeholderText="DD/MM/YYYY"
                  dateFormat="YYYY-MM-DDTHH:mm:ss.sssZ"
                  className="form__input"
                  onChange={this.handleDateUpdate('damageTimestamp')}
                  showTimeSelect={true}
                />
                {this.state.errorMessage.damageTimestamp
                  ? this.state.errorMessage.damageTimestamp.map((err, i)=> (
                      <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                  : null}

                <p
                  className="mt-1rem mb-1rem form__label"
                  style={{ marginTop: '2rem' }}
                >
                  Required Documents for all claims
                </p>

                <FileUpload
                  document={this.state.required.policeReport}
                  fileName="Police Report"
                  documentName="policeReport"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                {this.state.errorMessage.policeReport
                  ? this.state.errorMessage.policeReport.map((err, i)=> (
                      <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                  : null}

                {/* // TODO: update file handler to allow for multiple files */}
                <FileUpload
                  document={this.state.required.policySchedule}
                  fileName="Policy Schedule"
                  documentName="policySchedule"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                {this.state.errorMessage.policySchedule
                  ? this.state.errorMessage.policySchedule.map((err, i)=> (
                      <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                  : null}

                <label
                  className="form__label mt-1rem mb-1rem"
                  htmlFor="damageDescription"
                  style={{ marginTop: '2rem' }}
                >
                  Describe Damage
                </label>
                <textarea
                  className="form__input"
                  min="0"
                  step="0.01"
                  rows="10"
                  name="damageDescription"
                  onChange={this.handleInput}
                  value={this.state.damageDescription}
                  style={{ width: '100%' }}
                />
                {this.state.errorMessage.damageDescription
                  ? this.state.errorMessage.damageDescription.map((err, i)=> (
                      <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                  : null}

                {/* witnesses section */}
                <div
                  className=""
                  style={{
                    backgroundColor: '#f2f2f2',
                    padding: '2rem',
                    marginTop: '2rem',
                  }}
                >
                  <h3 className="mt-5rem site-content__lead">Witnesses</h3>
                  {this.state.witnesses.map((wit, id) => (
                    <>
                      <div className="mt-3rem">
                        {this.state.witnesses.length > 1 ? (
                          <h4>Witness {id + 1}</h4>
                        ) : null}
                      </div>
                      <label className="form__label mt-1rem mb-1rem">
                        Name
                      </label>
                      <input
                        className="form__input"
                        placeholder="Ben Brako"
                        value={wit.name}
                        onChange={this.handleWitnessInputUpdate('name', id)}
                      />
                      {this.state.errorMessage.witnesses
                        ? this.state.errorMessage.witnesses.map(
                          (err, i)=>
                            err.witId === id && (
                                <p key={i} style={{ color: 'red', marginTop: '1em' }}>
                                  {err.name}
                                </p>
                            ),
                        )
                        : null}
                      <br /> <br />
                      <label className="form__label mt-1rem mb-1rem">
                        Contact
                      </label>
                      <input
                        className="form__input"
                        placeholder="023 *******"
                        value={wit.contact}
                        onChange={this.handleWitnessInputUpdate('contact', id)}
                      />
                      {this.state.errorMessage.witnesses
                        ? this.state.errorMessage.witnesses.map(
                          (err, i)=>
                            err.witId === id && (
                                <p key={i} style={{ color: 'red', marginTop: '1em' }}>
                                  {err.contact}
                                </p>
                            ),
                        )
                        : null}
                      {this.state.witnesses.length > 1 ? (
                        <button
                          className="btn btn--danger mt-2rem"
                          onClick={this.handleRemoveWitness(id)}
                        >
                          Remove Witness {id + 1}
                        </button>
                      ) : null}
                    </>
                  ))}
                  <button
                    className="btn btn--proceed mt-5rem"
                    onClick={this.handleAddWitness}
                  >
                    Add Witness
                  </button>
                </div>
                {/* end witnesses secttion */}

                {/* third parties section */}
                {this.state.showThirdPartyFields && (
                  <div
                    className=""
                    style={{
                      backgroundColor: '#f2f2f2',
                      padding: '2rem',
                      marginTop: '2rem',
                    }}
                  >
                    <h3 className="mt-5rem site-content__lead">
                      Third Parties
                    </h3>
                    {this.state.thirdParties.map((third, id) => (
                      <>
                        <div className="mt-3rem">
                          {this.state.thirdParties.length > 1 ? (
                            <h4>Third Party {id + 1}</h4>
                          ) : null}
                        </div>
                        <label className="form__label mt-1rem mb-1rem">
                          Name
                        </label>
                        <input
                          className="form__input"
                          placeholder="John Koku"
                          value={third.name}
                          onChange={this.handleThirdPartyInputUpdate(
                            'name',
                            id,
                          )}
                        />
                        {this.state.errorMessage.thirdParties
                          ? this.state.errorMessage.thirdParties.map(
                            (err, i)=>
                              err.tId === id && (
                                  <p key={i} style={{ color: 'red', marginTop: '1em' }}>
                                    {err.name}
                                  </p>
                              ),
                          )
                          : null}
                        <br /> <br />
                        <label className="form__label mt-1rem mb-1rem">
                          Contact
                        </label>
                        <input
                          className="form__input"
                          placeholder="023 *******"
                          value={third.contact}
                          onChange={this.handleThirdPartyInputUpdate(
                            'contact',
                            id,
                          )}
                        />
                        {this.state.errorMessage.thirdParties
                          ? this.state.errorMessage.thirdParties.map(
                            (err, i)=>
                              err.tId === id && (
                                  <p key={i} style={{ color: 'red', marginTop: '1em' }}>
                                    {err.contact}
                                  </p>
                              ),
                          )
                          : null}
                        {this.state.thirdParties.length > 1 ? (
                          <button
                            className="btn btn--danger mt-2rem"
                            onClick={this.handleRemoveThirdParties(id)}
                          >
                            Remove Third Party {id + 1}
                          </button>
                        ) : null}
                      </>
                    ))}
                    <button
                      className="btn btn--proceed mt-5rem"
                      onClick={this.handleAddThirdParties}
                    >
                      Add Third Party
                    </button>
                  </div>
                )}
                {/* end third parties secttion */}

                {/* injured parties section */}
                {this.state.showPersonalAccidentFields && (
                  <div
                    className=""
                    style={{
                      backgroundColor: '#f2f2f2',
                      padding: '2rem',
                      marginTop: '2rem',
                    }}
                  >
                    <h3 className="mt-5rem site-content__lead">
                      Injured Party
                    </h3>
                    {this.state.injuredParties.map((inj, id) => (
                      <>
                        <div className="mt-3rem">
                          {this.state.injuredParties.length > 1 ? (
                            <h4>Injured Party {id + 1}</h4>
                          ) : null}
                        </div>
                        <label className="form__label mt-1rem mb-1rem">
                          Name
                        </label>
                        <input
                          className="form__input"
                          placeholder="Ben Brako"
                          value={inj.name}
                          onChange={this.handleInjuredPartyInputUpdate(
                            'name',
                            id,
                          )}
                        />
                        {this.state.errorMessage.injuredParties
                          ? this.state.errorMessage.injuredParties.map(
                            (err, i)=>
                              err.injId === id && (
                                  <p key={i} style={{ color: 'red', marginTop: '1em' }}>
                                    {err.name}
                                  </p>
                              ),
                          )
                          : null}
                        <br /> <br />
                        <label className="form__label mt-1rem mb-1rem">
                          Contact
                        </label>
                        <input
                          className="form__input"
                          placeholder="023 *******"
                          value={inj.contact}
                          onChange={this.handleInjuredPartyInputUpdate(
                            'contact',
                            id,
                          )}
                        />
                        {this.state.errorMessage.injuredParties
                          ? this.state.errorMessage.injuredParties.map(
                            (err, i)=>
                              err.injId === id && (
                                  <p key={i}
                                    style={{
                                      color: 'red',
                                      marginTop: '1em',
                                    }}
                                  >
                                    {err.contact}
                                  </p>
                              ),
                          )
                          : null}
                        {this.state.injuredParties.length > 1 ? (
                          <button
                            className="btn btn--danger mt-2rem"
                            onClick={this.handleRemoveInjuredParties(id)}
                          >
                            Remove Injured Party {id + 1}
                          </button>
                        ) : null}
                      </>
                    ))}
                    <button
                      className="btn btn--proceed mt-5rem"
                      onClick={this.handleAddInjuredParties}
                    >
                      Add Injured Party
                    </button>
                  </div>
                )}
                {/* end injured parties secttion */}

                {this.state.errorMessage.nonFieldErrors
                  ? this.state.errorMessage.nonFieldErrors.map((err, i)=> (
                      <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                  : null}
              </div>
            </div>
          </div>

          <hr width="60%" />

          {this.state.isSubmitting ? (
            <div className="mt-5rem">
              <FadeLoader />
            </div>
          ) : (
            <div className="mt-5rem">
              <button
                className="btn btn--gradient-primary btn--width-md mb-2rem mr-2rem"
                type="submit"
              >
                Make Claim
              </button>
              <button
                className="btn btn--width-md mb-2rem mr-2rem"
                onClick={() => this.props.history.push('/account')}
              >
                CANCEL
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}
PropertyClaimForm.propTypes = {
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
  match: PropTypes.object,
  history: PropTypes.object,
  product: PropTypes.shape({
    company: PropTypes.shape({
      logoUrl: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
};

export default withRouter(
  connect(state => ({
    authUser: state.authUser,
  }))(PropertyClaimForm),
);
