import React, { useState } from 'react';
import axios from 'axios';
import { getHeaders, promoUrl, successNotification } from '../../config';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import BasicInput from '../Forms/BasicInput';
import WithUser from '../../hocs/WithUser';
import PropTypes from 'prop-types';

const PromoForm = props => {
  const [policy, setPolicy] = useState({
    policyType: props.policyType || '',
    policy: props.policy || null,
    promoCode: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const removeErrorMessage = stateName => {
    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        nonFieldErrors: null,
      });
    }
  };

  const handleFieldUpdate = stateName => event => {
    setPolicy({ ...policy, [stateName]: event.target.value });

    // remove form field errors
    removeErrorMessage(stateName);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    axios
      .post(`${promoUrl}/apply`, policy, { headers: getHeaders() })
      .then(res => {
        props.handleSuccess();
        successNotification(
          `${res.data.data.discountPercentage}% ${res.data.message}`,
        );
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
        setSuccessMessage(
          `${res.data.data.discountPercentage}% ${res.data.message}`,
        );
        // setPolicy({ ...policy, promoCode: '' })
        setIsSubmitting(false);
      })
      .catch(err => {
        setIsSubmitting(false);
        if (err.response) {
          if (err.response.status === 400) {
            setErrorMessage({
              ...err.response.data,
              nonFieldErrors: [err.response.data.message],
            });
            setTimeout(() => {
              setErrorMessage({
                ...err.response.data,
                nonFieldErrors: [],
              });
            }, 10000);
            // errorNotification(err.response.data.message)
          } else {
            setErrorMessage({
              ...errorMessage,
              nonFieldErrors: ['Oops, something went wrong! kindly retry'],
            });
            setTimeout(() => {
              setErrorMessage({
                ...errorMessage,
                nonFieldErrors: [],
              });
            }, 10000);
          }
        } else {
          setErrorMessage({
            ...errorMessage,
            nonFieldErrors: ['Error Connecting to Server, kindly retry.'],
          });
          setTimeout(() => {
            setErrorMessage({
              ...errorMessage,
              nonFieldErrors: [],
            });
          }, 10000);
        }
      });
  };

  return (
    <div>
      <Form
        onFinish={handleSubmit}
        layout="inline"
        style={{ marginLeft: '1rem', width: '100%' }}
        className="mb-2rem"
      >
        <Form.Item style={{ marginRight: '0rem' }}>
          <BasicInput
            label={'Do you have a promo code?'}
            value={policy.promoCode}
            name="promoCode"
            placeholder="Enter your code here"
            handleChange={handleFieldUpdate('promoCode')}
            required={false}
            style={{ marginRight: '0rem' }}
          />
        </Form.Item>
        <Form.Item>
          <button
            className="btn_normal btn_dark btn_md"
            type="submit"
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              padding: '0.7rem 1.5rem',
              marginTop: '2.8rem',
            }}
          >
            {isSubmitting ? <LoadingOutlined /> : 'Apply'}
          </button>
        </Form.Item>

        {/* <button className="btn_normal btn_gradient btn_md" type="submit">
          {isSubmitting ? (
            <LoadingOutlined />
          ) :
            'Apply'
          }
        </button> */}
      </Form>
      <div>
        {successMessage ? (
          <p style={{ marginLeft: '1rem', color: 'green', marginTop: '1em' }}>
            {successMessage}
          </p>
        ) : null}

        {errorMessage.nonFieldErrors
          ? errorMessage.nonFieldErrors.map((err, i) => (
              <p
                style={{ marginLeft: '1rem', color: 'red', marginTop: '1em' }}
                key={i}
              >
                {err}
              </p>
          ))
          : null}
      </div>
    </div>
  );
};

PromoForm.propTypes = {
  policyType: PropTypes.string,
  policy: PropTypes.string,
  handleSuccess: PropTypes.func,
};

export default WithUser(PromoForm);
