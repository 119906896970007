import React from 'react';

import Navbar from '../components/Navbar';
import cookie from '../img/cookie.svg';

const CookiePage = () => {
  return (
    <div>
      <Navbar />
      <main className="center-auto mb-10rem wrapper--sub">
        <div
          className=" mt-5rem"
          style={{ maxWidth: '800px', lineHeight: '2.5rem' }}
        >
          <div>
            <img src={cookie} className="mr-1rem" alt="" />
            <h3 className="inline-block mb-2rem text--aqua">Cookie Policy</h3>
          </div>
          <p className="mb-5rem">
            <span className="block mb-2rem">
              {`BestQuote (Ghana) Limited (“BestQuote") is committed to ensuring
              that your privacy is protected. We aim to be as open and
              transparent as possible around the information we collect when
              you visit our website.`}{' '}
            </span>

            <span className="block mb-2rem">
              To make full use of the features and services we offer at
              www.mybestquote.com, your computer, tablet or mobile phone will
              need to accept cookies, as we can only provide you with certain
              features of this website by using them.{' '}
            </span>

            <span className="block mb-2rem">
              This policy explains what cookies are and how we use them. It
              also explains the different types of cookies we use, and how you
              can change or delete them. We hope that this policy helps you
              understand, and feel more confident about our use of cookies.
            </span>

            <span className="block mb-2rem">
              <strong>
                <em>Last Updated: July 10, 2018</em>
              </strong>
            </span>
          </p>

          <h4 className="mb-2rem">What are cookies?</h4>
          <p className="mb-5rem">
            <span className="block mb-2rem">
              Cookies are small text files that are stored by your browser
              onto your computer or other internet enabled devices, such as a
              smartphone or tablet when you visit the BestQuote website. They
              are widely used to make websites work in a better and more
              efficient way. They can do this because websites can read and
              write these files, enabling them to recognize you and remember
              important information that you will make use of, thus making a
              website more convenient (e.g. by remembering your user
              preferences) and provide you with a more secure online
              experience. Cookies generally do not hold any information to
              identify an individual person, but are instead used to identify
              a browser on an individual machine. Cookies can be referred to
              as session cookies or persistent cookies, depending on how long
              they are used:
            </span>
          </p>

          <h4 className="mb-2rem">Types of Cookies</h4>
          <p className="mb-5rem">
            <span className="block mb-2rem">
              {`Cookies also have, broadly speaking, four different functions
              and can be categorised as follows: 'necessary' cookies,
              'performance' cookies, 'functionality' cookies and 'targeting'
              or 'advertising' cookies.`}
            </span>

            <span className="block mb-2rem">
              <strong>Necessary cookies</strong>{` - These cookies are essential
              to enable a user navigate around a website and use its features.
              Without them, you would not be able to use basic services like
              registration or getting an insurance quote. Necessary cookies
              allow you to sign in to the BestQuote website as a registered
              user. These cookies do not gather information about you that
              could be used for marketing or remembering where you've been on
              the internet.`}
            </span>

            <span className="block mb-2rem">
              <strong>Performance cookies</strong>{` - These cookies help us
              understand how visitors interact with our websites by providing
              information about the sections visited, the time spent on the
              website, and any issues encountered, such as error messages.
              Performance cookies collect anonymous data for statistical
              purposes on how visitors use a website. They don't contain
              personal information, and are used to improve your user
              experience of a website.`}
            </span>

            <span className="block mb-2rem">
              <strong>Functionality cookies</strong> - Functionality cookies
              allow a website to remember the choices you make (such as your
              user name, language or the region you are in) and to provide
              enhanced and more personal features. They also remember changes
              you have made, such as customising a certain webpage, and for
              other services you request such as watching a video or
              commenting on a blog. Functionality cookies will not track your
              activity on other websites.
            </span>

            <span className="block mb-2rem">
              <strong>Targeting cookies</strong> - These cookies are used to
              deliver content that is more relevant to you and your interests.
              They may be used to deliver targeted advertising or to limit the
              number of times you see an advertisement. They also help us
              measure the effectiveness of advertising campaigns on BestQuote
              websites. We may use these cookies to remember websites you have
              visited and we may share this information with other parties,
              including advertisers and our agencies.
            </span>
          </p>

          <h4 className="mb-2rem">Third Party Cookies</h4>
          <p className="mb-5rem">
            In some cases, we may use third parties to manage our cookies but
            we do not allow any third party to use these cookies other than
            for the purposes listed in this policy. Third party advertisers
            and other organisations may also use their own cookies or other
            technologies to collect information about the content of our
            website and/or the advertisements you have clicked on. This
            information may be used by them to serve advertisements which they
            believe are most likely to be of interest to you based on content
            you have viewed. Third party advertisers may also use this
            information to measure the effectiveness of their advertisements.
            We do not have access or control over the cookies placed on our
            website by third parties and use of their cookies is subject to
            their own privacy policies. We recommend that you review the
            privacy policies of each of our third parties.
          </p>

          <h4 className="mb-2rem">User Communication</h4>
          <p className="mb-5rem">
            When you send email or other communications to BestQuote, we may
            retain those communications to process your inquiries, respond to
            your requests and improve our services.
          </p>

          <h4 className="mb-2rem">Managing Cookies</h4>
          <p className="mb-5rem">
            Most internet browsers are initially set up to automatically
            accept cookies. You can change the settings to block cookies or to
            alert you when cookies are being sent to your device. Please note,
            if you choose to block all cookies, our site will not function as
            intended and you will not be able to use or access many of the
            services we provide. Generally, you have the option to see what
            cookies you’ve got and delete them individually, block third-party
            cookies or cookies from particular sites. Visit the ‘options’ or
            ‘preferences’ menu on your browser to change settings.
          </p>

          <h4 className="mb-2rem">User Agreement</h4>
          <p className="mb-5rem">
            By continuing to use our site, you agree to the placement of
            cookies on your device. If you choose not to receive our cookies,
            we cannot guarantee that your experience will be as fulfilling as
            it would otherwise be.
          </p>
        </div>
      </main>
    </div>
  );
};


export default CookiePage;
