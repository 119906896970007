import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import { getHeaders, motorUrl } from '../../config';
import { getCookie } from '../../utils/urls';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Modal } from 'antd';

import BasicInput from '../Forms/BasicInput';
import SelectInput from '../Forms/SelectInput';
import PropTypes from 'prop-types';
import { initiatePayment } from '../Payments/utils';
import Payments from '../Payments';
import PromoForm from '../Promo/PromoForm';
import UserInfoForm from '../Auth/UserInfoForm';
import LoginPopUp from '../Auth/LoginPopUp';
import WithUser from '../../hocs/WithUser';

const MotorBuyForm = props => {
  const [policy, setPolicy] = useState({
    id: props.policy.id,
    firstName: '',
    lastName: '',
    // otherNames: '',
    address: '',
    dateOfBirth: new Date(),
    occupation: '',
    email: '',
    phoneNumber: '',
    startDate: new Date(),
    buyingForClient: false,

    // for payments
    policy: null,
    policyType: 'motor',
    showModal: false,
  });

  const [formSection, setFormSection] = useState(1);
  // eslint-disable-next-line
  const [referrer, setReferrer] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  // eslint-disable-next-line
  const [affiliateReferrer, setAffiliateReferrer] = useState(false)
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);

  const removeErrorMessage = stateName => {
    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        nonFieldErrors: null,
      });
    } else if (errorMessage.vehicles) {
      setErrorMessage({
        ...errorMessage,
        vehicles: errorMessage.vehicles.map(v => {
          return { ...v, [stateName]: null };
        }),
        nonFieldErrors: null,
      });
    } 
  };

  const handleFieldUpdate = stateName => event => {
    setPolicy({ ...policy, [stateName]: event.target.value });

    // remove form field errors
    removeErrorMessage(stateName);
  };

  const handleSelectUpdate = stateName => value => {
    setPolicy({ ...policy, [stateName]: value });

    // remove form field errors
    removeErrorMessage(stateName);
  };

  // const handleDateUpdate = stateName => date => {
  //   setPolicy({ ...policy, [stateName]: date })

  //   // remove form field errors
  //   removeErrorMessage(stateName)
  // }

  const handleSubmit = async () => {
    let data = {
      ...policy,
      startDate: policy.startDate
        ? moment(policy.startDate).format('YYYY-MM-DD')
        : '',
      dateOfBirth: policy.dateOfBirth
        ? moment(policy.dateOfBirth).format('YYYY-MM-DD')
        : '',
    };
    delete data.policy;
    delete data.policyType;
    delete data.showModal;

    setIsSubmitting(true);

    axios
      .put(`${motorUrl}/${policy.id}`, data, {
        headers: getHeaders(),
      })
      .then(res => {
        initiatePayment('motor', res.data.data.id);
        setPolicy({
          ...policy,
          showModal: true,
          policy: res.data.data,
          policyType: 'motor',
        });
        // props.history.push({
        //   pathname: `/payments/new/${res.data.data.id}`,
        //   state: { id: res.data.data.id, type: 'motor' }
        // })
        setIsSubmitting(false);
      })
      .catch(err => {
        setIsSubmitting(false);
        if (err.response) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          err.response.status === 400
            ? setErrorMessage({
              ...err.response.data,
              nonFieldErrors: ["Kindly make sure you've completed the form"],
            })
            : setErrorMessage({
              ...errorMessage,
              nonFieldErrors: ['Oops, server Error! Retry'],
            });
        } else {
          setErrorMessage({
            ...errorMessage,
            nonFieldErrors: ['Error Connecting to Server, Retry.'],
          });
        }
      });
  };

  const handleNextSection = () => {
    setFormSection(formSection + 1);
  };

  const handleOk = () => {
    setPolicy({ ...policy, showModal: false });
  };

  const handleCancel = () => {
    setPolicy({ ...policy, showModal: false });
  };

  useEffect(() => {
    // initialize referrer code if available.
    if (getCookie('referrer')) {
      setAffiliateReferrer(true);
      setReferrer(getCookie('referrer'));
    }
  }, []);

  const handleFormChecks = () => {
    // eslint-disable-next-line react/prop-types
    if (!props.authUser.user.isComplete) {
      setShowUserInfoModal(true);
    } else {
      handleSubmit();
    }
  };

  return (
    <Form
      onFinish={formSection === 1 ? handleFormChecks : handleNextSection}
      layout="vertical"
      className="motor_form"
      style={{
        border: '1px solid #f2f2f2',
        boxShadow: 'none',
      }}
    >
      {formSection === 1 ? (
        <div className="row">
          <h3 className="mb-2rem">
            Finish up by giving more information about yourself
          </h3>
          <div className="col-md-12 mb-2rem">
            <SelectInput
              label={'Who are you buying this policy for?'}
              value={policy.buyingForClient}
              name={'owner of policy'}
              optionList={[
                { label: 'Buying for myself', value: false },
                { label: 'Buying for someone else', value: true },
              ]}
              onChange={handleSelectUpdate('buyingForClient')}
              placeholder="select owner of policy"
              required={true}
            />

            {errorMessage.buyingForClient
              ? errorMessage.buyingForClient.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          {policy.buyingForClient ? (
            <>
              <div className="col-md-12 mb-2rem">
                <BasicInput
                  label="Kindly enter His/her first name"
                  value={policy.firstName}
                  name={'first name'}
                  placeholder="enter first name"
                  handleChange={handleFieldUpdate('firstName')}
                  required={true}
                />

                {errorMessage.firstName
                  ? errorMessage.firstName.map((err, i) => (
                      <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                        {err}
                      </p>
                  ))
                  : null}
              </div>

              <div className="col-md-12 mb-2rem">
                <BasicInput
                  label="Kindly enter His/her last name"
                  value={policy.lastName}
                  name={'last name'}
                  placeholder="enter last name"
                  handleChange={handleFieldUpdate('lastName')}
                  required={true}
                />

                {errorMessage.lastName
                  ? errorMessage.lastName.map((err, i) => (
                      <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                        {err}
                      </p>
                  ))
                  : null}
              </div>

              <div className="col-md-12 mb-2rem">
                <BasicInput
                  label="Kindly provide His/her phone number"
                  value={policy.phoneNumber}
                  name={'phone number'}
                  placeholder="enter phone number"
                  handleChange={handleFieldUpdate('phoneNumber')}
                  required={true}
                />

                {errorMessage.phoneNumber
                  ? errorMessage.phoneNumber.map((err, i) => (
                      <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                        {err}
                      </p>
                  ))
                  : null}
              </div>

              <div className="col-md-12 mb-2rem">
                <BasicInput
                  label="Kindly provide His/her email address"
                  inputType="email"
                  value={policy.email}
                  name={'email address'}
                  placeholder="enter email address"
                  handleChange={handleFieldUpdate('email')}
                  required={false}
                />

                {errorMessage.email
                  ? errorMessage.email.map((err, i) => (
                      <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                        {err}
                      </p>
                  ))
                  : null}
              </div>
            </>
          ) : null}

          <div className="col-md-12">
            {errorMessage.nonFieldErrors
              ? errorMessage.nonFieldErrors.map((err, i) => (
                  <div style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </div>
              ))
              : null}
          </div>

          {/* <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly enter your other names"
              value={policy.otherNames}
              name={'other name'}
              placeholder="enter other name"
              handleChange={handleFieldUpdate('otherNames')}
              required={false}
            />

            {errorMessage.otherNames
              ? errorMessage.otherNames.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div> */}

          {/* <div className="col-md-12 mb-2rem">
            <DatePickerInput
              label="Kindly indicate your date of birth"
              value={policy.dateOfBirth}
              handleChange={handleDateUpdate('dateOfBirth')}
              name="date of birth"
              required={true}
            />{' '}
            {errorMessage.dateOfBirth
              ? errorMessage.dateOfBirth.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div> */}

          <PromoForm
            policy={policy.id}
            policyType={policy.policyType}
            handleSuccess={props.getPolicy}
          />
        </div>
      ) : null}

      {formSection === 2 ? (
        <div className="row">
          <h3 className="mb-2rem">Give us more details about you</h3>
          {/* <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide your occupation or line of work"
              value={policy.occupation}
              name={'occupation'}
              placeholder="enter occupation"
              handleChange={handleFieldUpdate('occupation')}
              required={true}
            />

            {errorMessage.occupation
              ? errorMessage.occupation.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div> */}

          {/* <div className="col-md-12 mb-2rem">
            <BasicInput
              label="Kindly provide your address"
              value={policy.address}
              name={'address'}
              placeholder="enter address"
              handleChange={handleFieldUpdate('address')}
              required={true}
            />

            {errorMessage.address
              ? errorMessage.address.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div> */}
        </div>
      ) : null}

      <div className="motor_form_buttons">
        <Link
          style={{ color: 'grey', fontSize: '1.5rem' }}
          to="/buy-motor-insurance/compare"
        >
          Back
        </Link>

        <button className="btn_normal btn_gradient btn_md" type="submit">
          {isSubmitting ? (
            <LoadingOutlined />
          ) : formSection === 2 ? (
            'Submit'
          ) : (
            'Continue'
          )}
        </button>
      </div>

      {/* initialize payment process */}
      <Modal
        title=""
        centered
        open={policy.showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Payments policy={policy.policy} policyType={policy.policyType} />
      </Modal>

       {/* take user details if we don't have their email and name. */}
       <Modal
          title={'Kindly provide the needed information to complete your purchase'}
          centered
          open={showUserInfoModal}
          onOk={() => setShowUserInfoModal(false)}
          onCancel={() => setShowUserInfoModal(false)}
          footer={null}
        >
          <UserInfoForm 
            handleSuccess={() => {
              setShowUserInfoModal(false);
              handleSubmit();
            }} policyType={policy.policyType}/>
        </Modal>

        {/* login modal */}
        <Modal
          title=""
          centered
          open={showAuthModal}
          onOk={() => setShowAuthModal(true)}
          onCancel={() =>setShowAuthModal(true)}
          footer={null}
        >
          <LoginPopUp handleSuccess={() => {
            setShowAuthModal(false);
          }}/>
        </Modal>
    </Form>
  );
};

MotorBuyForm.propTypes = {
  policy: PropTypes.object,
  getPolicy: PropTypes.func,
};

export default withRouter(WithUser(MotorBuyForm));
