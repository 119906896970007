import React, { Component } from 'react';
import PropTypes from 'prop-types';

class GetUserName extends Component {
  state = {
    name: '',
    email: '',
  };

  handleFieldUpdate = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.handleSubmit(this.state);
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="mb-5rem">
          <p className="mb-2rem">Tell Us About Yourself</p>

          <div className="form__inputs">
            <div className="form__wrapper">
              <label className="form__label mt-1rem mb-1rem">Name</label>
              <input
                type="text"
                name="name"
                className="form__input"
                value={this.state.name}
                onChange={this.handleFieldUpdate}
                required
              />
            </div>

            <div className="form__wrapper">
              <label className="form__label mt-1rem mb-1rem">Email</label>
              <input
                type="email"
                className="form__input"
                name="email"
                value={this.state.email}
                onChange={this.handleFieldUpdate}
                required
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn--gradient-primary btn--width mt-5rem"
          >
            Show me the BestQuotes
          </button>
        </form>

        <small className="text--muted block">
          BestQuote uses these details to personalize your experience; we never
          share your details with third-parties.
        </small>
      </div>
    );
  }
}
GetUserName.propTypes = {
  handleSubmit: PropTypes.func,
};

export default GetUserName;
