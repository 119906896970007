import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Header from '../components/Header';
import coinImg from '../img/coin.svg';
import eyeImg from '../img/eye.svg';
import glassImg from '../img/magnifying-glass.svg';
import NotifyMeForm from './NotifyMeForm';

const ComingSoonPage = () => {
  return (
    <div>
      <Header noRibbon={true}>
        <div
          className="wrapper align--center mt-5rem"
          style={{ minHeight: '35vh' }}
        >
          {this.props.match.path === '/life' ? (
            <div>
              <h3 className="mb-2rem">
                Insurance Bigger than Life is Coming
              </h3>

              <p className="mb-2rem text--muted">
                Add your email and be the first to get notified when we launch
                our Life Insurance platform and access over 100+ top insurance
                products
              </p>
            </div>
          ) : (
            <div>
              <h3 className="mb-2rem">
                The Fastest Motor Insurance is Coming
              </h3>

              <p className="mb-2rem text--muted">
                Add your email and be the first to get notified when we launch
                our Motor Insurance platform and access over 100+ top
                insurance products
              </p>
            </div>
          )}

          <NotifyMeForm />

          <p className="mt-5rem">
            BestQuote is the right platform for you! Discover new policies for
            all your insurance needs
          </p>
          <Link to="/">
            <button className="btn btn--gradient-border mt-2rem">
              Discover Policies
            </button>
          </Link>
        </div>
      </Header>
      <div className="bq-banner">
        <ul className="bq-banner__items">
          <li className="bq-banner__item">
            <img src={glassImg} alt="" /> Find &amp; Compare Policies
          </li>
          <li className="bq-banner__item">
            <img src={eyeImg} alt="" /> View Policy Details
          </li>
          <li className="bq-banner__item">
            <img src={coinImg} alt="" /> Easily Pay for Policy
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withRouter(ComingSoonPage);
