import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { newUserPasswordUrl, successNotification } from '../../config';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const SetNewPasswordForm = props => {
  const [state, setState] = useState({
    reference: props.reference,
    password1: '',
    password2: '',
  });
  const [errorMessage, setErrorMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputUpdate = (stateName, event) => {
    setState({ ...state, [stateName]: event.target.value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsSubmitting(true);

    axios
      .post(newUserPasswordUrl, state)
      .then(() => {
        setIsSubmitting(false);
        // eslint-disable-next-line react/prop-types
        props.history.push('/auth/login');
        successNotification('Password set successfully, Login Now!');
      })
      .catch(error => {
        if (error.response && error.response.status < 500) {
          setErrorMessage({ ...error.response.data });

          if (error.response.data.message) {
            setErrorMessage({
              ...errorMessage,
              nonFieldErrors: [error.response.data.message],
            });
          }
        } else {
          setErrorMessage({
            ...errorMessage,
            nonFieldErrors: ['Oops! Something happened. Please try again.'],
          });
        }
        setIsSubmitting(false);
      });
  };

  return (
      <React.Fragment>
        <form className="mt-2rem" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12 mb-1rem">
            <label htmlFor="password" style={{ fontWeight: '700' }}>
                Password
              </label>
              <Input.Password
                className=""
                placeholder="Enter password"
                type="password"
                onChange={handleInputUpdate.bind(this, 'password1')}
                required
              />
              {errorMessage.password1
                ? errorMessage.password1.map((err, i) => (
                    <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
            </div>

            <div className="col-md-12 mt-2rem">
              <label htmlFor="password" style={{ fontWeight: '700' }}>
                Confirm Password
              </label>
              <Input.Password
                className=""
                placeholder="Confirm password"
                type="password"
                onChange={handleInputUpdate.bind(this, 'password2')}
                required
              />
              {errorMessage.password2
                ? errorMessage.password2.map((err, i) => (
                    <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
            </div>
          </div>
          <div>
            {errorMessage.nonFieldErrors
              ? errorMessage.nonFieldErrors.map((err, i) => (
                  <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
              ))
              : null}
          </div>

          <div className="form__submit mt-3rem">
            <button
              className="btn btn--gradient-primary btn--width-lng mt-2rem mb-1rem"
              type="submit"
            >
              {isSubmitting ? <LoadingOutlined /> : 'Set Password'}
            </button>
          </div>
        </form>
      </React.Fragment>
  );
};

SetNewPasswordForm.propTypes = {
  reference: PropTypes.string.isRequired,
};

export default withRouter(SetNewPasswordForm);
