import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from '../../../components/Header';
import LifeCompanySearchForm from './LifeCompanySearchForm';
import LifeCompanyPolicyCard from './LifeCompanyPolicyCard';

const LifeCompanySearch = () => {
  const [showDetail, setShowDetail] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Header></Header>

      <div className="wrapper mt-5rem mb-5rem">
        <div
          className="row reverse-md"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div className="col-md-7">
            <LifeCompanySearchForm setShowDetail={setShowDetail} />
          </div>
          {showDetail ? (
            <div className="col-md-5 mb-2rem">
              {/* <p className="mb-2rem">Policy Details</p> */}
              <LifeCompanyPolicyCard />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

LifeCompanySearch.propTypes = {
  search: PropTypes.object,
};

export default withRouter(LifeCompanySearch);
