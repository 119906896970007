import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getHeaders, OTPUrl, OTPVerificationUrl } from '../../config';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, InputNumber } from 'antd';
import WithUser from '../../hocs/WithUser';
import PropTypes from 'prop-types';

const PhoneNumberVerifyForm = props => {
  const { handleSuccess } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [showModal, setShowModal] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpCounter, setOtpCounter] = useState(60);
  const [otpCode, setOtpCode] = useState('');
  const [errorMessage, setErrorMessage] = useState({});
  const [numberErrorMessage, setNumberErrorMessage] = useState('');
  const [formSection, setFormSection] = useState(1);

  function handleInputChange(value) {
    setPhoneNumber('0' + value);

    // if a change is made on the form, clear the errors
    setNumberErrorMessage('');
    setErrorMessage({});
  }

  function handleOtpChange(value) {
    setOtpCode(value);
    clearInterval();

    // if a change is made on the form, clear the errors
    setNumberErrorMessage('');
    setErrorMessage({});
  }

  const countDown = () => {
    let myCounter = 60;
    setInterval(() => {
      if (myCounter <= 0) {
        clearInterval();
      } else {
        myCounter--;
        setOtpCounter(myCounter);
      }
    }, 1000);
  };
  
  const handleVerifyOTP = () => {
    clearInterval();
    if (!otpCode ) {
      setNumberErrorMessage('Kindly 6 digit code sent to your phone');

    } else {
      let data = {
        otp: `${otpCode}`,
        phoneNumber,
      };
      setIsSubmitting(true);
      axios 
        .post(OTPVerificationUrl, data, { headers: getHeaders() })
        .then(res => {
          // eslint-disable-next-line react/prop-types
          props.setUser({
            token: res.data.token,
            user: {
              ...res.data.user,
              phoneNumber,
            },
          });
          handleSuccess();
          setIsSubmitting(false);
          setNumberErrorMessage('');
          setErrorMessage({});
          setOtpCode('');
          setPhoneNumber('');
        })
        .catch(err => {
          setIsSubmitting(false);
          if (err.response) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            err.response.status === 400
              ? setErrorMessage({
                ...err.response.data,
                nonFieldErrors: ["Kindly make sure you've completed the form"],
              })
              : setErrorMessage({
                ...errorMessage,
                nonFieldErrors: ['Oops, server Error! Retry'],
              });
          } else {
            setErrorMessage({
              ...errorMessage,
              nonFieldErrors: ['Error Connecting to Server, Retry.'],
            });
          }
        });
    }
  };

  const handleSendOTP = () => {
    if (!phoneNumber || phoneNumber.length < 10 || phoneNumber.length > 10) {
      setNumberErrorMessage('Kindly input correct Phone Number');
    } else {
      let data = {
        phone_number : `${phoneNumber}`,
      };
      setIsSubmitting(true);

      axios 
        .post(OTPUrl, data, { headers: getHeaders() })
        .then(res => {
          setPhoneNumber(res.data.data.phoneNumber);
          setFormSection(2);
          setNumberErrorMessage('');
          setErrorMessage({});
          countDown();
          // this.scrollTo()
          setIsSubmitting(false);
        })
        .catch(err => {
          setIsSubmitting(false);
          if (err.response) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            err.response.status === 400
              ? setErrorMessage({
                ...err.response.data,
                nonFieldErrors: ["Kindly make sure you've completed the form"],
              })
              : setErrorMessage({
                ...errorMessage,
                nonFieldErrors: ['Oops, server Error! Retry'],
              });
          } else {
            setErrorMessage({
              ...errorMessage,
              nonFieldErrors: ['Error Connecting to Server, Retry.'],
            });
          }
        });
    }
    
  };

  // const handleLogin = () => {
  //   props.history.push({
  //     pathname: '/auth/login',
  //     state: { from: { pathname: '/buy-motor-insurance/compare' } }
  //   })
  // }

  useEffect(() => {
  }, []);

  return (
    <Form
      layout="vertical"
      size="large"
      className="text-center"
    >
      {formSection === 1 ? (
          <>
          <div className="row">
            <div className="col-md-12">
              <p>Verify Phone number</p>
              <InputNumber 
                addonBefore="+233"
                onChange={handleInputChange}
                placeholder="eg. 241234567"
                value={phoneNumber}
                className='modal-input-number w-75'
              />
              {numberErrorMessage
                ? (
                    <p style={{ color: 'red', marginTop: '1em' }}>{numberErrorMessage}</p>
                )
                : null}
              {errorMessage.nonFieldErrors
                ? errorMessage.nonFieldErrors.map((err, i) => (
                    <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
            </div>
          </div>

          <div className='row mt-4 mx-0 text-center'>
          <div className={'w-100'}>
              <button className="btn_normal btn_gradient btn_md" 
                onClick={
                  () => handleSendOTP()
                }
              >
                {isSubmitting ? (
                  <LoadingOutlined />
                ) : ('Send 6 digit code via SMS')}
              </button>
          </div>
        </div>

        <div className='row mt-4 mx-0 text-center'>
              <div className="w-100 bg-light p-3 d-flex justify-content-center align-items-center">
                <p className='m-0 p-3 text-green-950'>Already have an account on BestQuote?</p>
                <button 
                  className="btn_normal btn_dark btn_sm" type="submit"
                  onClick={() => props.handleAuth()}
                >
                  Login Now
                </button>
              </div>
            </div>
</>
      ) : null}

      {formSection === 2 ? (
          <div>
          <div className="row">
            <div className="col-md-12">
              <p>Kindly enter 6 digit code we sent via SMS</p>
              <InputNumber
                className='modal-input-number w-75'
                placeholder="eg. 123456"
                onChange={handleOtpChange}
                value={otpCode}
                required={true}
              />
              {numberErrorMessage
                ? (
                    <p style={{ color: 'red', marginTop: '1em' }}>{numberErrorMessage}</p>
                )
                : null}

              {errorMessage.nonFieldErrors
                ? errorMessage.nonFieldErrors.map((err, i) => (
                    <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
            </div>
          </div>

          <div className='row mt-4 mx-0 text-center'>
          <div className={'w-100 px-5 mx-5'}>
                      <div className='d-flex justify-content-between px-5'>
                        <button
                          className="btn_normal btn_md"
                          type="button"
                          onClick={() =>
                            setFormSection(1)
                          }
                        >
                          Change my number
                        </button>
                        <button className="btn_normal btn_gradient btn_md" 
                          onClick={
                            () => handleVerifyOTP()
                          }
                        >
                          {isSubmitting ? (
                            <LoadingOutlined />
                          ) : ('Verify')}
                        </button>
                      </div>
          </div>
        </div>

        <div className='row mt-4 mx-0 text-center'>
        <div className="w-100 bg-light p-3 d-flex justify-content-center align-items-center">
          { otpCounter > 1 ? (
          <p className='m-0 p-3'>You should receive a 6 digit OTP on your Phone Number in <span className='font-weight-bold'>{otpCounter} seconds</span>.</p>
          ) : (
                <>
                <p className='m-0 p-3'>Did not receive SMS ?</p>
                <button 
                  className="btn--custom-verify" 
                  onClick={
                      () => handleSendOTP()
                    }
                >
                  {isSubmitting ? (
                    <LoadingOutlined />
                  ) : ('Resend SMS')}
                </button>
                </>
          )}
          </div>
          </div>
        
        </div>
      ) : null}
    </Form>
  );
};

PhoneNumberVerifyForm.propTypes = {
  handleSuccess: PropTypes.func,
  handleAuth: PropTypes.func.isRequired,
};

PhoneNumberVerifyForm.defaultProps = {
  handleSuccess: () => {},
  handleAuth: () => {},
};

export default WithUser(PhoneNumberVerifyForm);
