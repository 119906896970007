import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';

class RequestForm extends Component {
  state = {
    name: '',
    phoneNumber: '',
    email: '',
    category: '',
    message: '',
  };

  handleRequestType = selectedOption => {
    this.setState({ category: selectedOption.value });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API_BASE}/api/v1/messages/`;
    const headers = { 'Content-Type': 'application/json' };
    const { name, email, phoneNumber, category, message } = this.state;
    const payload = { name, email, phoneNumber, category, message };

    axios
      .post(url, payload, { headers })
      .then(() => {})
      .catch(() => {});
  };

  render() {
    const requestOptions = [
      { label: 'Make a Claim on Policy', value: 'CLAIM' },
      { label: 'Cancel Policy', value: 'CANCEL' },
    ];

    const { phoneNumber, name, email, message, category } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className="form">
        <div className="form__inputs mb-2rem">
          <div className="form__wrapper">
            <label className="form__label mt-1rem mb-1rem">Full Name*</label>
            <input
              className="form__input"
              name="name"
              value={name}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form__wrapper">
            <label className="form__label mt-1rem mb-1rem">Phone number</label>
            <input
              className="form__input"
              name="phoneNumber"
              value={phoneNumber}
              onChange={this.handleChange}
              required
            />
          </div>
        </div>

        <div className="form__inputs mb-2rem">
          <div className="form__wrapper">
            <label className="form__label mt-1rem mb-1rem">Email address</label>
            <input
              className="form__input"
              name="email"
              value={email}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form__wrapper">
            <label className="form__label mt-1rem mb-1rem">
              Nature of request
            </label>
            <Select
              required
              value={category}
              onChange={this.handleRequestType}
              options={requestOptions}
            />
          </div>
        </div>

        <div className="form__inputs mb-2rem">
          <div className="form__wrapper">
            <label className="form__label mt-1rem mb-1rem">Message</label>
            <textarea
              className="form__textarea"
              name="message"
              value={message}
              onChange={this.handleChange}
              required
            />
          </div>
        </div>

        <button
          type="submit"
          className="btn btn--gradient-primary btn--width-lng mt-3rem"
        >
          Send Message
        </button>
      </form>
    );
  }
}

export default RequestForm;
