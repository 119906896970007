import Navbar from '../../components/Navbar';
import React, { useEffect, useState } from 'react';
import GetHelpCard from '../../components/Cards/GetHelpCard';
import { getHeaders, motorUrl, errorMessage } from '../../config';
import axios from 'axios';
import WithProducts from '../../hocs/WithProducts';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import WithUser from '../../hocs/WithUser';
import carIcon from '../../img/motor/city_driver.svg';
import helpImg from '../../img/get_help_image.png';
import helpImgMobile from '../../img/get_help_image_mobile.png';

import PolicyCard from '../../components/Cards/PolicyCard';
import PhoneNumberPopUp from '../../components/Auth/PhoneNumberVerifyForm';
import LoginPopUp from '../../components/Auth/LoginPopUp';
import { trackEvents } from '../../utils/gaTrack';

const MotorCompare = props => {
  const [policies, setPolicies] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [policyExist, setPolicyExist] = useState(false);
  const [showPhoneNumberModal, setShowPhoneNumberModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleBuy = policy => {
    // eslint-disable-next-line react/prop-types
    if (props.authUser.token) {
      setIsSubmitting(true);
      let data = {
        ...policy.payload,
        companyId: policy.companyId,
      };
 
      axios
        .post(motorUrl, data, { headers: getHeaders() })
        .then(res => {
          let id = res.data.results.id;
          // eslint-disable-next-line react/prop-types
          props.history.push(`/buy-motor-insurance/${id}/buy`);
          trackEvents(
            'Motor', 
            'Chose a policy',  
            // eslint-disable-next-line react/prop-types
            props.authUser.user.phoneNumber ? props.authUser.user.phoneNumber : 'Anonymous User',
          );
          setIsSubmitting(false);
        })
        .catch(err => {
          setIsSubmitting(false);
          if (err.response) {
            errorMessage('Oops! Something went wrong, kindly retry.');
          } else {
            errorMessage(
              'Error connecting, kindly check your internet connection.',
            );
          }
        });
    } else {
      setShowPhoneNumberModal(true);
    }
  };

  const formatProducts = products => {
    let prods = [];

    products.forEach((p, i) => {
      let { finalPremium, ncdAmount } = p.discount;
      prods.push({
        isPolicy: true,
        payload: p.payload,
        premium: ncdAmount
          ? {
            currency: p.policy.premium.currency,
            amount: finalPremium,
          }
          : {
            currency: p.policy.premium.currency,
            amount: p.policy.premium.amount,
          },
        discount: ncdAmount
          ? {
            currency: p.policy.premium.currency,
            amount: ncdAmount,
          }
          : null,
        companyLogo: p.company.logoUrl,
        companyName: p.company.name,
        companyId: p.company.id,
        vehicle: p.payload.vehicles[0],
        compare: false,
        showDetail: false,
      });

      if (i === 3) {
        prods.push({
          isPolicy: false,
        });
      }
    });

    return prods;
  };

  const getMotorProducts = payload => {
    axios
      .post(`${motorUrl}/preview`, payload, { headers: getHeaders() })
      .then(res => {
        // eslint-disable-next-line react/prop-types
        props.setMotorProducts(res.data.results);
        setPolicies(formatProducts(res.data.results));
        // setPolicyExist(true);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (props.motorProducts.length) {
      // eslint-disable-next-line react/prop-types
      setPolicies(formatProducts(props.motorProducts));
      // setPolicyExist(true);
    } else {
      // eslint-disable-next-line react/prop-types
      getMotorProducts(props.motorPayload);
    }

    // eslint-disable-next-line react/prop-types
    if (!props.authUser.token) {
      setShowPhoneNumberModal(true);
    }
    
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <Navbar />
      <div className="motor_compare">
        <div className="motor_compare_header">
          {policies.length ? (
            <h2>
              {"You're viewing"}{' '}
              <span style={{ color: '#009B9C' }}>Car Insurance</span> premiums
              for
            </h2>
          ) : (
            <p>
              Please provide information about your car{' '}
              <Link to="/buy-motor-insurance">here</Link>
            </p>
          )}
        </div>

        {policies.length ? (
          <>
            <div className="motor_compare_card">
              <img src={carIcon} alt="compare card" />
              <div className="motor_compare_card_caption">
                <h3>{`${policies[0].vehicle.manufactureYear} ${policies[0].vehicle.make}`}</h3>
                <p>
                  <span>
                    Car Reg. #: {policies[0].vehicle.registrationNumber}
                  </span>
                </p>

                <p
                  style={{
                    display: 'inline-flex',
                    backgroundColor: '#3f3d56',
                    padding: '0.5rem 1rem',
                    float: 'right',
                    marginTop: '2rem',
                    borderRadius: '5px',
                    fontWeight: '700',
                    cursor: 'pointer',
                  }}
                >
                  <Link to="/buy-motor-insurance">
                    <span style={{ color: '#f2f2f2' }}>Edit Car Details</span>
                  </Link>
                </p>
              </div>
            </div>

            <div className="motor_compare_policies">
              {/* <div className="motor_compare_policies_warning">
                <img src={noticeIcon} alt="notice" />
                <p>Click on the compare button to start comparing</p>
              </div> */}

              <div className="motor_compare_policies_list">
                {policies.map((policy, i) =>
                  policy.isPolicy ? (
                    <div key={i} className="motor_compare_policies_list_card mb-3rem">
                      <PolicyCard
                        policy={policy}
                        handleBuy={handleBuy.bind(this, policy)}
                        isSubmitting={isSubmitting}
                      />
                    </div>
                  ) : (
                    <div key={i} className="motor_compare_policies_list_help mb-3rem">
                      <GetHelpCard image={helpImg} />
                    </div>
                  ),
                )}

                {policies.map((policy, i) =>
                  policy.isPolicy ? (
                    <div key={i} className="motor_compare_policies_list_card_mobile">
                      <PolicyCard
                        policy={policy}
                        handleBuy={handleBuy.bind(this, policy)}
                        isSubmitting={isSubmitting}
                      />
                    </div> 
                  ) : (
                    <div key={i} className="motor_compare_policies_list_help_mobile">
                      <GetHelpCard image={helpImgMobile} />
                    </div>
                  ),
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>

      {policies.length ? (
        <Modal
          title="Discounts, Exclusive offers and Important updates"
          centered
          closable={false}
          maskClosable={false}
          wrapClassName="blurModalBg"
          open={showPhoneNumberModal}
          onOk={() => setShowPhoneNumberModal(false)}
          onCancel={() => setShowPhoneNumberModal(false)}
          footer={null}
        >
          <div className='text-center'>
            <PhoneNumberPopUp handleSuccess={() => setShowPhoneNumberModal(false)} handleAuth={() => {
              setShowLoginModal(true);
              setShowPhoneNumberModal(false);
            }} />
          </div>
        </Modal>
      ) : null}

        <Modal
          title=""
          centered
          open={showLoginModal}
          onOk={() => setShowPhoneNumberModal(true)}
          onCancel={() => {
            setShowPhoneNumberModal(true);
            setShowLoginModal(false);
          }}
          footer={null}
        >
          <LoginPopUp handleSuccess={() => {
            setShowPhoneNumberModal(false);
            setShowLoginModal(false);
          }}/>
        </Modal>
    </React.Fragment>
  );
};

export default WithProducts(WithUser(MotorCompare));
