import react from 'react'

import { Space, Spin, ConfigProvider } from 'antd';

export const FadeLoader = () => (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#13d1cf',
        borderRadius: 2,
        colorBgContainer: '#ffffff',
      },
    }}
  >
    <Spin size="large" />
  </ConfigProvider>
);
