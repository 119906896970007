import React from 'react';
import Helmet from 'react-helmet';

// eslint-disable-next-line react/prop-types
const Meta = ({ children }) => {
  return (
    <main>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>
          Get the Best Quote on Insurance | Compare and Buy Cheap insurance
        </title>
        <meta
          name="keywords"
          content="Best Insurance Purchase in Ghana, Buy Insurance Online, Insurance Policies Online, Travel Insurance in Ghana, Car Insurance in Ghana, Home Insurance in Ghana."
        />
        <meta
          name="description"
          content="BestQuote is an online insurance comparison platform which enables
    you to find the best insurance policies in Ghana. Find, compare and purchase travel, Car and Home insurance policies in Ghana."
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemProp="name" content="BestQuote" />
        <meta
          itemProp="keywords"
          content="Best Insurance Purchase in Ghana, Buy Insurance Online, Insurance Policies Online, Travel Insurance in Ghana, Car Insurance in Ghana, Home Insurance in Ghana."
        />
        <meta
          itemProp="description"
          content="BestQuote is an online insurance comparison platform which enables
    you to find the best insurance policies in Ghana. Find, compare and purchase travel, Car and Home insurance policies in Ghana."
        />
        <meta
          itemProp="image"
          content="https://www.mybestquote.com/static/media/bestquote-logo.1c2df910.png"
        />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.mybestquote.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="BestQuote" />
        <meta
          property="og:keywords"
          content="Best Insurance Purchase in Ghana, Buy Insurance Online, Insurance Policies Online, Travel Insurance in Ghana, Car Insurance in Ghana, Home Insurance in Ghana."
        />
        <meta
          property="og:description"
          content="BestQuote is an online insurance comparison platform which enables
    you to find the best insurance policies in Ghana. Find, compare and purchase Travel, Car and Home insurance policies in Ghana."
        />
        <meta
          property="og:image"
          content="https://www.mybestquote.com/static/media/bestquote-logo.1c2df910.png"
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="BestQuote" />
        <meta
          name="twitter:keywords"
          content="Best Insurance Purchase in Ghana, Buy Insurance Online, Insurance Policies Online, Travel Insurance in Ghana, Car Insurance in Ghana, Home Insurance in Ghana."
        />
        <meta
          name="twitter:description"
          content="BestQuote is an online insurance comparison platform which enables
    you to find the best insurance policies in Ghana. Find, compare and purchase Travel, Car and Home insurance policies in Ghana."
        />
        <meta
          name="twitter:image"
          content="https://www.mybestquote.com/static/media/bestquote-logo.1c2df910.png"
        />
      </Helmet>
      <div>{children}</div>
    </main>
  );
};
export default Meta;
