import React from 'react';

import Navbar from '../components/Navbar';
import RequestForm from './RequestForm';
import location from '../img/contact-location.svg';
import mail from '../img/contact-mail.svg';
import phone from '../img/contact-phone.svg';

const ContactPage = () => {
  return (
    <div>
      <Navbar />
      <main className="site-content mb-5rem">
        <div className="wrapper mt-5rem mb-3rem">
          <h3 className="align--left site-content__lead">Submit a Request</h3>
        </div>
        <div className="wrapper flex">
          <div
            className="align--left mb-5rem"
            style={{ marginRight: '15rem' }}
          >
            <p className="mb-5rem">
              Get in touch and let us know how we can help!
            </p>
            <RequestForm />
          </div>
          <div className="align--left">
            <p className="mb-6rem">{"Here's how you can reach us"}</p>

            <label className="label mt-2rem mb-1rem">Location</label>
            <div className="flex-row">
              <img src={location} className="contact-icon mr-1rem" alt="" />
              <div>
                <p>The Victoria, No. 3 First Norla Street</p>
                <p className="mb-4rem">Labone, Accra - Ghana </p>
              </div>
            </div>

            <label className="label mt-1rem mb-1rem">Email Address</label>
            <div className="flex-row">
              <img src={mail} className="contact-icon mr-1rem" alt="" />
              <p className="mb-4rem">support@mybestquote.com</p>
            </div>

            <label className="label mt-1rem mb-1rem">Phone number</label>
            <div className="flex-row">
              <img src={phone} className="contact-icon mr-1rem" alt="" />
              <p>+233 302 904 929</p>
            </div>
          </div>
        </div>
        <div className="mt-5rem mb-5rem" style={{ width: '100%' }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.966503115685!2d-0.1770475853331689!3d5.571970795960233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9a9a79124d45%3A0xe30937b55ccabc21!2sThe+Victoria!5e0!3m2!1sen!2sgh!4v1531910454797"
            width="100%"
            height="400"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            title="The Victoria"
          />
        </div>
      </main>
    </div>
  );
};


export default ContactPage;
