import Navbar from '../../components/Navbar'
import React, { useEffect, useState } from 'react'
import PolicyCard from '../../components/Cards/PolicyCard'
import GetHelpCard from '../../components/Cards/GetHelpCard'
import { getHeaders, errorMessage, healthUrl } from '../../config'
import axios from 'axios'
import WithProducts from '../../hocs/WithProducts'
import { Link } from 'react-router-dom'
import WithUser from '../../hocs/WithUser'
import carIcon from '../../img/motor/city_driver.svg'
import helpImg from '../../img/get_help_image.png'
import helpImgMobile from '../../img/get_help_image_mobile.png'

const HealthCompare = props => {
  const [policies, setPolicies] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleBuy = policy => {
    if (props.authUser.token) {
      setIsSubmitting(true)
      let data = {
        ...policy.payload,
        companyId: policy.companyId,
        healthPlan: policy.healthPlan
      }

      axios
        .post(healthUrl, data, { headers: getHeaders() })
        .then(res => {
          if ([200, 201].includes(res.status)) {
            let id = res.data.data.id
            props.history.push({
              pathname: `/payments/new/${id}`,
              state: { id, type: 'health' }
            })
          }
          setIsSubmitting(false)
        })
        .catch(err => {
          setIsSubmitting(false)
          if (err.response) {
            errorMessage('Oops! Something went wrong, kindly retry.')
          } else {
            errorMessage(
              'Error connecting, kindly check your internet connection.'
            )
          }
        })
    } else {
      props.history.push({
        pathname: '/auth/login',
        state: { from: { pathname: '/buy-health-insurance/compare' } }
      })
    }
  }

  const formatProducts = products => {
    let prods = []

    products.forEach((p, i) => {
      prods.push({
        isPolicy: true,
        payload: p.payload,
        premium: {
          currency: p.preview.premium.currency,
          amount: p.preview.premium.amount
        },
        companyLogo: p.company.logoUrl,
        companyName: p.company.name,
        companyId: p.company.id,
        healthPlan: p.preview.id,
        compare: false,
        showDetail: false
      })

      if (i === 3) {
        prods.push({
          isPolicy: false
        })
      }
    })

    return prods
  }

  useEffect(() => {
    props.healthProducts && setPolicies(formatProducts(props.healthProducts))
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <Navbar />
      <div className="motor_compare">
        <div className="motor_compare_header">
          {policies.length ? (
            <h2>
              You're viewing{' '}
              <span style={{ color: '#009B9C' }}>Health Insurance</span>{' '}
              premiums for
            </h2>
          ) : (
            <p>
              Please provide information about your health{' '}
              <Link to="/buy-health-insurance">here</Link>
            </p>
          )}
        </div>

        {policies.length ? (
          <>
            <div className="motor_compare_card">
              <img src={carIcon} alt="compare card" />
              <div className="motor_compare_card_caption">
                <h3>{`Family of ${policies[0].payload.familySize}`}</h3>

                <p
                  style={{
                    display: 'inline-flex',
                    backgroundColor: '#3f3d56',
                    padding: '0.5rem 1rem',
                    float: 'right',
                    marginTop: '2rem',
                    borderRadius: '5px',
                    fontWeight: '700',
                    cursor: 'pointer'
                  }}
                >
                  <Link to="/buy-health-insurance">
                    <span style={{ color: '#f2f2f2' }}>Edit Details</span>
                  </Link>
                </p>
              </div>
            </div>

            <div className="motor_compare_policies">
              {/* <div className="motor_compare_policies_warning">
                <img src={noticeIcon} alt="notice" />
                <p>Click on the compare button to start comparing</p>
              </div> */}

              <div className="motor_compare_policies_list">
                {policies.map(policy =>
                  policy.isPolicy ? (
                    <div className="motor_compare_policies_list_card mb-3rem">
                      <PolicyCard
                        policy={policy}
                        handleBuy={handleBuy.bind(this, policy)}
                        isSubmitting={isSubmitting}
                      />
                    </div>
                  ) : (
                    <div className="motor_compare_policies_list_help mb-3rem">
                      <GetHelpCard image={helpImg} />
                    </div>
                  )
                )}

                {policies.map(policy =>
                  policy.isPolicy ? (
                    <div className="motor_compare_policies_list_card_mobile mb-3rem">
                      <PolicyCard
                        policy={policy}
                        handleBuy={handleBuy.bind(this, policy)}
                        isSubmitting={isSubmitting}
                      />
                    </div>
                  ) : (
                    <div className="motor_compare_policies_list_help_mobile mb-3rem">
                      <GetHelpCard image={helpImgMobile} />
                    </div>
                  )
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </React.Fragment>
  )
}

export default WithProducts(WithUser(HealthCompare))
