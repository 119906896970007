import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { FadeLoader } from '../../components/UI/FadeLoader';

import WithUser from '../../hocs/WithUser';
import UserPolicyCard from './travel/UserPolicyCard';
import MotorUserPolicyCard from './motor/MotorUserPolicyCard';
import ProductBreakdown from '../../components/Travel/TravelProductBreakdown';
import MotorProductBreakdown from '../../components/Motor/MotorProductBreakdown';
import PropertyProductBreakdown from '../property/PropertyProductBreakdown';
import PropertyUserPolicyCard from './property/PropertyUserPolicyCard';
import HealthProductBreakdown from '../health-insurance/HealthProductBreakdown';
import HealthUserPolicyCard from './health/HealthUserPolicyCard';
import LifeUserPolicyCard from './life/LifeUserPolicyCard';
import LifeProductBreakdown from '../life-insurance/LifeProductBreakdown';
import {
  getHeaders,
  healthUrl,
  lifeUrl,
  motorUrl,
  propertyUrl,
  travelUrl,
} from '../../config';

// travel policy overview
class Travel extends Component {
  render() {
    let { policy } = this.props;

    return (
      <div className="user-dash__main">
        {policy ? (
          <div>
            <p className="site-content__lead">{policy.product.name}</p>
            <p className="text--muted mb-2rem">{policy.tripDescription}</p>

            <hr />

            <div className="mt-5rem"></div>

            <div className="row">
              <div className="col-md-7 mb-5rem">
                <UserPolicyCard policy={policy} />

                <div className="flex">
                  {policy.status === 'U' ? (
                    <span className="mr-1rem mb-1rem">
                      <Link
                        to={{
                          pathname: `/payments/new/${policy.id}`,
                          state: { id: policy.id, type: 'travel' },
                        }}
                        className="navbar__link"
                      >
                        <button className="btn btn--width-sm btn--gradient-primary">
                          Pay
                        </button>
                      </Link>
                    </span>
                  ) : null}

                  {policy.status !== 'U' ? (
                    <span className="mr-1rem mb-1rem">
                      <Link
                        to={`/account/claims/travel/new/${policy.id}`}
                        className="navbar__link"
                      >
                        <button className="btn btn--width-sm btn--gradient-primary">
                          Claim
                        </button>
                      </Link>
                    </span>
                  ) : null}

                  {policy.status !== 'U' ? (
                    <span className="mr-1rem mb-1rem">
                      <Link
                        to={`/account/cancellations/new/${policy.id}`}
                        className="navbar__link"
                      >
                        <button className="btn btn--width-sm btn--gradient-primary">
                          Cancel
                        </button>
                      </Link>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-5">
                <ProductBreakdown
                  product={policy.product}
                  key={policy.id}
                  isPurchasing={true}
                  premium={policy.premium}
                  // travellerAges={this.state.travellerAges}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="site-content mb-10rem">
            <FadeLoader />
          </div>
        )}
      </div>
    );
  }
}
Travel.propTypes = {
  policy: PropTypes.shape({
    product: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string,
    premium: PropTypes.number,
    id: PropTypes.number,
    tripDescription: PropTypes.shape({}),
  }),
};
// end travel overview

// motor overview
class Motor extends Component {
  render() {
    let { policy } = this.props;

    return (
      <div className="user-dash__main">
        {policy ? (
          <div>
            {/* <p className="site-content__lead">{policy.product.name}</p>
            <p className="text--muted mb-2rem">{policy.tripDescription}</p> */}

            <hr />

            <div className="mt-5rem"></div>

            <div className="row">
              <div className="col-md-7 mb-5rem">
                <MotorUserPolicyCard policy={policy} />

                <div className="flex">
                  {policy.status === 'U' ? (
                    <span className="mr-1rem mb-1rem">
                      <Link
                        to={{
                          pathname: `/payments/new/${policy.id}`,
                          state: { id: policy.id, type: 'motor' },
                        }}
                        className="navbar__link"
                      >
                        <button className="btn btn--width-sm btn--gradient-primary">
                          Pay
                        </button>
                      </Link>
                    </span>
                  ) : null}
                  {policy.status !== 'U' ? (
                    <span className="mr-1rem mb-1rem">
                      <Link
                        to={`/account/claims/motor/new/${policy.id}`}
                        className="navbar__link"
                      >
                        <button className="btn btn--width-sm btn--gradient-primary">
                          Claim
                        </button>
                      </Link>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-5">
                <MotorProductBreakdown
                  product={policy}
                  premium={policy.premium}
                  isPurchasing={true}
                  // travellerAges={this.state.travellerAges}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="site-content mb-10rem">
            <FadeLoader />
          </div>
        )}
      </div>
    );
  }
}
Motor.propTypes = {
  policy: PropTypes.shape({
    product: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string,
    premium: PropTypes.number,
    id: PropTypes.number,
    tripDescription: PropTypes.shape({}),
  }),
};
// end overview

// health overview
class Health extends Component {
  render() {
    let { policy } = this.props;

    return (
      <div className="user-dash__main">
        {policy ? (
          <div>
            {/* <p className="site-content__lead">{policy.product.name}</p>
            <p className="text--muted mb-2rem">{policy.tripDescription}</p> */}

            <hr />

            <div className="mt-5rem"></div>

            <div className="row">
              <div className="col-md-7 mb-5rem">
                <HealthUserPolicyCard policy={policy} />

                <div className="flex">
                  {policy.status === 'U' ? (
                    <span className="mr-1rem mb-1rem">
                      <Link
                        to={{
                          pathname: `/payments/new/${policy.id}`,
                          state: { id: policy.id, type: 'health' },
                        }}
                        className="navbar__link"
                      >
                        <button className="btn btn--width-lg btn--gradient-primary">
                          Pay Now
                        </button>
                      </Link>
                    </span>
                  ) : null}
                  {policy.status !== 'U' ? (
                    <span className="mr-1rem mb-1rem">
                      <Link
                        to={`/account/claims/health/new/${policy.id}`}
                        className="navbar__link"
                      >
                        <button className="btn btn--width-lg btn--gradient-primary">
                          Make a Claim
                        </button>
                      </Link>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-5">
                <HealthProductBreakdown
                  product={policy}
                  premium={policy.premium}
                  isPurchasing={true}
                  // travellerAges={this.state.travellerAges}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="site-content mb-10rem">
            <FadeLoader />
          </div>
        )}
      </div>
    );
  }
}
Health.propTypes = {
  policy: PropTypes.shape({
    product: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string,
    premium: PropTypes.number,
    id: PropTypes.number,
    tripDescription: PropTypes.shape({}),
  }),
};
// end overview

// health overview
class Life extends Component {
  render() {
    let { policy } = this.props;

    return (
      <div className="user-dash__main">
        {policy ? (
          <div>
            {/* <p className="site-content__lead">{policy.product.name}</p>
            <p className="text--muted mb-2rem">{policy.tripDescription}</p> */}

            <hr />

            <div className="mt-5rem"></div>

            <div className="row">
              <div className="col-md-7 mb-5rem">
                <LifeUserPolicyCard policy={policy} />

                <div className="flex">
                  {policy.status === 'U' ? (
                    <span className="mr-1rem mb-1rem">
                      <Link
                        to={{
                          pathname: `/payments/new/${policy.id}`,
                          state: { id: policy.id, type: 'life' },
                        }}
                        className="navbar__link"
                      >
                        <button className="btn btn--width-lg btn--gradient-primary">
                          Pay Now
                        </button>
                      </Link>
                    </span>
                  ) : null}
                  {policy.status !== 'U' ? (
                    <span className="mr-1rem mb-1rem">
                      <Link
                        to={`/account/claims/life/new/${policy.id}`}
                        className="navbar__link"
                      >
                        <button className="btn btn--width-lg btn--gradient-primary">
                          Make a Claim
                        </button>
                      </Link>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-5">
                <LifeProductBreakdown
                  product={policy}
                  premium={policy.premium}
                  isPurchasing={true}
                  // travellerAges={this.state.travellerAges}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="site-content mb-10rem">
            <FadeLoader />
          </div>
        )}
      </div>
    );
  }
}
Life.propTypes = {
  policy: PropTypes.shape({
    product: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string,
    premium: PropTypes.number,
    id: PropTypes.number,
    tripDescription: PropTypes.shape({}),
  }),
};
// end overview

// property overview
class Property extends Component {
  render() {
    let { policy } = this.props;

    return (
      <div className="user-dash__main">
        {policy ? (
          <div>
            {/* <p className="site-content__lead">{policy.product.name}</p>
            <p className="text--muted mb-2rem">{policy.tripDescription}</p> */}

            <hr />

            <div className="mt-5rem"></div>

            <div className="row">
              <div className="col-md-7 mb-5rem">
                <PropertyUserPolicyCard policy={policy} />

                <div className="flex">
                  {policy.status === 'U' ? (
                    <span className="mr-1rem mb-1rem">
                      <Link
                        to={{
                          pathname: `/payments/new/${policy.id}`,
                          state: { id: policy.id, type: 'property' },
                        }}
                        className="navbar__link"
                      >
                        <button className="btn btn--width-sm btn--gradient-primary">
                          Pay
                        </button>
                      </Link>
                    </span>
                  ) : null}

                  {policy.status !== 'U' ? (
                    <span className="mr-1rem mb-1rem">
                      <Link
                        to={`/account/claims/property/new/${policy.id}`}
                        className="navbar__link"
                      >
                        <button className="btn btn--width-sm btn--gradient-primary">
                          Claim
                        </button>
                      </Link>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-5">
                <PropertyProductBreakdown
                  product={policy}
                  premium={policy.premium}
                  isPurchasing={true}
                  // travellerAges={this.state.travellerAges}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="site-content mb-10rem">
            <FadeLoader />
          </div>
        )}
      </div>
    );
  }
}

Property.propTypes = {
  policy: PropTypes.shape({
    product: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string,
    premium: PropTypes.number,
    id: PropTypes.number,
    tripDescription: PropTypes.shape({}),
  }),
};
// end property overview

class PolicyDetail extends Component {
  state = {
    policy: null,
  };

  isMotor = this.props.match.path.split('/').includes('motor');

  isTravel = this.props.match.path.split('/').includes('travel');

  isProperty = this.props.match.path.split('/').includes('property');

  isHealth = this.props.match.path.split('/').includes('health');

  isLife = this.props.match.path.split('/').includes('life');

  componentDidMount() {
    let URL = `${travelUrl}/${this.props.match.params.policyId}/`;

    if (this.isMotor) {
      URL = `${motorUrl}/${this.props.match.params.policyId}/`;
    }

    if (this.isProperty) {
      URL = `${propertyUrl}/${this.props.match.params.policyId}/`;
    }

    if (this.isHealth) {
      URL = `${healthUrl}/${this.props.match.params.policyId}/`;
    }

    if (this.isLife) {
      URL = `${lifeUrl}/${this.props.match.params.policyId}/`;
    }

    axios
      .get(URL, {
        headers: getHeaders(),
      })
      .then(res => {
        this.setState({ policy: res.data });
      });
  }

  render() {
    let policy = this.state.policy;
    return (
      <div className="column" style={{ width: '100%' }}>
        <div>{this.isTravel && <Travel policy={policy} />}</div>
        <div>{this.isMotor && <Motor policy={policy} />}</div>
        <div>{this.isProperty && <Property policy={policy} />}</div>
        <div>{this.isHealth && <Health policy={policy} />}</div>
        <div>{this.isLife && <Life policy={policy} />}</div>

        <p className="mt-10rem">
          <span className="mr-2rem">
            {"Need Help? Do reach out to us and we'd gladly resolve any issues or concerns."}
          </span>
          <button className="btn btn--gradient-primary btn--width-md">
            Contact Us
          </button>
        </p>
      </div>
    );
  }
}
PolicyDetail.propTypes = {
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
  match: PropTypes.object,
};

export default withRouter(WithUser(PolicyDetail));
