import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BasicTooltip from './BasicTooltip';
import { Input, Form } from 'antd';

const BasicInput = props => {
  const [showToolTip, setShowToolTip] = useState(false);
  const {
    toolTipLabel,
    inputType,
    label,
    name,
    value,
    required,
    disabled,
    handleChange,
    placeholder,
    className,
    style,
  } = props;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    toolTipLabel && setShowToolTip(true);
  }, [toolTipLabel]);

  return (
    <React.Fragment>
      {/* <label for={name}>
          {props.label}
          {showToolTip ? <BasicTooltip label={props.toolTipLabel} /> : null}
        </label> */}

      <Form.Item
        name={name}
        label={
          <>
            {label ? label : ''}{' '}
            {showToolTip ? (
              <>
                <BasicTooltip label={toolTipLabel} />
              </>
            ) : (
              ''
            )}
          </>
        }
        initialValue={value}
        rules={[
          {
            required: required,
          },
        ]}
        colon={false}
      >
        <Input
          type={inputType}
          className={`form_input_muted ${className}`}
          id={name}
          value={value}
          defaultValue={value}
          onChange={handleChange}
          name={name}
          placeholder={placeholder}
          style={{ ...style, width: '100%' }}
          disabled={disabled ? true : false}
          size="middle"
          // required={required}
        />
      </Form.Item>
    </React.Fragment>
  );
};

BasicInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  inputType: PropTypes.string,
  value: PropTypes.any,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  toolTipLabel: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

export default BasicInput;
