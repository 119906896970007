import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { FadeLoader } from '../../components/UI/FadeLoader';
import { connect } from 'react-redux';
import countryList from 'react-select-country-list';
import PropTypes from 'prop-types';
import { getHeaders, productsUrl } from '../../config';

import Header from '../../components/Header';
import TravelBuyForm from '../../components/Travel/TravelBuyForm';
import ProductBreakdown from '../../components/Travel/TravelProductBreakdown';
import { formatProducts, getPremiumFromAges } from '../../utils/products';

class TravelBuy extends Component {
  state = {
    product: null,
    premium: null,
    search: null,
    travellerAges: [],
  };

  componentDidMount() {
    let search = this.props.search;

    if (search.id > 0) {
      let searchCountries = search.countries.map(cou => {
        if (typeof cou === 'object') {
          return {
            value: cou.value,
            label: cou.label,
          };
        } else {
          return {
            value: cou.toString().toLowerCase(),
            label: countryList().getLabel(cou.toString().toLowerCase()),
          };
        }
      });

      search.countries = searchCountries;
      this.setState({
        search: search,
        travellerAges: search.travellerAges,
      });
    }

    let id = this.props.match.params.id;
    axios
      .get(`${productsUrl}/${id}/`, {
        headers: getHeaders(),
      })
      .then(res => {
        let products = formatProducts([res.data.results], search);
        this.setState({ product: products[0] });
      });
  }

  setTravellerAges = ages => {
    this.setState({ travellerAges: ages }, () => {
      let premium = getPremiumFromAges(this.state.product.travelPremiums, ages);

      this.setState({
        premium: { currency: this.state.product.currency, amount: premium },
      });
    });
  };

  render() {
    let product = this.state.product;

    return (
      <div>
        <Header>{/* <ProductSearchSummary /> */}</Header>

        <div className="wrapper mt-5rem mb-5rem">
          {product ? (
            <div className="row reverse-md">
              <div className="col-md-7">
                <TravelBuyForm
                  setTravellerAges={this.setTravellerAges}
                  search={this.state.search}
                  premiums={product.travelPremiums}
                />
              </div>
              <div className="col-md-5 mb-2rem">
                <p className="mb-2rem">{"Here's a breakdown of your policy:"}</p>
                <ProductBreakdown
                  product={product}
                  premium={this.state.premium}
                  key={product.id}
                  isPurchasing={true}
                  travellerAges={this.state.travellerAges}
                />
              </div>
            </div>
          ) : (
            <div className="site-content mb-10rem">
              <FadeLoader />
            </div>
          )}
        </div>
      </div>
    );
  }
}

TravelBuy.propTypes = {
  search: PropTypes.object,
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default withRouter(
  connect(state => ({
    search: state.search,
    authUser: state.authUser,
  }))(TravelBuy),
);
