import React, { useState } from 'react';
import axios from 'axios';
import { getHeaders, usersUrl } from '../../config';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import BasicInput from '../Forms/BasicInput';
import { Form, Switch } from 'antd';

const UserInfoForm = props => {
  const [state, setState] = useState({
    email: '',
    firstName: '',
    lastName: '',
  });
  const [checked, setChecked] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const handleFieldUpdate = (stateName, event) => {
    setState({ ...state, [stateName]: event.target.value });
  };

  const handleSubmit = () => {
    setIsSubmitting(false);
    let data = {};
    if (props.policyType === 'motor') {
      data = {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
      };
    } else {
      if (checked) {
        data = {
          firstName: props.firstName,
          lastName: props.lastName,
          email: props.email,
        };
      } else {
        data = {
          firstName: state.firstName,
          lastName: state.lastName,
          email: state.email,
        };
      }
    }

    axios
      .post(usersUrl + '/update-info', data, { headers: getHeaders() })
      .then(res => {
        console.log(data, res);
        setIsSubmitting(false);
        props.handleSuccess();
        setState({ ...state,
          email: '',
          firstName: '',
          lastName: '',
        });
        setErrorMessage({});
      })
      .catch(error => {
        if (error.response && error.response.status < 500) {
          setErrorMessage({ ...error.response.data });
          if (error.response.data.message) {
            setErrorMessage({ ...errorMessage, nonFieldErrors: [error.response.data.message] });
          }
        } else {
          setErrorMessage({
            ...errorMessage,
            nonFieldErrors: ['Oops! Something happened. Please try again.'],
          });
        }
        setIsSubmitting(false);
      });
  };

  return (
      <React.Fragment>
        <Form 
        onFinish={handleSubmit}
        layout="vertical"
        className="motor_form p-0"
        style={{
          boxShadow: 'none', margin: 0,
        }}>
          {
            props.policyType === 'motor' ? (
              <div>
                <div className='row'>
                        <div className="col-md-12">
                          <BasicInput
                            label="Kindly enter your first name"
                            value={state.firstName}
                            name={'first name'}
                            placeholder="enter first name"
                            handleChange={handleFieldUpdate.bind(this, 'firstName')}
                            required={true}
                          />
                          {errorMessage.firstName
                            ? errorMessage.firstName.map((err, i) => (
                                <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                                  {err}
                                </p>
                            ))
                            : null}
                        </div>

                        <div className="col-md-12">
                          <BasicInput
                            label="Kindly enter your last name"
                            value={state.lastName}
                            name={'last name'}
                            placeholder="enter last name"
                            handleChange={handleFieldUpdate.bind(this, 'lastName')}
                            required={true}
                          />
                          {errorMessage.lastName
                            ? errorMessage.lastName.map((err, i) => (
                                <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                                  {err}
                                </p>
                            ))
                            : null}
                        </div>

                        <div className="col-md-12">
                          <BasicInput
                            label="Kindly provide your email address"
                            inputType="email"
                            value={state.email}
                            name={'email address'}
                            placeholder="enter email address"
                            handleChange={handleFieldUpdate.bind(this, 'email')}
                            required={true}
                          />
                          {errorMessage.email
                            ? errorMessage.email.map((err, i) => (
                                <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                                  {err}
                                </p>
                            ))
                            : null}
                        </div>
                      </div>
              </div>
            ) : (
              <div className="p-3">
                <div className="align-items-center d-flex font-weight-bold justify-content-center mb-2rem">
                  <h6 className='mr-3'>Are you buying this policy for yourself?</h6>
                  <Switch checkedChildren="Yes" unCheckedChildren="No" checked={checked} onChange={()=>setChecked(!checked)} />
                  {errorMessage.firstName
                    ? errorMessage.firstName.map((err, i) => (
                        <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                          {err}
                        </p>
                    ))
                    : null}
                </div>

                {
                  checked ?
                    (
                        <div>
                          <h5>Your Information</h5>
                          <div>
                            <p><b>Name:</b> {props.firstName} {props.lastName}</p>
                            <p><b>Email:</b> {props.email}</p>
                          </div>
                        </div>
                    ) :
                    (
                      <div className='row'>
                        <div className="col-md-12">
                          <BasicInput
                            label="Kindly enter your first name"
                            value={state.firstName}
                            name={'first name'}
                            placeholder="enter first name"
                            handleChange={handleFieldUpdate.bind(this, 'firstName')}
                            required={true}
                          />
                          {errorMessage.firstName
                            ? errorMessage.firstName.map((err, i) => (
                                <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                                  {err}
                                </p>
                            ))
                            : null}
                        </div>

                        <div className="col-md-12">
                          <BasicInput
                            label="Kindly enter your last name"
                            value={state.lastName}
                            name={'last name'}
                            placeholder="enter last name"
                            handleChange={handleFieldUpdate.bind(this, 'lastName')}
                            required={true}
                          />
                          {errorMessage.lastName
                            ? errorMessage.lastName.map((err, i) => (
                                <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                                  {err}
                                </p>
                            ))
                            : null}
                        </div>

                        <div className="col-md-12">
                          <BasicInput
                            label="Kindly provide your email address"
                            inputType="email"
                            value={state.email}
                            name={'email address'}
                            placeholder="enter email address"
                            handleChange={handleFieldUpdate.bind(this, 'email')}
                            required={true}
                          />
                          {errorMessage.email
                            ? errorMessage.email.map((err, i) => (
                                <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                                  {err}
                                </p>
                            ))
                            : null}
                        </div>
                      </div>
                    )
                }
                  
              </div>
            )
          }
          
          <div>
            {errorMessage.nonFieldErrors
              ? errorMessage.nonFieldErrors.map((err, i) => (
                  <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
              ))
              : null}
          </div>

          <div className="form__submit">
            <button
              className="btn btn--gradient-primary btn--width-lng mt-2rem mb-1rem"
              type="submit"
            >
              {
                checked ?
                  (isSubmitting ? <LoadingOutlined /> : 'Submit') : (isSubmitting ? <LoadingOutlined /> : 'Submit')
              }
            </button>
          </div>
        </Form>
      </React.Fragment>
  );
};

UserInfoForm.propTypes = {
  handleSuccess: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  policyType: PropTypes.string,
};

UserInfoForm.defaultProps = {
  handleSuccess: () => {},
  policyType: '',
};
export default UserInfoForm;
