import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  setPaymentRegion,
  setPayment,
  setSubaccount,
} from '../Payments/actions';
import PaystackPayment from './Paystack';
import {
  getHeaders,
  errorMessage,
  lifeUrl,
  healthUrl,
  propertyUrl,
  motorUrl,
  travelUrl,
} from '../../config';
import { Modal } from 'antd';

import ProductBreakdown from '../Travel/TravelProductBreakdown';
import WithUser from '../../hocs/WithUser';
import MotorProductBreakdown from '../Motor/MotorProductBreakdown';
import PropertyProductBreakdown from '../../pages/property/PropertyProductBreakdown';
import WithProducts from '../../hocs/WithProducts';
import HealthProductBreakdown from '../../pages/health-insurance/HealthProductBreakdown';
import LifeProductBreakdown from '../../pages/life-insurance/LifeProductBreakdown';
import WithPayments from '../../hocs/WithPayments';

class PaymentPage extends Component {
  state = {
    policy: null,
    region: null,
    method: '',
    isSubmitting: false,
    errorMessage: '',
    showModal: false,
  };

  isMotor = this.props.location.state.type === 'motor';

  isTravel = this.props.location.state.type === 'travel';

  isProperty = this.props.location.state.type === 'property';

  isHealth = this.props.location.state.type === 'health';

  isLife = this.props.location.state.type === 'life';

  initiatePayment = () => {
    let policyId = this.state.policy.id;
    let paymentURL = `${travelUrl}/${policyId}/pay`;
    if (this.isMotor) {
      paymentURL = `${motorUrl}/${policyId}/pay`;
    }

    if (this.isProperty) {
      paymentURL = `${propertyUrl}/${policyId}/pay`;
    }

    if (this.isHealth) {
      paymentURL = `${healthUrl}/${policyId}/pay`;
    }

    if (this.isLife) {
      paymentURL = `${lifeUrl}/${policyId}/pay`;
    }

    // let data = this.state;

    axios
      .post(paymentURL, {}, { headers: getHeaders() })
      .then(res => {
        let transaction = res.data.data;
        setPayment(transaction);
      })
      .catch(err => {
        if (err.response) {
          errorMessage('Oops! Something went wrong, kindly retry.');
        } else {
          errorMessage(
            'Error connecting, kindly check your internet connection.',
          );
        }
      });
  };

  componentDidMount() {
    let id = this.props.match.params.policyId;
    let URL = `${travelUrl}/${id}`;

    if (this.isMotor) {
      URL = `${motorUrl}/${id}`;
    }

    if (this.isProperty) {
      URL = `${propertyUrl}/${id}`;
    }

    if (this.isHealth) {
      URL = `${healthUrl}/${id}`;
    }

    if (this.isLife) {
      URL = `${lifeUrl}/${id}/get`;
    }

    axios
      .get(URL, {
        headers: getHeaders(),
      })
      .then(res => {
        let region = null;
        if (this.isTravel) {
          region = res.data.product.company.region;
          setPaymentRegion(region);
          setSubaccount(res.data.product.company.paymentId);
        } else {
          region = res.data.company.region;
          setPaymentRegion(region);
          setSubaccount(res.data.company.paymentId);
        }
        this.setState({ policy: res.data, region });
        this.initiatePayment();
      });

    // remove current payload
    // this.props.setPayload({})
    // this.props.setPropertyPayload({})
    // this.props.setMotorPayload({})
    // this.props.setLifePayload({})
    // this.props.setHealthPayload({})
  }

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleOk = () => {
    this.setState({ showModal: false });
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleChange = event => {
    this.setState({
      method: event.target.value,
    });
  };

  handleSuccess = () => {
    let currentModule = 'travel';

    if (this.isMotor) {
      currentModule = 'motor';
    }

    if (this.isProperty) {
      currentModule = 'property';
    }

    if (this.isHealth) {
      currentModule = 'health';
    }

    if (this.isLife) {
      currentModule = 'life';
    }

    // eslint-disable-next-line react/prop-types
    this.props.history.push(`/account/${currentModule}`);
  };

  render() {
    // let premium = this.props.products.find(
    //   product => product.id === this.props.location.state.id
    // ).travelPremiums[0].premium;

    // const MyLoader = props => (
    //   <ContentLoader
    //     // height={410}
    //     // width={280}
    //     style={{width: '280px', height: '410px'}}
    //     speed={2}
    //     primaryColor="#f3f3f3"
    //     secondaryColor="#ecebeb"
    //     {...props}
    //   >
    //     <rect x="0" y="0" rx="3" ry="3" width="280" height="410" />
    //   </ContentLoader>
    // )

    let policy = this.state.policy;

    return (
      <div>
        <br />
        <br />
        <div className="wrapper mb-5rem">
          <div className="row reverse-md nm">
            <div className="col-md-7">
              <h3 className="site-content__lead">Accepted Payment Methods</h3>
              <p className="text--muted">
                {`You'll be redirected to a secure merchant payment platform where
                you'll have the option to pay via card or mobile money.`}
              </p>

              <form className="flex-column mt-2rem mb-2rem">
                {/* <label className="mb-1rem">
                  <input
                    type="radio"
                    value="I"
                    checked={this.state.method === "I"}
                    onChange={this.handleChange}
                    className="mr-1rem"
                  />
                  Pay
                </label> */}

                {/* <h3 className="mt-5rem">Total Premium: ${premium}</h3> */}
                <small className="text--muted block mb-2rem ">
                  Depending on the option you choose, there may be charges
                  applied by your payment provider
                </small>

                {/* {this.state.errorMessage ? (
                  <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                ) : null} */}

                {/* <h3 className="site-content__lead mt-5rem">
                  Agent Verification*
                </h3>
                <small className="text--muted">
                  *applicable only to registered BestQuote travel agents
                </small>

                <div className="mt-2rem">
                  <VerificationCodeForm />
                </div> */}

                <small className="block">
                  {`By clicking "Make Payment" you agree to the Terms and
                  Conditions governing the purchase of this policy as they exist
                  on the date of purchase.`}
                </small>
                {/* <PaystackPayment handleConfirmSuccess={this.handleSuccess} /> */}

                <button
                  className="block mt-2rem btn btn--gradient-primary btn--width-lng"
                  type="button"
                  onClick={this.handleShowModal}
                >
                  Proceed to Payment Now
                </button>
              </form>
            </div>
            <div className="col-md-5 mb-2rem">
              <p className="mb-2rem">{"Here's a breakdown of your policy:"}</p>
              {this.isTravel && policy ? (
                <ProductBreakdown
                  product={policy.product}
                  premium={policy.premium}
                  isPurchasing={true}
                />
              ) : null}

              {this.isMotor && policy ? (
                <MotorProductBreakdown
                  product={policy}
                  premium={policy.premium}
                  isPurchasing={true}
                />
              ) : null}

              {this.isProperty && policy ? (
                <PropertyProductBreakdown
                  product={policy}
                  premium={policy.premium}
                  isPurchasing={true}
                />
              ) : null}

              {this.isHealth && policy ? (
                <HealthProductBreakdown
                  product={policy}
                  premium={policy.premium}
                  isPurchasing={true}
                />
              ) : null}

              {this.isLife && policy ? (
                <LifeProductBreakdown
                  product={policy}
                  premium={policy.premium}
                  isPurchasing={true}
                />
              ) : null}
            </div>
            {/* payment dialog */}
            <Modal
              title=""
              centered
              open={this.state.showModal}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={null}
            >
              <div style={{ textAlign: 'center' }}>
                <span style={{ fontSize: '1.5rem' }}>
                  <b>
                    {/* eslint-disable-next-line react/prop-types */ }
                    Proceed to pay {this.props.payment.currency}{' '}
                     {/* eslint-disable-next-line react/prop-types */ }
                    {this.props.payment.amount}?
                  </b>
                </span>
              </div>

              <div
                className="mt-3rem mb-2rem"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* <button
                  className="block btn btn--gradient-primary btn--width-lng mb-2rem"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button> */}
                {/* <FlutterwavePayment handleConfirmSuccess={this.handleCancel} /> */}
                <PaystackPayment handleConfirmSuccess={this.handleSuccess} />
              </div>
            </Modal>
            {/* end payment dialog */}
          </div>
        </div>
      </div>
    );
  }
}
PaymentPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
};

export default withRouter(WithProducts(WithPayments(WithUser(PaymentPage))));
