import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import info from '../../img/info.svg';

const BasicTooltip = props => {
  return (
    <React.Fragment>
      <Tooltip
        size="big"
        title="Welcome to React"
        position="right"
        trigger="mouseenter click"
        html={
          <div style={{ textAlign: 'left' }}>
            <div style={{ paddingBottom: '.5rem' }}>{props.label}</div>
            {props.label2 ? <div>{props.label2}</div> : null}
          </div>
        }
      >
        <img
          style={{
            maxHeight: '1.4rem',
            marginLeft: '.5rem',
          }}
          src={info}
          alt="info"
        />
      </Tooltip>
    </React.Fragment>
  );
};

BasicTooltip.propTypes = {
  label: PropTypes.string,
  label2: PropTypes.string,
};

export default BasicTooltip;
