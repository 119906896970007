import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import * as Sentry from '@sentry/react';
import { SentrySettings } from './utils/sentry';
import { Provider } from 'react-redux';
import store from './store';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-dropdown/assets/index.css';
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const sentrySettings = SentrySettings();
sentrySettings &&
  Sentry.init({
    dsn: sentrySettings.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: sentrySettings.urlTargetList,
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    // Capture 100% of the transactions, reduce in production!
    tracesSampleRate: sentrySettings.tracesSampleRate,
    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: sentrySettings.replaysSessionSampleRate,
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: sentrySettings.replaysOnErrorSampleRate,
  });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
