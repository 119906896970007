import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import bqUserIcon from '../../img/bq-user-icon.svg';
import WithUser from '../../hocs/WithUser';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const DashboardNavbar = props => {
  const menu = [
    {
      key: '1',
      label: (
        <Link to="/" className="">
        Home Page
      </Link>
      ),
    },
    {
      key: '3',
      label: (
          // eslint-disable-next-line react/prop-types
          <div  className="cursor-pointer" onClick={props.unsetUser}>
          Logout
        </div>
      ),
    },
  ];

  return (
    <div className="dashboard_navbar">
      <div className="dashboard_navbar_left" onClick={props.handleOpen}>
        <FontAwesomeIcon icon={faBars} />
      </div>

      <Dropdown menu={{ items: menu }} placement="bottomCenter">
        <div
          className="dashboard_navbar_right"
          onClick={() => props.history.push('/account')}
        >
          <Space>
            <img src={bqUserIcon} alt="user-icon" />{' '}
            {/* eslint-disable-next-line react/prop-types */}
            {`${props.authUser.user.firstName && props.authUser.user.firstName.toUpperCase()}`}
            <DownOutlined />
          </Space>
        </div>
      </Dropdown>
    </div>
  );
};

DashboardNavbar.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  handleOpen: PropTypes.func,
};

export default withRouter(WithUser(DashboardNavbar));
