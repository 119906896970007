/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

const LandingPage = ({ history }) => {
  useEffect(() => {
    history.push('/buy-motor-insurance');
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
    </React.Fragment>
  );
};

export default LandingPage;
