export const MOTOR = 'M';
export const TRAVEL = 'T';
export const LIFE = 'L';
export const PROPERTY = 'P';

export const PRODUCT_TYPE_CHOICES = {
  [MOTOR]: 'Motor',
  [TRAVEL]: 'Travel',
  [LIFE]: 'Life',
  [PROPERTY]: 'Property',
};

export const GHANA = 'gh';
export const NIGERIA = 'ng';
export const COTE_DIVOIRE = 'ci';
export const KENYA = 'ke';

export const REGIONAL_CHOICES = {
  [GHANA]: 'Ghana',
  [NIGERIA]: 'Nigeria',
  [KENYA]: 'Kenya',
  [COTE_DIVOIRE]: "Cote D'ivoire",
};

// travel product constants
export const EUROPE = 'E';
export const SCHENGEN = 'S';
export const WORLDWIDE = 'W';

export const TRAVEL_REGION_CHOICES = {
  [EUROPE]: 'Europe',
  [SCHENGEN]: 'Schengen',
  [WORLDWIDE]: 'Worldwide',
};

export const NONE = 'N';
export const US_CANADA = 'U';
export const US_CANADA_JP_AU = 'J';

export const TRAVEL_EXCEPTION_CHOICES = {
  [NONE]: 'None',
  [US_CANADA]: 'US & Canada',
  [US_CANADA_JP_AU]: 'US, Canada, Austrailia, & Japan',
};

export const MEDICAL_LIMIT = 'M';
export const SUM_ASSURED = 'S';
export const LOST_BAGGAGE = 'L';

export const TRAVEL_COVER_BD_TYPES = {
  [MEDICAL_LIMIT]: 'Medical Limit',
  [SUM_ASSURED]: 'Sum Insured',
  [LOST_BAGGAGE]: 'Lost baggage',
};

// motor product Constants
export const THIRD_PARTY = 'T';
export const THIRD_PARTY_FIRE_AND_THEFT = 'F';
export const COMPREHENSIVE = 'C';

export const MOTOR_PRODUCT_TYPE_CHOICES = {
  [THIRD_PARTY]: 'Third Party',
  [THIRD_PARTY_FIRE_AND_THEFT]: 'Third Party Fire and Theft',
  [COMPREHENSIVE]: 'Comprehensive',
};

export const PRIVATE_IND = 'X1';
export const PRIVATE_CORP = 'X4';
export const TAXI = 'TX';
export const HIRING_CARS = 'HC';
export const MINI_BUS = 'MB';
export const MAXI_BUS = 'XB';
export const MOTORCYCLE = 'MC';
export const AMBULANCE = 'AB';
export const OWN_GOODS_TO_3000 = 'O3';
export const OWN_GOODS_ABOVE_3000 = 'O4';
export const TANKERS = 'TK';
export const GEN_CARTAGE_TO_3000 = 'GT';
export const GEN_CARTAGE_ABOVE_3000 = 'GA';
export const Z_ON_SITE = 'ZS';
export const Z_ON_ROAD = 'ZR';
export const GW1_CLASS1 = 'G1';
export const GW1_CLASS2 = 'G2';
export const GW1_CLASS3 = 'G3';

export const VEHICLE_TYPE_CHOICES = {
  [PRIVATE_IND]: 'Private Individual',
  [PRIVATE_CORP]: 'Private Corporate',
  [TAXI]: 'Taxi',
  [HIRING_CARS]: 'Hiring Cars',
  [MINI_BUS]: 'Minibus',
  [MAXI_BUS]: 'Maxi-bus',
  [MOTORCYCLE]: 'Motorcycle',
  [AMBULANCE]: 'Ambulance/Hearse',
  [OWN_GOODS_TO_3000]: 'Own Goods Z.300 (Up to 3000CC)',
  [OWN_GOODS_ABOVE_3000]: 'Own Goods Z.300 (Above 3000CC)',
  [TANKERS]: 'ART/Tankers',
  [GEN_CARTAGE_TO_3000]: 'Gen. Cartage Z.301 (Up to 3000CC)',
  [GEN_CARTAGE_ABOVE_3000]: 'Gen. Cartage Z.301 (Above 3000CC)',
  [Z_ON_SITE]: 'Z.802 on site',
  [Z_ON_ROAD]: 'Z.802 on road',
  [GW1_CLASS1]: 'GW1 Class 1',
  [GW1_CLASS2]: 'GW1 Class 2',
  [GW1_CLASS3]: 'GW1 Class 3',
};

// property product constants
export const HOMEOWNERS = 'H';
export const COMMERCIAL = 'C';

export const PROPERTY_PRODUCT_TYPE_CHOICES = {
  [HOMEOWNERS]: 'Home Owers',
  [COMMERCIAL]: 'Commercial Buildings',
};

// life product Constants
export const FUNERAL_PLAN = 'funeral';
export const CHILD_PLAN = 'child';
export const FAMILY_PLAN = 'family';

export const LIFE_PRODUCT_TYPE_CHOICES = {
  [FUNERAL_PLAN]: 'Assured Funeral Plan',
  [CHILD_PLAN]: 'Assured Child Education Plan',
  [FAMILY_PLAN]: 'Assured Family Plan',
};
