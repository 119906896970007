import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  POLICY_STATUS_CHOICES,
  MOTOR_PRODUCT_TYPE_CHOICES,
  STATUS_CLASSNAMES,
} from '../../../app-constants';

class MotorClaimCard extends Component {
  render() {
    let claim = this.props.claim;
    let { policy } = claim;
    let { motorType } = policy;
    let { motorPolicyInfo } = policy;
    let { logoUrl } = policy.company;
    return (
      <div className="user-dash-card mr-2rem mb-2rem">
        <div>
          <div className="space-between align-vertical">
            <span className="user-dash-card__category">{`${MOTOR_PRODUCT_TYPE_CHOICES[motorType]} - ${motorPolicyInfo.duration} months`}</span>

            <span
              className={`user-dash-card__status user-dash-card__status${
                STATUS_CLASSNAMES[policy.status]
              }`}
            >
              {POLICY_STATUS_CHOICES[policy.status]}
            </span>
          </div>

          {/* <div className="user-dash-card__description">
            Claim value: <span className="text--muted">10000</span>
          </div>
          <div className="user-dash-card__description">
            Reason for claim: <span className="text--muted">Reason text</span>
          </div> */}
        </div>

        <div>
          <img
            className="user-dash-card__logo"
            src={logoUrl}
            alt="Insurer Logo"
          />
        </div>
      </div>
    );
  }
}
MotorClaimCard.propTypes = {
  claim: PropTypes.shape({
    policy: PropTypes.shape({
      motorType: PropTypes.string,
      status: PropTypes.string,
      company: PropTypes.shape({
        logoUrl: PropTypes.string,
      }),
      motorPolicyInfo: PropTypes.shape({
        duration: PropTypes.number,
      }),
    }),
  }),
};

export default MotorClaimCard;
