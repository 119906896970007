import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FadeLoader } from '../../../components/UI/FadeLoader';
import PropTypes from 'prop-types';

import FileUpload from '../FileUpload';
import { getFormHeaders } from '../../../config';

class PolicyClaimForm extends Component {
  state = {
    required: {
      policyDoc: null,
      lossLetter: null,
      lossReceipt: null,
      passport: null,
    },
    delayedLuggage: null,
    medical: null,
    lostLuggage: null,
    lostDocuments: null,
    delayedFlight: null,
    other: '',
    errorMessage: '',
    isSubmitting: false,
    isClaiming: false,
    claimId: 0,
  };

  handleMedical = () => {
    if (!this.state.medical) {
      this.setState({
        medical: {
          medicalReport: null,
          labResults: null,
          bankStatement: null,
        },
      });
    } else {
      this.setState({ medical: null });
    }
  };

  handleFlightDelay = () => {
    if (!this.state.delayedFlight) {
      this.setState({
        delayedFlight: {
          carrierCert: null,
          delayedFlightReport: null,
          initialTicket: null,
          replacementTicket: null,
        },
      });
    } else {
      this.setState({ delayedFlight: null });
    }
  };

  handleDelayedLuggage = () => {
    if (!this.state.delayedLuggage) {
      this.setState({
        delayedLuggage: {
          delayedIrregularityReport: null,
          delayedLuggageReport: null,
          itemsBoughtReceipt: null,
        },
      });
    } else {
      this.setState({ delayedLuggage: null });
    }
  };

  handleLostLuggage = () => {
    if (!this.state.lostLuggage) {
      this.setState({
        lostLuggage: {
          lostIrregularityReport: null,
          lostLuggageCert: null,
          settlementForm: null,
          lostLuggageReport: null,
        },
      });
    } else {
      this.setState({ lostLuggage: null });
    }
  };

  handleLostDocs = () => {
    if (!this.state.lostDocuments) {
      this.setState({
        lostDocuments: {
          statementOfLoss: null,
          replacementCostReceipt: null,
        },
      });
    } else {
      this.setState({ lostDocuments: null });
    }
  };

  handleOther = event => {
    this.setState({ other: event.target.value });
  };

  handleFiles = (stateName, document) => event => {
    this.setState({
      [stateName]: {
        ...this.state[stateName],
        [document]: event.target.files[0],
      },
    });
  };

  onSubmit = async event => {
    event.preventDefault();

    let isComplete = true;
    if (!this.state.required.policyDoc) isComplete = false;
    if (!this.state.required.lossLetter) isComplete = false;
    if (!this.state.required.lossReceipt) isComplete = false;
    if (!this.state.required.passport) isComplete = false;

    let delayedLuggage = this.state.delayedLuggage;
    if (delayedLuggage && !delayedLuggage.delayedIrregularityReport)
      isComplete = false;
    if (delayedLuggage && !delayedLuggage.delayedLuggageReport)
      isComplete = false;
    if (delayedLuggage && !delayedLuggage.itemsBoughtReceipt) isComplete = false;

    let delayedFlight = this.state.delayedFlight;
    if (delayedFlight && !delayedFlight.carrierCert) isComplete = false;
    if (delayedFlight && !delayedFlight.delayedFlightReport) isComplete = false;
    if (delayedFlight && !delayedFlight.initialTicket) isComplete = false;
    if (delayedFlight && !delayedFlight.replacementTicket) isComplete = false;

    let medical = this.state.medical;
    if (medical && !medical.medicalReport) isComplete = false;
    if (medical && !medical.labResults) isComplete = false;
    if (medical && !medical.bankStatement) isComplete = false;

    let lostLuggage = this.state.lostLuggage;
    if (lostLuggage && !lostLuggage.lostIrregularityReport) isComplete = false;
    if (lostLuggage && !lostLuggage.lostLuggageCert) isComplete = false;
    if (lostLuggage && !lostLuggage.settlementForm) isComplete = false;
    if (lostLuggage && !lostLuggage.lostLuggageReport) isComplete = false;

    let lostDocuments = this.state.lostDocuments;
    if (lostDocuments && !lostDocuments.statementOfLoss) isComplete = false;
    if (lostDocuments && !lostDocuments.replacementCostReceipt)
      isComplete = false;

    if (!isComplete) {
      this.setState({
        errorMessage: 'Please make sure you upload all required files',
      });
    } else {
      let documents = [
        ...Object.keys(this.state.required).map(key => {
          return { document_name: key, document: this.state.required[key] };
        }),
      ];
      if (this.state.delayedLuggage) {
        documents.push(
          ...Object.keys(this.state.delayedLuggage).map(key => {
            return {
              document_name: key,
              document: this.state.delayedLuggage[key],
            };
          }),
        );
      }
      if (this.state.medical) {
        documents.push(
          ...Object.keys(this.state.medical).map(key => {
            return {
              document_name: key,
              document: this.state.medical[key],
            };
          }),
        );
      }
      if (this.state.lostLuggage) {
        documents.push(
          ...Object.keys(this.state.lostLuggage).map(key => {
            return {
              document_name: key,
              document: this.state.lostLuggage[key],
            };
          }),
        );
      }
      if (this.state.lostDocuments) {
        documents.push(
          ...Object.keys(this.state.lostDocuments).map(key => {
            return {
              document_name: key,
              document: this.state.lostDocuments[key],
            };
          }),
        );
      }
      if (this.state.delayedFlight) {
        documents.push(
          ...Object.keys(this.state.delayedFlight).map(key => {
            return {
              document_name: key,
              document: this.state.delayedFlight[key],
            };
          }),
        );
      }

      let formData = new FormData();
      formData.append('message', 'Default');
      documents.forEach(document => {
        formData.append(document.document_name, document.document);
      });

      this.setState({ isSubmitting: true });

      await axios({
        headers: getFormHeaders(),
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE}/api/v1/policies/${this.props.match.params.policyId}/claim/`,
        data: formData,
      });

      this.setState({
        errorMessage: 'Refund request success',
        isSubmitting: false,
      });

      setTimeout(() => {
        this.props.history.push('/account/claims');
      }, 1500);
    }
  };

  render() {
    let { product } = this.props;

    return (
      <div>
        <p className="mb-1rem">Make a Claim on Policy</p>
        <p className="text--muted mb-2rem">
          Review the policy below and add the required details &amp; documents
          to make your claim
        </p>
        <p style={{ color: 'red' }}>{this.state.errorMessage}</p>

        <div className="align-vertical mb-2rem" style={{ fontSize: '12px' }}>
          <img
            alt={product.company.name}
            className="mr-2rem"
            src={product.company.logoUrl}
            style={{ maxHeight: '3rem' }}
          />
          <span>{product.company.name}</span>
        </div>

        <label className="">
          <input
            value={this.state.isClaiming}
            type="checkbox"
            onClick={() =>
              this.setState({ isClaiming: !this.state.isClaiming })
            }
            className="mr-1rem mb-2rem"
          />
          Make claim on policy
        </label>

        {this.state.isClaiming ? (
          <form className="form" onSubmit={this.onSubmit}>
            <div className="flex mb-2rem" style={{ fontSize: '12px' }}>
              <div className="flex-column mr-2rem">
                <label className="mb-1rem">
                  <input
                    type="checkbox"
                    onChange={this.handleMedical}
                    className="mr-1rem"
                  />
                  Medical Expenses / Emergency Dental Care
                </label>
                <label className="mb-1rem">
                  <input
                    type="checkbox"
                    onChange={this.handleFlightDelay}
                    className="mr-1rem"
                  />
                  Flight Delay
                </label>
                <label className="mb-1rem">
                  <input
                    type="checkbox"
                    onChange={this.handleDelayedLuggage}
                    className="mr-1rem"
                  />
                  Luggage Delay
                </label>
              </div>
              <div className="flex-column mr-2rem">
                <label className="mb-1rem">
                  <input
                    type="checkbox"
                    onChange={this.handleLostLuggage}
                    className="mr-1rem"
                  />
                  Lost Luggage
                </label>
                <label className="mb-1rem">
                  <input
                    type="checkbox"
                    onChange={this.handleLostDocs}
                    className="mr-1rem"
                  />
                  Loss of Personal Documents
                </label>
              </div>
              {/* <div className="flex-column mr-2rem">
                <label>
                  <input type="checkbox"
                  onChange={this.handleFlightDelay} />
                  Delayed Departure
                </label>
                
              </div> */}
            </div>

            {/* file uploads */}

            <div className="claim-form mb-2rem">
              <div className="claim-form__loss-type flex-column mb-2rem">
                <p className="mb-1rem form__label">
                  Required Documents for all claims
                </p>

                <FileUpload
                  document={this.state.required.policyDoc}
                  fileName="Copy of insurance policy"
                  documentName="policyDoc"
                  label="Copy of insurance policy"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                <FileUpload
                  document={this.state.required.lossLetter}
                  fileName="Detailed letter explaining the loss"
                  documentName="lossLetter"
                  label="Detailed letter explaining the loss"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                <FileUpload
                  document={this.state.required.lossReceipt}
                  fileName="Original official receipt(s) of incurred loss"
                  documentName="lossReceipt"
                  label="Original official receipt(s) of incurred loss"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
                <FileUpload
                  document={this.state.required.passport}
                  fileName="Copy of passport showing exit/entry dates from country of
                  residence"
                  documentName="passport"
                  label="Copy of passport showing exit/entry dates from country of
                  residence"
                  stateName="required"
                  handleFiles={this.handleFiles}
                />
              </div>

              {this.state.medical ? (
                <div
                  className="claim-form__loss-type flex-column mb-2rem"
                  style={{ width: '50rem' }}
                >
                  <p className="mb-1rem form__label">
                    For medical expenses/emergency dental care
                  </p>

                  <FileUpload
                    document={this.state.medical.medicalReport}
                    fileName="Medical Report with Admitting Medical History"
                    documentName="medicalReport"
                    label="Medical Report with Admitting Medical History"
                    stateName="medical"
                    handleFiles={this.handleFiles}
                  />
                  <FileUpload
                    document={this.state.medical.labResults}
                    fileName="Clinical / Laboratory results"
                    documentName="labResults"
                    label="Clinical / Laboratory results"
                    stateName="medical"
                    handleFiles={this.handleFiles}
                  />
                  <FileUpload
                    document={this.state.medical.bankStatement}
                    fileName="Detailed Statement of Bank Account (IBAN, SWIFT)"
                    documentName="bankStatement"
                    label="Detailed Statement of Bank Account (IBAN, SWIFT)"
                    stateName="medical"
                    handleFiles={this.handleFiles}
                  />
                </div>
              ) : null}

              {this.state.delayedFlight ? (
                <div
                  className="claim-form__loss-type flex-column mb-2rem"
                  style={{ width: '50rem' }}
                >
                  <p className="mb-1rem form__label">For delayed flight</p>

                  <FileUpload
                    document={this.state.delayedFlight.carrierCert}
                    fileName="Certificate issued by the Carrier"
                    documentName="carrierCert"
                    label="Certificate issued by the Carrier"
                    stateName="delayedFlight"
                    handleFiles={this.handleFiles}
                  />
                  <FileUpload
                    document={this.state.delayedFlight.delayedFlightReport}
                    fileName="Incident report from client"
                    documentName="delayedFlightReport"
                    label="Incident report from client"
                    stateName="delayedFlight"
                    handleFiles={this.handleFiles}
                  />
                  <FileUpload
                    document={this.state.delayedFlight.initialTicket}
                    fileName="Copy of Initial Travel Ticket"
                    documentName="initialTicket"
                    label="Copy of Initial Travel Ticket"
                    stateName="delayedFlight"
                    handleFiles={this.handleFiles}
                  />
                  <FileUpload
                    document={this.state.delayedFlight.replacementTicket}
                    fileName="Copy of Replacement Ticket (showing amount paid)"
                    documentName="replacementTicket"
                    label="Copy of Replacement Ticket (showing amount paid)"
                    stateName="delayedFlight"
                    handleFiles={this.handleFiles}
                  />
                </div>
              ) : null}

              {this.state.delayedLuggage ? (
                <div
                  className="claim-form__loss-type flex-column mb-2rem"
                  style={{ width: '50rem' }}
                >
                  <p className="mb-1rem form__label">For delayed luggage</p>

                  <FileUpload
                    document={
                      this.state.delayedLuggage.delayedIrregularityReport
                    }
                    fileName="Property irregularity report (issued by Carrier / Handling
                      Service)"
                    documentName="delayedIrregularityReport"
                    label="Property irregularity report (issued by Carrier / Handling
                      Service)"
                    stateName="delayedLuggage"
                    handleFiles={this.handleFiles}
                  />
                  <FileUpload
                    document={this.state.delayedLuggage.delayedLuggageReport}
                    fileName="Incident report from client"
                    documentName="delayedLuggageReport"
                    label="Incident report from client"
                    stateName="delayedLuggage"
                    handleFiles={this.handleFiles}
                  />
                  <FileUpload
                    document={this.state.delayedLuggage.itemsBoughtReceipt}
                    fileName="Original receipt of basic necessity items bought"
                    documentName="itemsBoughtReceipt"
                    label="Original receipt of basic necessity items bought"
                    stateName="delayedLuggage"
                    handleFiles={this.handleFiles}
                  />
                </div>
              ) : null}

              {this.state.lostLuggage ? (
                <div
                  className="claim-form__loss-type flex-column mb-2rem"
                  style={{ width: '50rem' }}
                >
                  <p className="mb-1rem form__label">For lost luggage</p>

                  <FileUpload
                    document={this.state.lostLuggage.lostIrregularityReport}
                    fileName="Property irregularity report (issued by Carrier / Handling
                      Service)"
                    documentName="lostIrregularityReport"
                    label="Property irregularity report (issued by Carrier / Handling
                      Service)"
                    stateName="lostLuggage"
                    handleFiles={this.handleFiles}
                  />
                  <FileUpload
                    document={this.state.lostLuggage.lostLuggageCert}
                    fileName="Certificate of Lost Luggage issued by the Carrier"
                    documentName="lostLuggageCert"
                    label="Certificate of Lost Luggage issued by the Carrier"
                    stateName="lostLuggage"
                    handleFiles={this.handleFiles}
                  />
                  <FileUpload
                    document={this.state.lostLuggage.settlementForm}
                    fileName="Copy of the Carrier reimbursement / settlement form"
                    documentName="settlementForm"
                    label="Copy of the Carrier reimbursement / settlement form"
                    stateName="lostLuggage"
                    handleFiles={this.handleFiles}
                  />
                  <FileUpload
                    document={this.state.lostLuggage.lostLuggageReport}
                    fileName="Incident report from client"
                    documentName="lostLuggageReport"
                    label="Incident report from client"
                    stateName="lostLuggage"
                    handleFiles={this.handleFiles}
                  />
                </div>
              ) : null}

              {this.state.lostDocuments ? (
                <div
                  className="claim-form__loss-type flex-column mb-2rem"
                  style={{ width: '50rem' }}
                >
                  <p className="mb-1rem form__label">
                    For lost personal documents
                  </p>

                  <FileUpload
                    document={this.state.lostDocuments.statementOfLoss}
                    fileName="Statement of Loss (Police report)"
                    documentName="statementOfLoss"
                    label="Statement of Loss (Police report)"
                    stateName="lostDocuments"
                    handleFiles={this.handleFiles}
                  />
                  <FileUpload
                    document={this.state.lostDocuments.replacementCostReceipt}
                    fileName="Receipts of document replacement incurred costs"
                    documentName="replacementCostReceipt"
                    label="Receipts of document replacement incurred costs"
                    stateName="lostDocuments"
                    handleFiles={this.handleFiles}
                  />
                </div>
              ) : null}
            </div>

            <hr />

            {this.state.isSubmitting ? (
              <div className="mt-5rem">
                <FadeLoader />
              </div>
            ) : (
              <div className="mt-5rem">
                <button
                  className="btn btn--gradient-primary btn--width-md mb-2rem mr-2rem"
                  type="submit"
                >
                  Make Claim
                </button>
                <button
                  className="btn btn--width-md mb-2rem mr-2rem"
                  onClick={event => {
                    event.preventDefault();
                    this.handleCancelClaim();
                  }}
                >
                  CANCEL
                </button>
              </div>
            )}
          </form>
        ) : null}
      </div>
    );
  }
}
PolicyClaimForm.propTypes = {
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
  history: PropTypes.object,
  match: PropTypes.object,
  product: PropTypes.shape({
    company: PropTypes.shape({
      name: PropTypes.string,
      logoUrl: PropTypes.string,
    }),
  }),
};

export default withRouter(
  connect(state => ({
    authUser: state.authUser,
  }))(PolicyClaimForm),
);
