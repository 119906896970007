import React from 'react';
import { withRouter } from 'react-router-dom';
// import PropTypes from 'prop-types'

import { getBreakdownValueByLabel } from '../../utils/products';
import WithProducts from '../../hocs/WithProducts';

const CompareTravelPolicies = props => {
  let nfApprox = currency =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  let nfExact = currency =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  let nfNoCurrency = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const ghsPremium = (premium, currency) => {
    if (premium) {
      if (currency === 'EUR')
        // eslint-disable-next-line react/prop-types
        return parseFloat(premium) * parseFloat(props.exchangeRate.ghsToEur);
      else if (currency === 'USD')
        // eslint-disable-next-line react/prop-types
        return parseFloat(premium) * parseFloat(props.exchangeRate.ghsToUsd);
    }
  };

  const handleBuy = policyId => {
    // eslint-disable-next-line react/prop-types
    props.clearCompareProducts();
    // eslint-disable-next-line react/prop-types
    props.history.push(`/buy-travel-insurance/${policyId}/buy`);
  };

  return (
    <div className="travel_compare_page">
      <table className="table table-responsive table-stripped">
        <tr style={{ backgroundColor: 'white' }}>
          <th className="travel_compare_page_first_column">Company</th>
          <th>Premium</th>
          <th>Age Groups</th>
          <th>Maximum Duration</th>
          <th>Medical expenses and hospitalization abroad</th>
          <th>Emergency medical evacuation in case of illness/accident</th>
          <th>Emergency dental care</th>
          <th>Emergency optical</th>
          <th>Return after medical treatment</th>
          <th>Repatriation of mortal remains</th>
          <th>Repatriation of family member travelling with the insured</th>
          <th>
            Emergency return home following the death of a close family member
          </th>
          <th>Accidental death by means of transport</th>
          <th>Travel of one immediate family member</th>
          <th>Trip cancellation</th>
          <th>Delayed departure</th>
          <th>Advance of bond/fund</th>
          <th>24 hours assistance service</th>
          <th>Legal Defence</th>
          <th>Loss of documents</th>
          <th>Location and forwarding of baggage and personal belonging</th>
          <th>Compensation for in-flight loss of checked in baggage</th>
          <th>Compensation for delay in arrival of luggage</th>
          <th>Personal Injury</th>
          {/* <th></th> */}
        </tr>

       { /* eslint-disable-next-line react/prop-types */}
        {props.compareProducts.map((p, i) => (
          <tr key={i}>
            <td className="travel_compare_page_first_column">
              <img alt="" className="" src={p.company.logoUrl} />
              <p className="text--muted mt-1rem">{p.company.name}</p>
              <p className="mt-1rem">
                <b>{p.name}</b>
              </p>
              <button
                onClick={handleBuy.bind(this, p.id)}
                className="btn_normal btn_green btn_md mt-1rem"
              >
                Buy Now
              </button>
            </td>

            <td>
              <p className="">
                {p.premium ? (
                  <span>
                    {`${nfExact(p.currency).format(
                      p.premium,
                    )} (Gh¢${nfNoCurrency.format(
                      ghsPremium(p.premium, p.currency),
                    )})`}
                  </span>
                ) : null}
              </p>
            </td>

            <td>
              {p.travelPremiums.map((premium, ii) => (
                <div className="mb-1rem" key={ii}>
                  <span>
                    {premium.ageRange.lower} - {premium.ageRange.upper}
                  </span>
                  <span className="ml-1rem">
                    {nfApprox(p.currency).format(premium.premium)}
                  </span>
                </div>
              ))}
            </td>

            <td>
              <span>{p.travelInfo.maxDuration} days</span>
            </td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'me_abroad')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'em_evacuation')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'e_dental')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'e_optical')}</td>
            <td>
              {getBreakdownValueByLabel(p.breakdowns, 'return_treatment')}
            </td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'mortal_remains')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'family_repa')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'ef_death_return')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'transport_death')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'ifm_travel')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'trip_cancel')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'departure_delay')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'bond_advance')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'assist_24')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'legal_defence')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'doc_loss')}</td>
            <td>
              {getBreakdownValueByLabel(p.breakdowns, 'forwarding_baggage')}
            </td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'ifl_baggage')}</td>
            <td>{getBreakdownValueByLabel(p.breakdowns, 'luggage_delay')}</td>
            <td>
              {getBreakdownValueByLabel(p.breakdowns, 'personal_liability')}
            </td>
            {/* <td>
              <button
                className="btn_normal btn_green btn_md"
                onClick={handleBuy.bind(this, p.id)}
              >
                Buy Now
              </button>
            </td> */}
          </tr>
        ))}
      </table>
    </div>
  );
};

CompareTravelPolicies.propTypes = {
  //
};

export default withRouter(WithProducts(CompareTravelPolicies));
