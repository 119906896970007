import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { getHeaders, healthUrl } from '../../config';
import WithRegion from '../../hocs/WithRegion';
import { getCookie } from '../../utils/urls';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import SelectInput from '../../components/Forms/SelectInput';
import DatePickerInput from '../../components/Forms/DatePickerInput';
import BasicInput from '../../components/Forms/BasicInput';
import WithProducts from '../../hocs/WithProducts';
import WithUser from '../../hocs/WithUser';
import SliderInput from '../../components/Forms/SliderInput';

const HealthForm = props => {
  // eslint-disable-next-line react/prop-types
  const { payload } = props;
  const [policy, setPolicy] = useState({
    // eslint-disable-next-line react/prop-types
    familySize: payload.familySize || null,
    // eslint-disable-next-line react/prop-types
    budgetRange: payload.budgetRange || [0, 100000],
    minimumBudget: 3000,
    maximumBudget: 20000,
    // eslint-disable-next-line react/prop-types
    startDate: payload.startDate || new Date(),
    // eslint-disable-next-line react/prop-types
    referrer: payload.referrer || '',
  });

  const [formSection, setFormSection] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [referrer, setReferrer] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [affiliateReferrer, setAffiliateReferrer] = useState(false);

  const removeErrorMessage = stateName => {
    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        nonFieldErrors: null,
      });
    }
  };

  const handleSelectUpdate = stateName => value => {
    setPolicy({ ...policy, [stateName]: value });
    // remove form field errors
    removeErrorMessage(stateName);
  };

  const handleFieldUpdate = stateName => event => {
    setPolicy({ ...policy, [stateName]: event.target.value });

    // remove form field errors
    removeErrorMessage(stateName);
  };

  const handleDateUpdate = stateName => date => {
    setPolicy({ ...policy, [stateName]: date });

    // remove form field errors
    removeErrorMessage(stateName);
  };

  const handleSubmit = async () => {
    let data = {
      ...policy,
      startDate: policy.startDate
        ? moment(policy.startDate).format('YYYY-MM-DD')
        : '',
      minimumBudget: policy.budgetRange[0],
      maximumBudget: policy.budgetRange[1],
    };

    setIsSubmitting(true);
    axios
      .post(`${healthUrl}/preview`, data, { headers: getHeaders() })
      .then(res => {
        // eslint-disable-next-line react/prop-types
        props.setHealthProducts(res.data.data);
        // eslint-disable-next-line react/prop-types
        props.setHealthPayload(policy);
        // eslint-disable-next-line react/prop-types
        props.history.push('/buy-health-insurance/compare');

        // this.scrollTo()
        setIsSubmitting(false);
      })
      .catch(err => {
        setIsSubmitting(false);
        if (err.response) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          err.response.status === 400
            ? setErrorMessage({
              ...err.response.data,
              nonFieldErrors: ["Kindly make sure you've completed the form"],
            })
            : setErrorMessage({
              ...errorMessage,
              nonFieldErrors: ['Oops, server Error! Retry'],
            });
        } else {
          setErrorMessage({
            ...errorMessage,
            nonFieldErrors: ['Error Connecting to Server, Retry.'],
          });
        }
      });
  };

  const handleNextSection = () => {
    setFormSection(formSection + 1);
  };

  useEffect(() => {
    if (getCookie('referrer')) {
      setAffiliateReferrer(true);
      setReferrer(getCookie('referrer'));
    }
  }, []);

  const familySizeOptions = [];
  for (let i = 1; i < 8; i++) {
    familySizeOptions.push({ label: i, value: i });
  }

  return (
    <Form
      onFinish={formSection === 1 ? handleSubmit : handleNextSection}
      layout="vertical"
      className="motor_form"
    >
      {formSection === 1 ? (
        <div className="row">
          <h3 className="mb-2rem">Start your insurance cover</h3>
          <div className="col-md-12 mb-2rem">
            <SelectInput
              label={'Kindly select your family size'}
              value={policy.familySize}
              name={'family size'}
              optionList={familySizeOptions}
              onChange={handleSelectUpdate('familySize')}
              placeholder="select family size"
              required={true}
            />
            {errorMessage.familySize
              ? errorMessage.familySize.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12">
            <SliderInput
              label={'Kindly tell us your budget range'}
              value={policy.budgetRange}
              name={'policy budget range'}
              onChange={handleSelectUpdate('budgetRange')}
              range={true}
              min={policy.minimumBudget}
              max={policy.maximumBudget}
              required={true}
            />
            {errorMessage.budgetRange
              ? errorMessage.budgetRange.map((err, i) => (
                  <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            <DatePickerInput
              label="Kindly chose when you want this policy to start"
              value={moment(policy.startDate).format('YYYY-MM-DD')}
              handleChange={handleDateUpdate('startDate')}
              name="starting date"
              required={true}
            />{' '}
            {errorMessage.startDate
              ? errorMessage.startDate.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          {!affiliateReferrer ? (
            <div>
              <h4 className="ml-1rem">Referral Code (Optional)</h4>

              <div className="col-md-12 mb-2rem">
                <BasicInput
                  label={''}
                  value={policy.referrer}
                  name="referrer"
                  placeholder="enter code here"
                  handleChange={handleFieldUpdate('referrer')}
                  required={false}
                />
              </div>
            </div>
          ) : null}

          <div>
            {errorMessage.nonFieldErrors
              ? errorMessage.nonFieldErrors.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>
        </div>
      ) : null}

      <div className="motor_form_buttons">
        <button className="btn_normal btn_gradient btn_md" type="submit">
          {isSubmitting ? <LoadingOutlined /> : 'Submit'}
        </button>
      </div>
    </Form>
  );
};

export default WithUser(WithProducts(WithRegion(withRouter(HealthForm))));
