import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getHeaders } from '../../config';
import WithProducts from '../../hocs/WithProducts';
import { getCookie } from '../../utils/urls';
import { formatCountries } from '../../utils/products';
import { Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import countryList from 'react-select-country-list';
import SelectInput from '../../components/Forms/SelectInput';
import DatePickerInput from '../../components/Forms/DatePickerInput';
import SelectMultipleInput from '../../components/Forms/SelectMultipleInput';
import { trackEvents } from '../../utils/gaTrack';

const TravelSearchForm = props => {
  // eslint-disable-next-line react/prop-types
  const { payload } = props;
  
  const [policy, setPolicy] = useState({
    // eslint-disable-next-line react/prop-types
    travellerType: payload.travellerType || '',
    // eslint-disable-next-line react/prop-types
    countries: payload.countries && payload.countries.length ? formatCountries(payload.countries) : [],
    // eslint-disable-next-line react/prop-types
    travellerAges: payload.travellerAges || [],
    // eslint-disable-next-line react/prop-types
    coverType: payload.coverType || '',
    // eslint-disable-next-line react/prop-types
    startDate: payload.startDate || new Date(),
    // eslint-disable-next-line react/prop-types
    endDate: payload.endDate || new Date(),
    region: 'gh',
  });

  const [formSection, setFormSection] = useState(1);
  // eslint-disable-next-line
  const [referrer, setReferrer] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  // eslint-disable-next-line
  const [affiliateReferrer, setAffiliateReferrer] = useState(false)

  const removeErrorMessage = stateName => {
    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        nonFieldErrors: null,
      });
    } else if (errorMessage.vehicles) {
      setErrorMessage({
        ...errorMessage,
        vehicles: errorMessage.vehicles.map(v => {
          return { ...v, [stateName]: null };
        }),
        nonFieldErrors: null,
      });
    }
  };

  const handleSelectUpdate = stateName => value => {
    if (value) {
      setPolicy({ ...policy, [stateName]: value });
    } else {
      setPolicy({ ...policy, [stateName]: '' });
    }

    // remove form field errors
    removeErrorMessage(stateName);
  };

  const handleDateUpdate = stateName => date => {
    setPolicy({ ...policy, [stateName]: date });

    // remove form field errors
    removeErrorMessage(stateName);
  };

  const numberOfAdults = ages => {
    let adults = 0;
    for (let i = 0; i < ages.length; i++) {
      if (ages[i] > 17) adults += 1;
    }
    return adults;
  };

  const handleSubmit = async () => {
    // handle checks
    if (policy.travellerType === 'F') {
      if (policy.travellerAges.length < 3 || policy.travellerAges.length > 5) {
        setErrorMessage({
          ...errorMessage,
          nonFieldErrors: ['Family policies require 3-5 travellers'],
        });
        return;
      } else if (numberOfAdults(policy.travellerAges) !== 2) {
        setErrorMessage({
          ...errorMessage,
          nonFieldErrors: ['Family policies require at least two adults'],
        });
        return;
      }
    }

    let startDate = new Date(policy.startDate);
    let endDate = new Date(policy.endDate);
    let diff = moment(endDate).diff(moment(startDate), 'days');

    if (policy.coverType === 'M') {
      if (diff < 90) {
        setErrorMessage({
          ...errorMessage,
          nonFieldErrors: [
            'The minimum duration for a multiple trip is 90 days',
          ],
        });
        return;
      }
    }

    setIsSubmitting(true);

    const url = `${process.env.REACT_APP_API_BASE}/api/v1/search/travel`;

    let data = policy;
    data.countries = data.countries.map(country => countryList().getValue(country.toLowerCase()));
    data.isFamily = data.travellerType === 'F';
    data.isSingleTrip = data.coverType === 'S';

    // save search query
    axios
      .post(url, data, { headers: getHeaders() })
      .then((res) => { 

        // use search query to retrieve products
        const newUrl = `${url}/${res.data.results.id}/results`;
        axios.get(newUrl, { headers: getHeaders() }).then(res_ => {
          let results = res_.data.results.map(product => {
            product.isToggled = false;
            return product;
          });

          // eslint-disable-next-line react/prop-types
          props.setProducts(results);
          // eslint-disable-next-line react/prop-types
          props.setPayload(policy);
          // eslint-disable-next-line react/prop-types
          props.setSearchItem(res_.data.search);
          setIsSubmitting(false);
          trackEvents(
            'Travel', 
            'Search Policies',  
            'New User',
          );
          // eslint-disable-next-line react/prop-types
          props.history.push('/buy-travel-insurance/compare');
        });
      })
      .catch(err => {
        setIsSubmitting(false);
        if (err.response) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          err.response.status === 400
            ? setErrorMessage({
              ...err.response.data,
              nonFieldErrors: ["Kindly make sure you've completed the form"],
            })
            : setErrorMessage({
              ...errorMessage,
              nonFieldErrors: ['Oops, server Error! Retry'],
            });
        } else {
          setErrorMessage({
            ...errorMessage,
            nonFieldErrors: ['Error Connecting to Server, Retry.'],
          });
        }
      });
  };

  const handleNextSection = () => {
    setFormSection(formSection + 1);
  };

  useEffect(() => {
    // setRegion({ region: region })
    // initialize referrer code if available.
    if (getCookie('referrer')) {
      setAffiliateReferrer(true);
      setReferrer(getCookie('referrer'));
    }
    // eslint-disable-next-line
  }, [])

  const travelOptions = [
    { label: 'Individual(s)', value: 'I' },
    { label: 'Family', value: 'F' },
  ];

  const coverOptions = [
    { label: 'Single Trip', value: 'S' },
    { label: 'Multi-Trip', value: 'M' },
  ];

  let countryOptions = countryList()
    .getData()
    .map(c => {
      return {
        ...c,
        value: c.label,
      };
    });
    
  let ages = [];
  for (let i = 0; i < 120; i++) {
    ages.push({ label: i, value: i });
  }

  return (
    <Form
      onFinish={formSection === 2 ? handleSubmit : handleNextSection}
      layout="vertical"
      className="travel_form"
    >
      {formSection === 1 ? (
        <div className="row travel_form_section">
          <h3 className="mb-2rem">Start your insurance cover</h3>
          {/* travel types */}
          <div className="col-md-12">
            <SelectMultipleInput
              label={'Your Destination(s)?'}
              value={policy.countries}
              name={'travelling destination(s)'}
              optionList={countryOptions}
              onChange={handleSelectUpdate('countries')}
              required={true}
              placeholder="select destinations"
            />
            {errorMessage.countries
              ? errorMessage.countries.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>
          {/* end motor types */}

          <div className="col-md-12">
            <SelectInput
              label="Who's Travelling?"
              value={policy.travellerType}
              name="type of travel"
              onChange={handleSelectUpdate('travellerType')}
              optionList={travelOptions}
              placeholder="choose traveler"
              required={true}
            />

            {errorMessage.travellerType
              ? errorMessage.travellerType.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          {/* driving experience and duration */}
          <div className="col-md-12">
            <SelectMultipleInput
              label={'Traveller Age(s)?'}
              value={policy.travellerAges}
              name={'travelller ages'}
              optionList={ages}
              onChange={handleSelectUpdate('travellerAges')}
              placeholder="select traveller ages"
              required={true}
            />
            {errorMessage.travellerAges
              ? errorMessage.travellerAges.map((err, i) => (
                  <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
              ))
              : null}
          </div>
        </div>
      ) : null}

      {formSection === 2 ? (
        <div className="row travel_form_section">
          <h3 className="mb-2rem">Finish up!</h3>
          <div className="col-md-12">
            <SelectInput
              label="Which cover do you prefer?"
              value={policy.coverType}
              name="type of travel cover"
              onChange={handleSelectUpdate('coverType')}
              optionList={coverOptions}
              toolTipLabel="SINGLE TRIP - Covers only one trip under the cover period"
              toolTipLabel2="MULTIPLE TRIP - Covers more than one trip under the cover period"
              placeholder="select type of the cover"
              required={true}
            />

            {errorMessage.coverType
              ? errorMessage.coverType.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12">
            <DatePickerInput
              label="When does your Journey Start? *choose below*"
              value={moment(policy.startDate).format('YYYY-MM-DD')}
              handleChange={handleDateUpdate('startDate')}
              name="starting date"
              required={true}
            />{' '}
            {errorMessage.startDate
              ? errorMessage.startDate.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12">
            <DatePickerInput
              label="When does your Journey End? *choose below*"
              value={moment(policy.endDate).format('YYYY-MM-DD')}
              handleChange={handleDateUpdate('endDate')}
              name="ending date"
              required={true}
            />{' '}
            {errorMessage.endDate
              ? errorMessage.endDate.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>
          <div>
            {errorMessage.nonFieldErrors
              ? errorMessage.nonFieldErrors.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>
        </div>
      ) : null}

      <div className="travel_form_buttons">
        <button
          className="btn_normal btn_dark btn_md"
          type="button"
          onClick={() =>
            setFormSection(formSection > 1 ? formSection - 1 : formSection)
          }
          style={formSection !== 1 ? {} : { visibility: 'hidden' }}
        >
          Back
        </button>

        <button className="btn_normal btn_gradient btn_md" type="submit">
          {isSubmitting ? (
            <LoadingOutlined />
          ) : formSection === 2 ? (
            'Search'
          ) : (
            'Continue'
          )}
        </button>
      </div>
    </Form>
  );
};

TravelSearchForm.propTypes = {
  setMotorProducts: PropTypes.func,
};

export default WithProducts(withRouter(TravelSearchForm));
