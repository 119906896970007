import React, { Component } from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { loginUrl } from '../../config';
import WithUser from '../../hocs/WithUser';
import PropTypes from 'prop-types';
import { Modal, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import LoginOTPForm from './LoginOTPForm';

class LoginForm extends Component {
  state = {
    email: '',
    password: '',
    phoneNumber: '',
    isSubmitting: false,
    redirectToReferrer: false,
    useComponentIn: this.props.useComponentIn, // page or modal
    errorMessage: {},
    showOTPModal: false,
    userData: {},
  };

  handleInputUpdate = (stateName, event) => {
    this.setState({ [stateName]: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    let data = this.state;
    axios
      .post(loginUrl, data)
      .then(res => {
        this.setState({ userData: res.data });

        if (res.data.user.verifiedPhone) {
          this.props.setUser(res.data);
          if (this.props.useComponentIn === 'page') {
            this.setState({ showOTPModal: false, isSubmitting: false, redirectToReferrer: true });
          } else {
            this.setState({ showOTPModal: false, isSubmitting: false, redirectToReferrer: false });
            this.props.handleSuccess();
          }
        } else {
          this.setState({ showOTPModal: true, isSubmitting: false });
        }
      })
      .catch(error => {
        if (error.response && error.response.status < 500) {
          this.setState({ errorMessage: error.response.data });
        } else {
          this.setState({
            errorMessage: {
              nonFieldErrors: ['Oops! Something happened. Please try again.'],
            },
          });
        }

        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const redirectPath = from.pathname;
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to={redirectPath} />;
    }

    return (
      <React.Fragment>
        <form className="mt-2rem" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-12 mb-1rem">
              <label htmlFor="email" style={{ fontWeight: '700' }}>
                Email Address
              </label>
              <Input
                className=""
                placeholder="john@doe.com"
                onChange={this.handleInputUpdate.bind(this, 'email')}
                autoFocus="autofocus"
                autoComplete="username"
                required
              />
              {this.state.errorMessage.email
                ? this.state.errorMessage.email.map((err, i) => (
                    <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
            </div>
            <div className="col-md-12 mt-2rem">
              <label htmlFor="password" style={{ fontWeight: '700' }}>
                Password
              </label>
              <Input.Password
                className=""
                placeholder="Enter password"
                type="password"
                onChange={this.handleInputUpdate.bind(this, 'password')}
                autoComplete="current-password"
                required
              />
              {this.state.errorMessage.password
                ? this.state.errorMessage.password.map((err, i) => (
                    <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
              <div
                style={{
                  color: 'rgb(0 66 65 / 69%)',
                  fontSize: '1.3rem',
                  marginTop: '0.5rem',
                }}
              >
                {"Can't remember your password?"}{' '}
                <Link to="/auth/password-reset">
                  <span className="navbar__link--active">Reset it Now</span>
                </Link>
              </div>
            </div>
          </div>
          <div>
            {this.state.errorMessage.nonFieldErrors
              ? this.state.errorMessage.nonFieldErrors.map((err, i) => (
                  <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
              ))
              : null}
          </div>

          <div className="form__submit mt-3rem">
            <button
              className="btn btn--gradient-primary btn--width-lng mt-2rem mb-1rem"
              type="submit"
            >
              {this.state.isSubmitting ? <LoadingOutlined /> : 'Login'}
            </button>
          </div>
        </form>

        <Modal
          title="Verify Phone Number!"
          centered 
          open={this.state.showOTPModal}
          onOk={() => this.setState({ showOTPModal: false })}
          onCancel={() => this.setState({ showOTPModal: false })}
          footer={null}
        >
          <LoginOTPForm handleSuccess={() =>  {
            this.props.setUser(this.state.userData);
            this.setState({ showOTPModal: false, redirectToReferrer: true });
          }} authToken={this.state.userData.token} />
        </Modal>
      </React.Fragment>
    );
  }
}

LoginForm.propTypes = {
  setUser: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  useComponentIn: PropTypes.string,
  handleSuccess: PropTypes.func,
};

LoginForm.defaultProps = {
  useComponentIn: 'page',
  handleSuccess: () => {},
};

export default withRouter(WithUser(LoginForm));
