import React, { useState, useEffect } from 'react';
// import moment from 'moment'
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { getHeaders, lifeUrl } from '../../config';
import WithRegion from '../../hocs/WithRegion';
import { getCookie } from '../../utils/urls';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import SelectInput from '../../components/Forms/SelectInput';
// import DatePickerInput from './DatePickerInput'
import BasicInput from '../../components/Forms/BasicInput';
import WithProducts from '../../hocs/WithProducts';
import WithUser from '../../hocs/WithUser';
// import RadioInput from './RadioInput'

const LifeForm = props => {
  // eslint-disable-next-line react/prop-types
  const { lifePayload } = props;
  const [policy, setPolicy] = useState({
    // eslint-disable-next-line react/prop-types
    formType: lifePayload.formType || '',
    // eslint-disable-next-line react/prop-types
    sumAssured: lifePayload.sumAssured || null,
    // eslint-disable-next-line react/prop-types
    premium: lifePayload.premium || null,
    // eslint-disable-next-line react/prop-types
    age: lifePayload.age || null,
    // eslint-disable-next-line react/prop-types
    duration: lifePayload.duration || null,
    // eslint-disable-next-line react/prop-types
    planType: lifePayload.planType || null,
    // eslint-disable-next-line react/prop-types
    referrer: lifePayload.referrer || '',
  });

  // const formTypeOptions = [
  //   {
  //     value: 'premium',
  //     label: 'Monthly Contribution',
  //     description:
  //       "tell us how much you're willing to pay monthly. Then we calculate for your total benefit."
  //   },
  //   {
  //     value: 'sum_assured',
  //     label: 'Sum Assured (Benefits)',
  //     description:
  //       'tell us how much you want at the end of policy. Then we calculate for your monthly contribution.'
  //   }
  // ]

  const [formSection, setFormSection] = useState(1);
  // const [region, setRegion] = useState('')
  // eslint-disable-next-line
  const [referrer, setReferrer] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  // eslint-disable-next-line
  const [affiliateReferrer, setAffiliateReferrer] = useState(false)

  const removeErrorMessage = stateName => {
    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        nonFieldErrors: null,
      });
    }
  };

  const handleSelectUpdate = stateName => value => {
    setPolicy({ ...policy, [stateName]: value });

    // remove form field errors
    removeErrorMessage(stateName);
  };

  const handleFieldUpdate = stateName => event => {
    setPolicy({ ...policy, [stateName]: event.target.value });

    // remove form field errors
    removeErrorMessage(stateName);
  };

  // const handleDateUpdate = stateName => date => {
  //   setPolicy({ ...policy, [stateName]: date })

  //   // remove form field errors
  //   removeErrorMessage(stateName)
  // }

  const handleSubmit = async () => {
    setIsSubmitting(true);

    axios
      .post(`${lifeUrl}/preview`, policy, { headers: getHeaders() })
      .then(res => {
        // eslint-disable-next-line react/prop-types
        props.setLifeProducts(res.data.data);
        // eslint-disable-next-line react/prop-types
        props.setLifePayload(policy);
        // eslint-disable-next-line react/prop-types
        props.history.push('/buy-life-insurance/compare');

        // this.scrollTo()
        setIsSubmitting(false);
      })
      .catch(err => {
        setIsSubmitting(false);
        if (err.response) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          err.response.status === 400
            ? setErrorMessage({
              ...err.response.data,
              nonFieldErrors: ["Kindly make sure you've completed the form"],
            })
            : setErrorMessage({
              ...errorMessage,
              nonFieldErrors: ['Oops, server Error! Retry'],
            });
        } else {
          setErrorMessage({
            ...errorMessage,
            nonFieldErrors: ['Error Connecting to Server, Retry.'],
          });
        }
      });
  };

  const handleNextSection = () => {
    setFormSection(formSection + 1);
  };

  useEffect(() => {
    if (getCookie('referrer')) {
      setAffiliateReferrer(true);
      setReferrer(getCookie('referrer'));
    }
  }, []);

  let ages = [];
  for (let i = 18; i < 60; i++) {
    ages.push({ label: i, value: i });
  }

  let durations = [];
  for (let i = 5; i < 18; i++) {
    durations.push({ label: i, value: i });
  }

  let coverOptions = [{ label: 'Assured Child Education', value: 'child' }];

  return (
    <Form
      onFinish={formSection === 2 ? handleSubmit : handleNextSection}
      layout="vertical"
      className="motor_form"
    >
      {formSection === 1 ? (
        <div className="row">
          <h3 className="mb-2rem">Start your insurance cover</h3>
          <div className="col-md-12 mb-2rem">
            <SelectInput
              label={'Kindly select the life plan you want'}
              value={policy.planType}
              name={'policy cover'}
              optionList={coverOptions}
              onChange={handleSelectUpdate('planType')}
              placeholder="select life cover"
              required={true}
            />
            {errorMessage.planType
              ? errorMessage.planType.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div className="col-md-12 mb-2rem">
            {/* <label></label> */}
            {/* <RadioInput
              name={'type of form'}
              label="What do you want to calculate for?"
              optionList={formTypeOptions}
              value={policy.formType}
              onChange={handleFieldUpdate('formType')}
              required={true}
            /> */}
            <div>
              <label>What do you want to calculate for?</label>

              <div
                style={{
                  border: '1px solid whitesmoke',
                  marginBottom: '1rem',
                  padding: '1rem',
                }}
              >
                <input
                  type="radio"
                  id={'premium'}
                  name={'calculation'}
                  value={'premium'}
                  onChange={handleFieldUpdate('formType')}
                  required
                  className="mr-1rem"
                />
                <label htmlFor={'premium'}>{'Monthly Contribution'}</label>
                <p style={{ color: 'gray' }}>
                  {
                    "tell us how much you're willing to pay monthly. Then we calculate for your total benefit."
                  }
                </p>
              </div>
              <div
                style={{
                  border: '1px solid whitesmoke',
                  marginBottom: '1rem',
                  padding: '1rem',
                }}
              >
                <input
                  type="radio"
                  id={'sum_assured'}
                  name={'calculation'}
                  value={'sum_assured'}
                  onChange={handleFieldUpdate('formType')}
                  required
                  className="mr-1rem"
                />
                <label htmlFor={'sum_assured'}>{'Sum Assured (Benefits)'}</label>
                <p style={{ color: 'gray' }}>
                  {
                    'tell us how much you want at the end of policy. Then we calculate for your monthly contribution.'
                  }
                </p>
              </div>
            </div>

            {errorMessage.formType
              ? errorMessage.formType.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>
        </div>
      ) : null}

      {formSection === 2 ? (
        <div className="row">
          <h3 className="mb-2rem">Tell us more about your life cover</h3>
          <div className="col-md-12 mb-2rem">
            <SelectInput
              label={
                'Kindly select how long you want the life cover (in years)'
              }
              value={policy.duration}
              name={'policy duration'}
              optionList={durations}
              onChange={handleSelectUpdate('duration')}
              placeholder="select duration (years)"
              required={true}
            />
            {errorMessage.duration
              ? errorMessage.duration.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          {policy.formType === 'premium' ? (
            <div className="col-md-12 mb-2rem">
              <BasicInput
                label={
                  "Kindly enter how much you're willing to pay monthly (in cedis)"
                }
                value={policy.premium}
                name="premium"
                placeholder="enter amount here"
                handleChange={handleFieldUpdate('premium')}
                required={true}
              />
              {errorMessage.premium
                ? errorMessage.premium.map((err, i) => (
                    <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
            </div>
          ) : null}

          {policy.formType === 'sum_assured' ? (
            <div className="col-md-12 mb-2rem">
              <BasicInput
                label={
                  'Kindly enter how much money you want at the end of the policy (in cedis)'
                }
                value={policy.sumAssured}
                name="sumAssured"
                placeholder="enter amount here"
                handleChange={handleFieldUpdate('sumAssured')}
                required={true}
              />
              {errorMessage.sumAssured
                ? errorMessage.sumAssured.map((err, i) => (
                    <p key={i} style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
                : null}
            </div>
          ) : null}

          <div className="col-md-12 mb-2rem">
            <SelectInput
              label={'Kindly select your age'}
              value={policy.age}
              name={'age'}
              optionList={ages}
              onChange={handleSelectUpdate('age')}
              placeholder="select your age"
              required={true}
            />
            {errorMessage.age
              ? errorMessage.age.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>

          <div>
            {errorMessage.nonFieldErrors
              ? errorMessage.nonFieldErrors.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
              ))
              : null}
          </div>
        </div>
      ) : null}

      <div className="motor_form_buttons">
        <button
          className="btn_normal btn_dark btn_md"
          type="button"
          onClick={() =>
            setFormSection(formSection > 1 ? formSection - 1 : formSection)
          }
          style={formSection !== 1 ? {} : { visibility: 'hidden' }}
        >
          Back
        </button>

        <button className="btn_normal btn_gradient btn_md" type="submit">
          {isSubmitting ? (
            <LoadingOutlined />
          ) : formSection === 2 ? (
            'Submit'
          ) : (
            'Continue'
          )}
        </button>
      </div>
    </Form>
  );
};

export default WithUser(WithProducts(WithRegion(withRouter(LifeForm))));
