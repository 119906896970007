import React, { Component } from 'react';

import ComingSoonPage from './ComingSoonPage';

class LifePage extends Component {
  render() {
    return <ComingSoonPage />;
  }
}

export default LifePage;
