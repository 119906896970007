import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem, Divider } from 'rc-menu';
import PropTypes from 'prop-types';

class LifeUserPolicyCard extends Component {
  render() {
    let { policy } = this.props;
    let { id } = policy;
    let { premium } = policy;
    let { company } = policy;
    let { user } = policy;
    let { status } = policy;
    let { plan } = policy;

    let nfExact = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const menuActive = (
      <Menu>
        <MenuItem key="1" className="pointer">
          <span className="mr-1rem mb-1rem">
            <Link
              to={`/account/claims/life/new/${id}`}
              className="navbar__link"
            >
              Make Claim
            </Link>
          </span>
        </MenuItem>
        <Divider />
        <MenuItem key="3">
          <Link to={`/account/policies/life/${id}`} className="navbar__link">
            View More
          </Link>
        </MenuItem>
      </Menu>
    );

    const menuUnpaid = (
      <Menu>
        <MenuItem key="1">
          <Link to={`/account/policies/life/${id}`} className="navbar__link">
            View More
          </Link>
        </MenuItem>
      </Menu>
    );

    const menus = {
      U: menuUnpaid,
      A: menuActive,
      PR: menuActive,
      PA: menuActive,
      CA: menuActive,
      C: menuActive,
      F: menuActive,
      P: menuActive,
      D: menuActive,
    };

    return (
      <div className="user-dash-card mr-2rem mb-2rem">
        {!isEmpty(policy) && (
          <React.Fragment>
            <div style={{ flex: '1' }}>
              <div className="space-between">
                <span className="user-dash-card__category">
                  {plan.planType}
                </span>
                {/* <span className="user-dash-card__category">{`${MOTOR_PRODUCT_TYPE_CHOICES[motorType]} - ${motorPolicyInfo.duration} months`}</span> */}
                <Dropdown
                  trigger={['click']}
                  overlay={menus[status]}
                  animation="slide-up"
                >
                  <span className="user-dash-card__options-toggle">...</span>
                </Dropdown>
              </div>

              <p className="text--muted user-dash-card__description">
                for {user.firstName} {user.lastName}
              </p>
            </div>

            <div className="space-between">
              <span className="user-dash-card__premium align-end">
                GHS {nfExact.format(premium.amount)}
              </span>
              <img
                className="user-dash-card__logo"
                src={company.logoUrl}
                alt="Insurer logo"
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
LifeUserPolicyCard.propTypes = {
  policy: PropTypes.shape({
    id: PropTypes.number,
    premium: PropTypes.number,
    status: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    company: PropTypes.shape({
      logoUrl: PropTypes.string,
    }),
    plan: PropTypes.object,
  }),
};

export default withRouter(
  connect(state => ({
    authUser: state.authUser,
  }))(LifeUserPolicyCard),
);
