import countryList from 'react-select-country-list';

export function getPremiumFromAges(premiums, ages) {
  let total = 0;
  for (let i = 0; i < ages.length; i++) {
    let premium = premiums.find(prem => {
      let upper = parseInt(prem.ageRange.upper, 10);
      let lower = parseInt(prem.ageRange.lower, 10);
      let intAge = parseInt(ages[i], 10);
      return intAge >= lower && intAge < upper;
    });

    if (!premium) {
      return null;
    }
    total += parseFloat(premium.premium);
  }
  return total;
}

export function getBreakdownValueByLabel(breakdowns, label) {
  for (let i = 0; i < breakdowns.length; i++) {
    if (breakdowns[i].label.toLowerCase() === label.toLowerCase()) {
      if (breakdowns[i].value) {
        return breakdowns[i].value;
      } else return 'N/A';
    }
  }

  return '';
}

export const formatProducts = (productsList, search) => {
  let formattedProducts = null;

  // sort travel premiums in order of age range
  formattedProducts = productsList.map(product => {
    let travelPremiums = product.travelPremiums;

    travelPremiums.sort(
      (a, b) =>
        b.ageRange.upper -
        b.ageRange.lower -
        (a.ageRange.upper - a.ageRange.lower),
    );
    product.travelPremiums = travelPremiums;
    return product;
  });

  // convert all premiums to local currency
  formattedProducts = formattedProducts.map(p => {
    let newLocalPremiums = p.travelPremiums.map(tp => {
      return {
        ...tp,
        premium: tp.localPremium,
      };
    });
    // debugger

    return {
      ...p,
      currency: 'GHS',
      travelPremiums: newLocalPremiums,
    };
  });

  // sort products by local currency premiums
  formattedProducts = formattedProducts.sort((a, b) => {
    const premiumA = getPremiumFromAges(a.travelPremiums, search.travellerAges);

    const premiumB = getPremiumFromAges(b.travelPremiums, search.travellerAges);

    return premiumA - premiumB;
  });

  // for products for product card.
  formattedProducts = formattedProducts.map((p, i) => {
    let premium = p.travelPremiums[0].premium;

    if (search.travellerAges.length > 0 && !p.travelInfo.isFamily) {
      premium = getPremiumFromAges(p.travelPremiums, search.travellerAges);
    }

    if (i === 4) {
      // for showing other usable content between policy listings
      return {
        isPolicy: false,
      };
    } else {
      return {
        ...p,
        isPolicy: true,
        premium: {
          currency: p.currency,
          amount: premium,
        },
        companyLogo: p.company.logoUrl,
        companyName: p.company.name,
        companyId: p.company.id,
        compare: true,
        showDetail: true,
      };
    }
  });

  return formattedProducts;
};

export const formatCountries = countries_ => {
  // we want only want country names here. this handles old and 
  // new versions of our saved payload.
  let formatedCountries = countries_.map(country => {
    // for when it is the country code: us, gh, de
    if (country.length === 2) {
      return countryList().getLabel(country.toLowerCase());
    }
    
    // for when it is the country name itself: ghana, kenya.
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let country__ = countryList().getValue(country.toLowerCase());
    return countryList().getLabel(country__.toLowerCase());
  });
  return formatedCountries;
};

// products.sort((a, b) => {
//   if (a.travelInfo.isFamily && !b.travelInfo.isFamily) return -1
//   if (!a.travelInfo.isFamily && b.travelInfo.isFamily) return 1
//   return 0
// })

// const getRandomProducts = () => {
//   axios.get(`${productsUrl}/featured-travel`).then(res => {
//     let allProducts = res.data.results.map(product => {
//       product.isToggled = false
//       return product
//     })

//     props.setProducts(allProducts)
//     setPolicies(formatProducts(allProducts, search))
//   })
// }
  