import { constants } from '../../app-constants';

const initialProducts = [];
const initialMotorProducts = [];
// const initialMotorCompanyProducts = []
const initialPropertyProducts = [];
// const initialPropertyCompanyProducts = []
const initialLifeProducts = [];
const initialLifeCompanyProducts = [];
const initialHealthProducts = [];
// const initialHealthCompanyProducts = []
const initialCompareProducts = [];

const initialPayload = JSON.parse(
  localStorage.getItem('bestquotePayload') || '{}',
);
const initialMotorPayload = JSON.parse(
  localStorage.getItem('bestquoteMotorPayload') || '{}',
);
const initialPropertyPayload = JSON.parse(
  localStorage.getItem('bestquotePropertyPayload') || '{}',
);
const initialLifePayload = JSON.parse(
  localStorage.getItem('bestquoteLifePayload') || '{}',
);
const initialLifeCompanyPayload = JSON.parse(
  localStorage.getItem('bestquoteLifeCompanyPayload') || '{}',
);
const initialHealthPayload = JSON.parse(
  localStorage.getItem('bestquoteHealthPayload') || '{}',
);
const minimizeMessenger = false;

// TODO: Limit no. of selected policies to 3
// Policy reducers

// eslint-disable-next-line @typescript-eslint/default-param-last
export const products = (state = initialProducts, action) => {
  switch (action.type) {
    case constants.SET_PRODUCTS:
      return action.payload;
    case constants.TOGGLE_PRODUCT:
      return state.map(policy => {
        if (policy.id !== action.payload) {
          return policy;
        }
        return {
          ...policy,
          isToggled: !policy.isToggled,
        };
      });
    case constants.CLEAR_PRODUCT_TOGGLES:
      return state.map(policy => {
        return {
          ...policy,
          isToggled: false,
        };
      });
    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const motorProducts = (state = initialMotorProducts, action) => {
  switch (action.type) {
    case constants.SET_MOTOR_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const healthProducts = (state = initialHealthProducts, action) => {
  switch (action.type) {
    case constants.SET_HEALTH_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const lifeProducts = (state = initialLifeProducts, action) => {
  switch (action.type) {
    case constants.SET_LIFE_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const lifeCompanyProducts = (state = initialLifeCompanyProducts, action) => {
  switch (action.type) {
    case constants.SET_LIFE_COMPANY_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const propertyProducts = (state = initialPropertyProducts, action) => {
  switch (action.type) {
    case constants.SET_PROPERTY_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const compareProducts = (state = initialCompareProducts, action) => {
  switch (action.type) {
    case constants.SET_COMPARE_PRODUCTS:
      return action.payload;

    case constants.CLEAR_COMPARE_PRODUCTS:
      return [];
    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const showMessenger = (state = minimizeMessenger, action) => {
  switch (action.type) {
    case constants.TOGGLE_MESSENGER:
      return !state;

    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const payload = (state = initialPayload, action) => {
  switch (action.type) {
    case constants.SET_PAYLOAD:
      localStorage.setItem('bestquotePayload', JSON.stringify(action.payload));
      return action.payload;

    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const motorPayload = (state = initialMotorPayload, action) => {
  switch (action.type) {
    case constants.SET_MOTOR_PAYLOAD:
      localStorage.setItem(
        'bestquoteMotorPayload',
        JSON.stringify(action.payload),
      );
      return action.payload;

    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const propertyPayload = (state = initialPropertyPayload, action) => {
  switch (action.type) {
    case constants.SET_PROPERTY_PAYLOAD:
      localStorage.setItem(
        'bestquotePropertyPayload',
        JSON.stringify(action.payload),
      );
      return action.payload;

    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const lifePayload = (state = initialLifePayload, action) => {
  switch (action.type) {
    case constants.SET_LIFE_PAYLOAD:
      localStorage.setItem(
        'bestquoteLifePayload',
        JSON.stringify(action.payload),
      );
      return action.payload;

    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const lifeCompanyPayload = (state = initialLifeCompanyPayload, action) => {
  switch (action.type) {
    case constants.SET_LIFE_COMPANY_PAYLOAD:
      localStorage.setItem(
        'bestquoteLifeCompanyPayload',
        JSON.stringify(action.payload),
      );
      return action.payload;

    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const healthPayload = (state = initialHealthPayload, action) => {
  switch (action.type) {
    case constants.SET_HEALTH_PAYLOAD:
      localStorage.setItem(
        'bestquoteHealthPayload',
        JSON.stringify(action.payload),
      );
      return action.payload;

    default:
      return state;
  }
};
