import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LifeBannerPage from './LifeBanner';
import LifeForm from './LifeSearchForm';
import LifePartners from './LifePartners';
import bannerImage from '../../img/life/life-main-banner.svg';
import { Modal } from 'antd';
import Navbar from '../../components/Navbar';

const LifeMainPage = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      <Navbar />

      <div className="motor">
        <div className="row">
          <div className="col-md-6 col-xs-12 motor_banner_section">
            <LifeBannerPage
              bannerImage={bannerImage}
              moduleName="Life"
              price="your preferred price"
            />
          </div>
          <div className="col-md-6 motor_form_section">
            <LifeForm />
          </div>
        </div>

        <div className="row motor_partners_section pb-5rem">
          <div className="col-md-7">
            <LifePartners />
          </div>
        </div>

        <div className="motor_floating_section">
          <button
            type="button"
            className="btn btn--gradient-primary mb-2rem motor_floating_section_button"
            onClick={() => setShowModal(true)}
          >
            Get Started Now
          </button>
        </div>

        <Modal
          title=""
          centered
          width={'90%'}
          open={showModal}
          onOk={() => setShowModal(false)}
          onCancel={() => setShowModal(false)}
          footer={null}
        >
          <div className="motor_form_wrapper">
            <LifeForm />
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

LifeMainPage.propTypes = {
  match: PropTypes.object,
};

export default LifeMainPage;
