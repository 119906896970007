import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../components/Navbar';
import privacy from '../img/protect-data.svg';

class PrivacyPage extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <main className="center-auto mt-10rem wrapper--sub">
          <div
            className=" mt-5rem"
            style={{ maxWidth: '800px', lineHeight: '2.5rem' }}
          >
            <div>
              <img src={privacy} className="mr-1rem" alt="" />
              <h3 className="inline-block mb-2rem text--aqua">
                Privacy Policy
              </h3>
            </div>
            <p className="mb-5rem">
              <span className="block mb-2rem">
                At BestQuote Ghana Limited (“BestQuote”) we recognize that
                privacy is important. BestQuote Privacy Policy applies to all
                the products, services and website offered by BestQuote. We are
                keenly aware of the trust our users place in us, and our
                responsibility to protect their privacy.{' '}
              </span>

              <span className="block mb-2rem">
                We believe transparency and choice are the foundations of
                privacy. We strive to let users know what information we collect
                when they use our services and describes how we treat personal
                information provided. You accepted this policy when you signed
                up for our BestQuote.
              </span>

              <span className="block mb-2rem">
                <strong>
                  <em>Last Updated: July 10, 2018</em>
                </strong>
              </span>
            </p>

            <h4 className="mb-2rem">
              Information We Collect and How We Use It{' '}
            </h4>
            <p className="mb-2rem">
              <span className="block">
                Our primary purpose in collecting personal information is to
                provide you with a safe, smooth, efficient, and customised
                experience. When you sign up for a BestQuote account, we ask you
                for personal information such as your name, email address, an
                account password, credit card or other payment account
                information which we maintain in encrypted form on secure
                servers. We may use your personal information to:
              </span>
            </p>

            <ul className="ml-2rem mb-5rem ">
              <li>Provide the services and customer support you request;</li>
              <li>
                Process transactions and send notices about your transactions;
              </li>
              <li>
                Resolve disputes, collect fees, and troubleshoot problems;
              </li>
              <li>
                Prevent potentially prohibited or illegal activities, and
                enforce our terms of service;
              </li>
              <li>
                Customise, measure, and improve our services and the content and
                layout of our website; and
              </li>
              <li>
                Compare information for accuracy and verify it with third
                parties.
              </li>
            </ul>

            <h4 className="mb-2rem">Cookies</h4>
            <p className="mb-5rem">
              {`When you visit BestQuote, we send one or more cookies - a small
              file containing a string of characters - to your computer that
              uniquely identifies your browser. We use cookies to improve the
              quality of search on BestQuote by storing user preferences and
              tracking user trends, such as how people search. We send a
              "session cookie" to your computer when you log in to your account.
              This type of cookie helps us to recognize you if you visit
              multiple pages on our site during the same session, so that we
              don't need to ask you for your password on each page. Once you log
              out or close your browser, this cookie expires and no longer has
              any effect. For more information on how we use cookies please read
              our`}{' '}
              <Link to="/cookies" className="navbar__link--active">
                cookie policy
              </Link>
              .
            </p>

            <h4 className="mb-2rem">Log information</h4>
            <p className="mb-5rem">
              When you access a search on BestQuote, our servers automatically
              record information that your browser sends whenever you visit a
              website. These server logs may include information such as your
              web request, Internet Protocol address, browser type, browser
              language, the date and time of your request and one or more
              cookies that may uniquely identify your browser. Also, to protect
              you from fraud and other misconduct, we may collect information
              about your interaction with our services. Any such information we
              collect will only be used to detect and prevent fraud or other
              misconduct.
            </p>

            <h4 className="mb-2rem">User communication</h4>
            <p className="mb-5rem">
              When you send email or other communications to BestQuote, we may
              retain those communications to process your inquiries, respond to
              your requests and improve our services.
            </p>

            <h4 className="mb-2rem">Information Sharing</h4>
            <p className="mb-2rem">
              <span className="block mb-2rem">
                BestQuote only shares personal information with other companies
                or individuals outside of BestQuote in the following limited
                circumstances:
              </span>

              <span className="block mb-2rem">
                • We provide such information to the insurance companies or
                other trusted businesses or persons for processing personal
                information on our behalf. We require that these parties agree
                to process such information based on our instructions and in
                compliance with this Privacy Policy and any other appropriate
                confidentiality and security measures.
              </span>

              <span className="block mb-2rem">
                • We have a good faith belief that access, use, preservation or
                disclosure of such information is reasonably necessary to:
              </span>
            </p>

            <ul className="ml-5rem mb-2rem">
              <li>
                Satisfy any applicable law, regulation, legal process or
                enforceable governmental request,
              </li>
              <li>
                Process transactions and send notices about your transactions;
              </li>
              <li>
                Enforce applicable Terms of Service, including investigation of
                potential violations thereof,{' '}
              </li>
              <li>
                Detect, prevent, or otherwise address fraud, security or
                technical issues, or{' '}
              </li>
              <li>
                Customise, measure, and improve our services and the content and
                layout of our website; and
              </li>
              <li>
                Protect against imminent harm to the rights, property or safety
                of BestQuote, its users or the public as required or permitted
                by law.
              </li>
            </ul>

            <span className="block mb-5rem">
              • We may share with third parties certain pieces of aggregated,
              non-personal information, such as the number of users who searched
              for a particular product. Such information does not identify you
              individually.
            </span>

            <h4 className="mb-2rem">Information Security</h4>
            <p className="mb-5rem">
              We take appropriate security measures to protect against
              unauthorised access to or unauthorised alteration, disclosure or
              destruction of data. These include internal reviews of our data
              collection, storage and processing practices and security
              measures, as well as physical security measures to guard against
              unauthorised access to systems where we store personal data. The
              security of your account also depends on keeping your account
              password confidential. We restrict access to personal information
              by BestQuote employees and the insurance companies who need to
              know that information to operate, develop or improve our services.
              These individuals are bound by confidentiality obligations.
            </p>

            <h4 className="mb-2rem">Data integrity</h4>
            <p className="mb-5rem">
              BestQuote processes personal information only for the purposes for
              which it was collected and in accordance with this Privacy Policy
              or any applicable service-specific privacy notice. We review our
              data collection, storage and processing practices to ensure that
              we only collect, store and process the personal information needed
              to provide or improve our services. We take reasonable steps to
              ensure that the personal information we process is accurate,
              complete, and current, but we depend on our users to update or
              correct their personal information whenever necessary.
            </p>

            <h4 className="mb-2rem">Data Protection </h4>
            <p className="mb-5rem">
              BestQuote is registered with the Data Protection Commission
              (“Commission”), registration number DC17060000809B , which is an
              independent statutory body established under the Data Protection
              Act, 2012 (Act 843) to protect the privacy and personal data of
              the customer by regulating the processing of personal information.
              The Commission provides for the process to obtain, hold, use or
              disclose personal information and for other related issues
              bordering on the protection of personal data.
            </p>

            <h4 className="mb-2rem">
              Accessing and Updating Personal Information
            </h4>
            <p className="mb-5rem">
              When you use BestQuote website, we provide you access to your
              personal information and either to correct this data if it is
              inaccurate or to delete such data at your request if it is not
              otherwise required to be retained by law or for legitimate
              business purposes. We ask individual users to identify themselves
              and the information requested to be accessed, corrected or removed
              before processing such requests, and we may decline to process
              requests that are unreasonably repetitive or systematic, require
              disproportionate technical effort, jeopardise the privacy of
              others, or would be extremely impractical, or for which access is
              not otherwise required.
            </p>

            <h4 className="mb-2rem">Enforcement</h4>
            <p className="mb-5rem">
              BestQuote regularly reviews its compliance with this Privacy
              Policy. Please feel free to direct any questions or concerns
              regarding this Privacy Policy or BestQuote’s treatment of personal
              information by contacting us through this website.
            </p>

            <h4 className="mb-2rem">Changes to this Privacy Policy</h4>
            <p className="mb-5rem">
              <span className="block mb-2rem">
                Please note that this Privacy Policy may change from time to
                time. We will not reduce your rights under this Privacy Policy
                without your explicit consent, and we expect that such changes
                will be minor. Regardless, we will post any Privacy Policy
                changes on this page and, if the changes are significant, we
                will provide a more prominent notice including, for certain
                services, email notification of Privacy Policy changes.
              </span>

              <span className="block mb-2rem">
                If you have any additional questions or concerns about this
                Privacy Policy, please feel free to{' '}
                <Link to="/contact" className="navbar__link--active">
                  contact us
                </Link>{' '}
                any time through this website.
              </span>
            </p>
          </div>
        </main>
      </div>
    );
  }
}

export default PrivacyPage;
