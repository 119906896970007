import React from 'react';
import PropTypes from 'prop-types';
import bqLogo from '../../img/bestquote-logo.png';
import LoginForm from './LoginForm';

const LoginPopUp = props => {
  return (
      <React.Fragment>
       <div className='auth_popup'>
          <div className='auth_popup__bounding-box'> 
          <div className="auth_popup__top">
            <img
              src={bqLogo}
              alt="BestQuote Logo"
              className="bq-logo bq-logo--auth mb-1rem"
            />
          </div>
          <div>
            <hr />
          </div>
          <div className="auth_popup__caption mb-5rem">
            <small>Login into your account to proceed</small>
          </div>
          <LoginForm handleSuccess={() => props.handleSuccess()} useComponentIn="modal" />
           </div>
         </div>
      </React.Fragment>
  );
};

LoginPopUp.propTypes = {
  handleSuccess: PropTypes.func,
};

LoginPopUp.defaultProps = {
  handleSuccess: () => {},
};

export default LoginPopUp;
