import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import PropertyPage from './PropertyPage';

import PageNotFound from '../PageNotFound';
import Footer from '../../components/Footer';

const PropertyRoutes = props => (
  <>
    <Switch>
      <Route exact path={props.match.path} component={PropertyPage} />
      <Route component={PageNotFound} />
    </Switch>
    <Footer />
  </>
);
PropertyRoutes.propTypes = {
  match: PropTypes.object,
};

export default PropertyRoutes;
