import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import MotorPage from './MotorMainPage';
import PageNotFound from '../PageNotFound';
import MotorCompare from './MotorCompare';
import MotorBuy from './MotorBuy';
import Footer from '../../components/Footer';
import PrivateRoute from '../../hocs/PrivateRoute';
import MotorRenew from '../../components/Motor/MotorRenew';

const MotorRoutes = props => (
  <>
    <Switch>
      <Route exact path={props.match.path} component={MotorPage} />
      <Route
        exact
        path={`${props.match.path}/compare`}
        component={MotorCompare}
      />
      <PrivateRoute path={`${props.match.path}/:id/buy`} component={MotorBuy} />
      <PrivateRoute
        path={`${props.match.path}/:id/renew`}
        component={MotorRenew}
      />
      <Route component={PageNotFound} />
    </Switch>
    <Footer />
  </>
);

MotorRoutes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default MotorRoutes;
