import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import PrivateRoute from './hocs/PrivateRoute';
import WithProducts from './hocs/WithProducts';
import { getHeaders } from './config';
import { setCookie } from './utils/urls';
import { sendSomeSugar } from './utils/any';
import Meta from './components/Meta';
import ReactGA from 'react-ga4';
import FullStory from 'react-fullstory';
import { MessengerChat } from 'react-messenger-chat-plugin';

import LandingPage from './pages/LandingPage';
import DashboardRoutes from './pages/dashboard/DashboardRoutes';
import LifePage from './pages/LifePage';
import ContactPage from './pages/ContactPage';
import TravelRoutes from './pages/travel-insurance';
import PropertyRoutes from './pages/property/PropertyRoutes';
import MotorRoutes from './pages/motor-insurance';
import HealthRoutes from './pages/health-insurance';
import LifeRoutes from './pages/life-insurance';
import AuthRoutes from './pages/user-auth';
import PaymentRoutes from './components/Payments/PaymentRoutes';
import ToSPage from './pages/ToSPage';
import PrivacyPage from './pages/PrivacyPage';
import CookiePage from './pages/CookiePage';
import PageNotFound from './pages/PageNotFound';
import FAQPage from './pages/FAQPage';

import './styles/styles.css';

const fullstory_org = '13FWHV';

const App = (props: { setExchangeRate?: any; showMessenger: boolean }) => {
  let { showMessenger } = props;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE}/api/v1/exchange-rates/latest/`, {
        headers: getHeaders(),
      })
      .then((res) => {
        props.setExchangeRate(res.data.results);
      });

    // initialize react google analytics
    ReactGA.initialize('G-QPKLN53W9B');
    // ReactGA.pageview(window.location.pathname + window.location.search)

    // get url params
    const params = new URLSearchParams(window.location.search);
    // set reference code of an affiliate user.
    if (params.has('referrer')) {
      let expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 7);
      setCookie('referrer', params.get('referrer'), expiryDate);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Meta>
      <Router>
        <div className="app">
          {process.env.REACT_APP_ENVIRONMENT === 'production' ? (
            <FullStory org={fullstory_org} />
          ) : null}
          <MessengerChat
            pageId="485942981883225"
            greetingDialogDisplay="fade"
            debugMode={process.env.REACT_APP_ENVIRONMENT === 'local'}
            themeColor="#009b9c"
            loggedInGreeting=""
            loggedOutGreeting=""
          />
          <Switch>
            <Route exact path="/" component={LandingPage} />
            {/* <Route path="/travel" component={TravelRoutes} /> */}
            <Route path="/buy-travel-insurance" component={TravelRoutes} />
            <Route path="/auth" component={AuthRoutes} />
            <Route path="/buy-motor-insurance" component={MotorRoutes} />
            <Route path="/buy-health-insurance" component={HealthRoutes} />
            <Route path="/buy-life-insurance" component={LifeRoutes} />
            <Route path="/property" component={PropertyRoutes} />
            <Route path="/life" component={LifePage} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/terms-of-service" component={ToSPage} />
            <Route path="/privacy-policy" component={PrivacyPage} />
            <Route path="/cookies" component={CookiePage} />
            <PrivateRoute path="/account" component={DashboardRoutes} />
            <PrivateRoute path="/payments" component={PaymentRoutes} />
            <Route path="/faq" component={FAQPage} />
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </Router>
    </Meta>
  );
};

App.propTypes = {
  setExchangeRate: PropTypes.func,
};

export default WithProducts(App);
