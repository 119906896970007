import React, { Component } from 'react';
import axios from 'axios';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { FadeLoader } from '../../../components/UI/FadeLoader';
import PropTypes from 'prop-types';
import { getHeaders, propertyUrl } from '../../../config';

import WithUser from '../../../hocs/WithUser';
import PropertyUserPolicyCard from './PropertyUserPolicyCard';
import policyIcon from '../../../img/policy-icon.svg';

class MyHomePolicies extends Component {
  state = {
    loadingProperty: true,
    unpaidPropertyPolicies: [],
    activePropertyPolicies: [],

    width: 0,
    height: 0,
  };

  async componentDidMount() {
    try {
      let {
        data: { results },
      } = await axios.get(propertyUrl, {
        headers: getHeaders(),
      });
      let unpaidPropertyPolicies = results.filter(
        policy => policy.status === 'U' && policy.premium !== null,
      );

      let activePropertyPolicies = results.filter(
        policy => policy.status !== 'U' && policy.premium !== null,
      );

      this.setState({
        unpaidPropertyPolicies,
        activePropertyPolicies,
        loadingProperty: false,
      });
    } catch (err) {
      console.error(err);
    }

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  handleMakeClaim = () => {
    this.props.makeClaim();
    this.props.openModal();
  };

  handleMakeCancellation = () => {
    this.props.makeCancellation();
    this.props.openModal();
  };

  render() {
  
    return (
      <div className="user-dash__main">
        <p className="site-content__lead">Purchased Home Insurance Policies</p>
        <p className="text--muted mb-2rem">Click any policy to see more</p>

        <hr />

        {this.state.loadingProperty ? (
          <div className="mt-3rem">
            <FadeLoader />
          </div>
        ) : this.state.activePropertyPolicies.length ? (
          <div className="mt-2rem flex-row flex-wrap">
            {this.state.activePropertyPolicies.map(policy => (
              <PropertyUserPolicyCard key={policy.id} policy={policy} />
            ))}
          </div>
        ) : (
          <div className="mt-2rem flex-row flex-wrap">
            {this.state.width > 767 ? (
              <img src={policyIcon} alt="policy-icon" />
            ) : null}
            <p className={cx(this.state.width > 767 ? 'ml-2rem' : null)}>
              <span className="mr-2rem">You have no active policies.</span>
              <button
                className={cx(
                  'btn btn--gradient-border',
                  this.state.width > 767 ? null : 'mt-1rem',
                )}
              >
                <Link to="/property">Buy Home Insurance</Link>
              </button>
            </p>
          </div>
        )}

        <div className="mb-5rem" />

        <p className="site-content__lead">Unpaid Home Insurance Policies</p>
        <p className="text--muted mb-2rem">Click on any policy to pay</p>
        <hr />

        {this.state.loadingProperty ? (
          <div className="mt-3rem">
            <FadeLoader />
          </div>
        ) : this.state.unpaidPropertyPolicies.length ? (
          <div className="mt-2rem flex-row flex-wrap">
            {this.state.unpaidPropertyPolicies.map(policy => (
              <PropertyUserPolicyCard key={policy.id} policy={policy} />
            ))}
          </div>
        ) : (
          <div className="mt-2rem flex-row flex-wrap">
            {this.state.width > 767 ? (
              <img src={policyIcon} alt="policy-icon" />
            ) : null}
            <p className={cx(this.state.width > 767 ? 'ml-2rem' : null)}>
              <span className="mr-2rem">You have no unpaid policies.</span>
              <button
                className={cx(
                  'btn btn--gradient-border',
                  this.state.width > 767 ? null : 'mt-1rem',
                )}
              >
                <Link to="/property">Buy Home Insurance</Link>
              </button>
            </p>
          </div>
        )}
      </div>
    );
  }
}
MyHomePolicies.propTypes = {
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
  makeClaim: PropTypes.func,
  openModal: PropTypes.func,
  makeCancellation: PropTypes.func,
};

export default WithUser(MyHomePolicies);
