import React, { Component } from 'react';

import Navbar from '../../components/Navbar';
import checkSign from '../../img/check-sign.svg';

export default class SignupSuccess extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <main style={{ minHeight: '60vh' }} className="site-content">
          <img style={{ maxHeight: '30px' }} src={checkSign} alt="BestQuote" />
          <p className="mt-1rem mb-1rem">Your account has been created!</p>
          <p className="mt-1rem mb-5rem">
            <span className="text--muted">
              Please check your email
              {/* </span> *email*
            <span className="text--muted"> */}{' '}
              to activate your account.
            </span>
          </p>
        </main>
      </React.Fragment>
    );
  }
}
