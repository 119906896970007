import React from 'react';
import { Space, Tag, Breadcrumb, Modal } from 'antd';
import axios from 'axios';
import * as pc from '../../../../app-constants/products';
import * as oc from '../../../../app-constants/policies';
import Travel from './Travel';
import { withRouter } from 'react-router-dom';
import { getHeaders, travelUrl } from '../../../../config';
import FilterTable from '../../../../components/Forms/searchFilter';
import countryList from 'react-select-country-list';

class Policies extends React.Component {
  state = {
    tableData: [],

    showModal: false,
    selected: null,
  };

  getCountry = countries_ => {
    let countries = '';
    countries_.forEach(c => {
      countries += `${countryList().getLabel(c)} `;
    });
    return countries;
  };

  // update tables
  updateTableResults() {
    axios
      .get(travelUrl, { headers: getHeaders() })
      .then(results => {
        // Handle table list results
        let policies = [];
        results.data.results.forEach(policy => {
          policy.clients.forEach(client => {
            policies.push({
              ...policy,
              ...client,
              customer: `${client.firstName} ${client.lastName}`,
              product: policy.product.name,
              productId: policy.product.id,
              company: policy.product.company.name,
              type: policy.product.type,
              typeName: pc.PRODUCT_TYPE_CHOICES[policy.product.type],
              premiumAmount: `${policy.premium.currency} ${policy.premium.amount}`,
              statusName: policy.validity,
              key: client.id,
              countries: this.getCountry(policy.travelInfo.countries),
              user: policy.user,
              policyId: policy.id,
              timestamp: new Date(policy.updatedAt).toDateString(),
              duration: `${policy.duration} Days`,
              payment: oc.POLICY_STATUS_CHOICES[policy.status],
              paymentColor: [oc.PAID, oc.ACTIVE].includes(policy.status)
                ? 'green'
                : 'red',
              status: policy.status,
            });
          });
        });
        // end travel request;

        this.setState({
          tableData: policies,
        });
      })
      .catch(() => {})
      .then(() => {});
  }
  // end update tables

  componentDidMount() {
    this.updateTableResults();
  }

  handleShowModal = () => {
    this.setState({
      showModal: true,
    });
  };

  handleOk = () => {
    this.setState({
      showModal: false,
    });
  };

  handleCancel = () => {
    this.setState(
      {
        showModal: false,
      },
      () => this.updateTableResults(),
    );
  };

  handleInputChange = stateName => event => {
    this.setState({ [stateName]: event.target.value });
  };

  render() {
    // eslint-disable-next-line react/prop-types
    if (this.props.isLoading) return null;
    
    let travelDetailRow = (record) => ({
      onClick: () => {
        this.setState({ selected: record, showModal: true });
      },
    });

    let { tableData } = this.state;

    const Columns = [
      {
        title: 'Date',
        dataIndex: 'timestamp',
        key: 'timestamp',
        width: 100,
      },
      {
        title: 'Policy Type',
        dataIndex: 'product',
        key: 'product',
        width: 80,
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
        width: 100,
      },
      {
        title: 'Amount',
        dataIndex: 'premiumAmount',
        key: 'premiumAmount',
        width: 80,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        width: 120,
      },
      {
        title: 'Payment',
        dataIndex: 'payment',
        key: 'payment',
        width: 80,
        render: (text, record) => (
          <Space size="middle">
            <Tag color={record.paymentColor}>{text}</Tag>
          </Space>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'statusName',
        key: 'statusName',
        width: 80,
        // filters: [
        //   {
        //     text: 'Unpaid',
        //     value: oc.UNPAID
        //   },
        //   {
        //     text: 'Paid',
        //     value: oc.ACTIVE
        //   },
        //   {
        //     text: 'Inactive',
        //     value: oc.INACTIVE
        //   },
        //   {
        //     text: 'Cancelled',
        //     value: oc.CANCELLED
        //   }
        // ],
        // onFilter: (value, record) => record.status === value
      },
      {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 100,
        render: () => (
          <Space size="middle">
            <span
              // onClick={handleDetail.bind(this, record)}
              style={{ color: 'blue', cursor: 'pointer' }}
            >
              View
            </span>
          </Space>
        ),
      },
    ];

    return (
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Travel Policies</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <Modal
            title="Travel Details"
            centered
            open={this.state.showModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
          >
            {this.state.showModal ? (
              <Travel
                handleCancel={this.handleCancel}
                policy={this.state.selected}
              />
            ) : null}
          </Modal>

          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <div className="utils__title">
                  <span>Travel Policies History</span>
                </div>
              </div>
              <div className="card-body">
                <div style={{ marginBottom: 16 }}>
                  <button
                    className="btn_normal btn_gradient btn_md"
                    onClick={() =>
                      // eslint-disable-next-line react/prop-types
                      this.props.history.push('/buy-travel-insurance')
                    }
                  >
                    Buy New Policy
                  </button>
                </div>

                <FilterTable
                  columns={Columns}
                  dataSource={tableData}
                  onRow={travelDetailRow}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Policies);
