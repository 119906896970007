import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FadeLoader } from '../../components/UI/FadeLoader';
import { getHeaders, motorUrl } from '../../config';

import Header from '../../components/Header';
// import ProductSearchSummary from "compoents/Product/ProductSearchSummary";
import MotorBuyForm from '../../components/Motor/MotorBuyForm';
// import { formatProducts, getPremiumFromAges } from 'utils/products'
import MotorProductBreakdown from '../../components/Motor/MotorProductBreakdown';

const MotorBuy = props => {
  const [policy, setPolicy] = useState({
    premium: {
      currency: '',
      amount: 0,
    },
    isReady: false,
  });

  const getPolicy = () => {
    axios
      .get(`${motorUrl}/${props.match.params.id}/get`, {
        headers: getHeaders(),
      })
      .then(res => {
        setPolicy({ ...res.data, isReady: true });
      });
  };

  useEffect(() => {
    getPolicy();
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Header>{/* <ProductSearchSummary /> */}</Header>

      <div className="wrapper mt-5rem mb-5rem">
        {policy.isReady ? (
          <div className="row reverse-md">
            <div className="col-md-7">
              <MotorBuyForm policy={policy} getPolicy={getPolicy} />
            </div>
            <div className="col-md-5 mb-2rem">
              <p className="mb-2rem">{"Here's a breakdown of your policy:"}</p>
              <MotorProductBreakdown
                product={policy}
                premium={policy.premium}
                isPurchasing={true}
              />
            </div>
          </div>
        ) : (
          <div className="site-content mb-10rem">
            <FadeLoader />
          </div>
        )}
      </div>
    </div>
  );
};

MotorBuy.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default withRouter(MotorBuy);
