export const UNPAID = 'U';
export const ACTIVE = 'A';
export const PAID = 'PA';
export const INACTIVE = 'I';
export const CANCELLED = 'C';
export const REFUND_REQUESTED = 'F';
export const REFUND_PROCESSED = 'P';
export const CLAIM_PENDING = 'C';
export const CLAIM_PROCESSED = 'D';

export const POLICY_STATUS_CHOICES = {
  [UNPAID]: 'Unpaid',
  [ACTIVE]: 'Paid',
  [PAID]: 'Paid',
  [INACTIVE]: 'Inactive',
  [CANCELLED]: 'Cancelled',
  [REFUND_REQUESTED]: 'Refund requested',
  [REFUND_PROCESSED]: 'Refund Processed',
  [CLAIM_PENDING]: 'Claim pending',
  [CLAIM_PROCESSED]: 'Claim processed',
};

export const SCHENGEN_COUNTRIES = [
  'at',
  'be',
  'ch',
  'cz',
  'de',
  'dk',
  'ee',
  'el',
  'es',
  'fi',
  'fr',
  'hu',
  'is',
  'it',
  'li',
  'lt',
  'lu',
  'lv',
  'mt',
  'nl',
  'no',
  'pl',
  'pt',
  'sw',
  'si',
  'sk',
];

export const NON_SCHENGEN_EU_COUNTRIES = ['bg', 'hr', 'ro', 'cy', 'ie', 'uk'];

export const TWO_EXCEPTION_COUNTRIES = ['us', 'ca'];

export const FOUR_EXCEPTION_COUNTRIES = ['us', 'ca', 'jp', 'au'];
