import React from 'react';
import PropTypes from 'prop-types';
import rectangle from '../../img/travel/travel-banner-box.svg';

const MotorBannerPage = props => {
  return (
    <div className="travel_banner">
      <h2 className="travel_banner_title">
        Explore the world with the best{' '}
        <span className="travel_banner_title_">
          {props.moduleName} Insurance
        </span>{' '}
        plans
      </h2>
      <img src={props.bannerImage} alt="banner" />

      <div className="travel_banner_tag">
        <div className="travel_banner_tag_item">
          <img src={rectangle} alt="pig-coin" />
          <p>Baggage delay/loss</p>
        </div>

        <div className="travel_banner_tag_item">
          <img src={rectangle} alt="pig-coin" />
          <p>Trip cancellation</p>
        </div>

        <div className="travel_banner_tag_item">
          <img src={rectangle} alt="pig-coin" />
          <p>Medical expenses and hospitalization abroad</p>
        </div>

        <div className="travel_banner_tag_item">
          <img src={rectangle} alt="pig-coin" />
          <p>Accidental death by means of transport</p>
        </div>

        <div className="travel_banner_tag_item">
          <img src={rectangle} alt="pig-coin" />
          <p>Delayed departure</p>
        </div>

        <div className="travel_banner_tag_item">
          <img src={rectangle} alt="pig-coin" />
          <p>Cancelled/delayed flight</p>
        </div>
      </div>
    </div>
  );
};

MotorBannerPage.propTypes = {
  match: PropTypes.object,
  bannerImage: PropTypes.any,
  moduleName: PropTypes.string,
  price: PropTypes.string,
};

export default MotorBannerPage;
