import React from 'react';
import PropTypes from 'prop-types';

import Backdrop from './Backdrop';

const Modal = props => (
  <div>
    <Backdrop
      backdropStyle={props.backdropStyle}
      show={props.show}
      onClick={props.handleCloseModal}
      // clicked={props.closeModal}
    />
    <div
      className={props.modalStyle}
      style={{
        display: props.show ? 'block' : 'none',
      }}
    >
      {!props.nonClosable ? (
        <span
          onClick={props.handleCloseModal}
          className="float--right text--muted"
          style={{ fontSize: '3rem', cursor: 'pointer' }}
        >
          &times;
        </span>
      ) : null}
      {props.children}
    </div>
  </div>
);
Modal.propTypes = {
  backdropStyle: PropTypes.string,
  show: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  modalStyle: PropTypes.string,
  nonClosable: PropTypes.bool,
  children: PropTypes.node,
};

export default Modal;
