import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BasicTooltip from './BasicTooltip';
import { DatePicker, Form } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';

const DatePickerInput = props => {
  const [showToolTip, setShowToolTip] = useState(false);

  const {
    toolTipLabel,
    name,
    label,
    required,
    disabled,
    value,
    handleChange,
    placeholder,
  } = props;
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    toolTipLabel && setShowToolTip(true);
    // eslint-disable-next-line
  }, [toolTipLabel])

  return (
    <React.Fragment>
      <Form.Item
        name={name}
        label={
          <>
            {label ? label : ''}{' '}
            {showToolTip ? (
              <>
                <BasicTooltip label={toolTipLabel} />
              </>
            ) : (
              ''
            )}
          </>
        }
        initialValue={value ? dayjs(value, 'YYYY-MM-DD') : null}
        rules={[
          {
            required: { required },
          },
        ]}
        colon={false}
      >
        <DatePicker
          value={value ? dayjs(value, 'YYYY-MM-DD') : null}
          defaultValue={value ? dayjs(value, 'YYYY-MM-DD') : null}
          placeholder={placeholder ? placeholder : 'YYYY-MM-DD'}
          format="YYYY-MM-DD"
          mode={'date'}
          picker={'date'}
          onChange={handleChange}
          style={{ width: '100%' }}
          className="form_input_muted"
          disabled={disabled ? true : false}
          // open={open}
          // focus={() => setOpen(false)}
        />
      </Form.Item>
    </React.Fragment>
  );
};

DatePickerInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  handleChange: PropTypes.func,
  handleDateChangeRaw: PropTypes.func,
  toolTipLabel: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default DatePickerInput;
