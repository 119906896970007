import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { FadeLoader } from '../../components/UI/FadeLoader';
import PropTypes from 'prop-types';
import {
  getHeaders,
  healthUrl,
  lifeUrl,
  motorUrl,
  propertyUrl,
  travelUrl,
} from '../../config';

import WithUser from '../../hocs/WithUser';
import PolicyClaimForm from './travel/PolicyClaimForm';
import MotorClaimForm from './motor/MotorClaimForm';
import PropertyClaimForm from './property/PropertyClaimForm';
import HealthClaimForm from './health/HealthClaimForm';
import LifeClaimForm from './life/LifeClaimForm';

class Motor extends React.Component {
  render() {
    let { policy } = this.props;
    return (
      <div className="user-dash__main">
        {policy ? (
          <MotorClaimForm product={policy} />
        ) : (
          <div style={{ width: '100%' }} className="center-auto">
            <FadeLoader />
          </div>
        )}
      </div>
    );
  }
}
Motor.propTypes = {
  policy: PropTypes.object,
};

class Health extends React.Component {
  render() {
    let { policy } = this.props;
    return (
      <div className="user-dash__main">
        {policy ? (
          <HealthClaimForm product={policy} />
        ) : (
          <div style={{ width: '100%' }} className="center-auto">
            <FadeLoader />
          </div>
        )}
      </div>
    );
  }
}
Health.propTypes = {
  policy: PropTypes.object,
};

class Life extends React.Component {
  render() {
  
    let { policy } = this.props;
    return (
      <div className="user-dash__main">
        {policy ? (
          <LifeClaimForm product={policy} />
        ) : (
          <div style={{ width: '100%' }} className="center-auto">
            <FadeLoader />
          </div>
        )}
      </div>
    );
  }
}
Life.propTypes = {
  policy: PropTypes.object,
};

class Property extends React.Component {
  render() {
   
    let { policy } = this.props;
    return (
      <div className="user-dash__main">
        {policy ? (
          <PropertyClaimForm product={policy} />
        ) : (
          <div style={{ width: '100%' }} className="center-auto">
            <FadeLoader />
          </div>
        )}
      </div>
    );
  }
}
Property.propTypes = {
  policy: PropTypes.object,
};
class Travel extends React.Component {
  render() {
   
    let { policy } = this.props;
    return (
      <div className="user-dash__main">
        {policy ? (
          <PolicyClaimForm product={policy.product} />
        ) : (
          <div style={{ width: '100%' }} className="center-auto">
            <FadeLoader />
          </div>
        )}
      </div>
    );
  }
}
Travel.propTypes = {
  policy: PropTypes.shape({
    product: PropTypes.object,
  }),
};

class NewClaim extends React.Component {
  state = {
    policy: null,
  };

  isTravel = this.props.match.path.split('/').includes('travel');

  isMotor = this.props.match.path.split('/').includes('motor');

  isProperty = this.props.match.path.split('/').includes('property');

  isHealth = this.props.match.path.split('/').includes('health');

  isLife = this.props.match.path.split('/').includes('life');

  componentDidMount() {
    let URL = `${travelUrl}/${this.props.match.params.policyId}/`;

    if (this.isMotor) {
      URL = `${motorUrl}/${this.props.match.params.policyId}/`;
    }
    if (this.isProperty) {
      URL = `${propertyUrl}/${this.props.match.params.policyId}/`;
    }
    if (this.isHealth) {
      URL = `${healthUrl}/${this.props.match.params.policyId}/`;
    }
    if (this.isLife) {
      URL = `${lifeUrl}/${this.props.match.params.policyId}/`;
    }

    axios
      .get(URL, {
        headers: getHeaders(),
      })
      .then(res => {
        this.setState({ policy: res.data });
      });
  }

  render() {
    let policy = this.state.policy;

    return (
      <React.Fragment>
        {this.isTravel && <Travel policy={policy} />}
        {this.isMotor && <Motor policy={policy} />}
        {this.isProperty && <Property policy={policy} />}
        {this.isHealth && <Health policy={policy} />}
        {this.isLife && <Life policy={policy} />}
      </React.Fragment>
    );
  }
}
NewClaim.propTypes = {
  match: PropTypes.object,
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
};

export default withRouter(WithUser(NewClaim));
