export const SentrySettings = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return {
      urlTargetList: [
        /^https:\/\/app\.mybestquote\.com/,
        /^https:\/\/api\.mybestquote\.com\/api/,
      ],
      // reduce all to 0.2 when more traffic start coming in.
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.5,
      replaysOnErrorSampleRate: 0.5,
      dsn: 'https://8ba79c296ebbca2d682e330a7ebe5350@o359324.ingest.sentry.io/4505929145450496',
    };
  } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    return {
      urlTargetList: [
        /^https:\/\/stage\.mybestquote\.com/,
        /^https:\/\/stage-api\.mybestquote\.com\/api/,
      ],
      tracesSampleRate: 0.5, // represents a 100%
      replaysSessionSampleRate: 0.5, // represents a 100%
      replaysOnErrorSampleRate: 0.5, // represents a 100%
      dsn: 'https://1f7635375e63ebc0e9049ccc839f1145@o359324.ingest.sentry.io/4505929530671104',
    };
  }

  return null;
};
