import React from 'react';
import { Tag, Space, Modal, Breadcrumb } from 'antd';
import axios from 'axios';
import * as pc from '../../../../app-constants/products';
import * as oc from '../../../../app-constants/policies';
import Motor from './Motor';
import { getHeaders, lifeUrl } from '../../../../config';
import { withRouter } from 'react-router-dom';
import FilterTable from '../../../../components/Forms/searchFilter';

class Policies extends React.Component {
  state = {
    tableData: [],
    productLimit: '7',

    showModal: false,
    selected: null,
  };

  // update tables
  updateTableResults() {
    // const { dispatch } = this.props

    axios
      .get(lifeUrl, { headers: getHeaders() })
      .then(results => {
        // motor request
        let policies = [];
        results.data.results.forEach(policy => {
          policies.push({
            ...policy,
            customer: policy.client
              ? `${policy.client.firstName} ${policy.client.lastName}`
              : `${policy.user.firstName} ${policy.user.lastName}`,
            product: policy.plan
              ? pc.LIFE_PRODUCT_TYPE_CHOICES[policy.plan.planType]
              : '',
            company: policy.company.name,
            typeName: pc.PRODUCT_TYPE_CHOICES.L,
            premiumAmount: `${policy.premium.currency} ${policy.premium.amount}`,
            statusName: policy.validity,
            key: policy.id,
            countries: ['NA'],
            policyId: policy.id,
            timestamp: new Date(policy.updatedAt).toDateString(),
            duration: `${policy.duration} Years`,
            payment: oc.POLICY_STATUS_CHOICES[policy.status],
            paymentColor: [oc.PAID, oc.ACTIVE].includes(policy.status)
              ? 'green'
              : 'red',
            status: policy.status,
          });
        });
        // end motor request

        this.setState({
          tableData: policies,
        });
      })
      .catch(() => {})
      .then(() => {
        // dispatch(setLoading(false));
      });
  }
  // end update tables

  componentDidMount() {
    this.updateTableResults();
  }

  handleShowModal = () => {
    this.setState({
      showModal: true,
    });
  };

  handleOk = () => {
    this.setState({
      showModal: false,
    });
  };

  handleCancel = () => {
    this.setState(
      {
        showModal: false,
      },
      () => this.updateTableResults(),
    );
  };

  handleInputChange = stateName => event => {
    this.setState({ [stateName]: event.target.value });
  };

  render() {
    // eslint-disable-next-line react/prop-types
    if (this.props.isLoading) return null;

    let { tableData } = this.state;

    const Columns = [
      {
        title: 'Date',
        dataIndex: 'timestamp',
        key: 'timestamp',
        width: 100,
      },
      {
        title: 'Policy Type',
        dataIndex: 'product',
        key: 'product',
        width: 80,
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
        width: 100,
      },
      {
        title: 'Amount',
        dataIndex: 'premiumAmount',
        key: 'premiumAmount',
        width: 80,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        width: 120,
      },
      {
        title: 'Payment',
        dataIndex: 'payment',
        key: 'payment',
        width: 80,
        render: (text, record) => (
          <Space size="middle">
            <Tag color={record.paymentColor}>{text}</Tag>
          </Space>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'statusName',
        key: 'statusName',
        width: 80,
        // filters: [
        //   {
        //     text: 'Unpaid',
        //     value: oc.UNPAID
        //   },
        //   {
        //     text: 'Paid',
        //     value: oc.ACTIVE
        //   },
        //   {
        //     text: 'Inactive',
        //     value: oc.INACTIVE
        //   },
        //   {
        //     text: 'Cancelled',
        //     value: oc.CANCELLED
        //   }
        // ],
        // onFilter: (value, record) => record.status === value
      },
      {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 100,
        render: () => (
          <Space size="middle">
            <span
              // onClick={handleDetail.bind(this, record)}
              style={{ color: 'blue', cursor: 'pointer' }}
            >
              View
            </span>
          </Space>
        ),
      },
    ];

    let motorDetailRow = (record) => ({
      onClick: () => {
        this.setState({ selectedMotor: record, showModal: true });
      },
    });

    return (
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Life Policies</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <Modal
            title="Life Details"
            centered
            open={this.state.showModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
          >
            {this.state.showModal ? (
              <Motor
                handleCancel={this.handleCancel}
                policy={this.state.selectedMotor}
              />
            ) : null}
          </Modal>
        </div>

        <div className="col-md-11 ml-3rem">
          <div className="card">
            <div className="card-header">
              <div className="utils__title">
                <span>Life Policies History</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                <button
                  className="btn_normal btn_gradient btn_md"
                  // eslint-disable-next-line react/prop-types
                  onClick={() => this.props.history.push('/buy-life-insurance')}
                >
                  Buy New Policy
                </button>
                {/*
                <span style={{ marginLeft: 8 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items`
                    : ""}
                </span> */}
              </div>

              <FilterTable
                columns={Columns}
                dataSource={tableData}
                onRow={motorDetailRow}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Policies);
